import { Components } from "appworks/components/components";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { Text } from "appworks/graphics/pixi/text";
import { SpineService } from "appworks/graphics/spine/spine-service";
import { gameState } from "appworks/model/game-state";
import { GenericRecord } from "appworks/model/gameplay/records/generic-record";
import { commsManager } from "appworks/server/comms-manager";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { State } from "appworks/state-machine/states/state";
import { brightness } from "appworks/utils/animation/brightness";
import { fadeIn2, fadeOut2 } from "appworks/utils/animation/fade2";
import { pulse } from "appworks/utils/animation/scale";
import { dualTransform, transform } from "appworks/utils/animation/transform";
import { tweenNumberText } from "appworks/utils/animation/tween-number-text";
import { lastInArray, normalizeIndex, shuffle, swapElements } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { logger } from "appworks/utils/logger";
import { RandomFromArray, RandomRangeInt } from "appworks/utils/math/random";
import { Timer } from "appworks/utils/timer";
import { Easing } from "appworks/utils/tween";
import { PYLBoardComponent, chanceOfExtraLifeOnBoard, chanceOfLandingOnMove2, chanceOfMove2OnBoard } from "components/pyl-board-component";
import { PYLBonusWinCounterComponent } from "components/pyl-bonus-win-counter-component";
import { PYLWhammyAnimationComponent } from "components/pyl-whammy-animation-component";
import { gameLayers } from "game-layers";
import { GMRFeaturePickRequestPayload } from "gaming-realms/integration/requests/gmr-feature-pick-request";
import { clamp, last } from "lodash";
import { PYLPrize } from "model/pyl-prize";
import { PYLBonusResult } from "model/results/pyl-bonus-result.ts";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { PYLAbstractBonusState } from "states/pyl-abstract-bonus-state";

export const fakePressYourLuckPrizes = {
    low: [0, 0.3, 0.4, 0.5, 0.6, 0.8, 1, 1.5, 2, 2.5, 3, 4, 5, 10],
    high: [0, 30, 35, 40, 50, 60, 75, 80, 90, 100, 125]
}

export class PYLPressYourLuckBonusState extends PYLAbstractBonusState {
    protected currentWhammies: number;
    protected maxWhammies: number;
    protected totalMultiplier: number;
    protected instantlyStart: boolean;
    protected initialPositions: {
        gold: DualPosition;
        btnCollect: DualPosition;
        collectFrame: DualPosition;
        totalWinLabel: DualPosition;
        totalWinValue: DualPosition;
        portrait_gold: DualPosition;
        portrait_totalwin_bg: DualPosition;
        portrait_totalWinLabel: DualPosition;
        portrait_totalWinValue: DualPosition;
    };
    protected currentWin: number;
    protected maxThresholdMultiplier: number;
    protected whammyIntervals: number[] = [];
    protected bellInterval: number;
    protected spines: Record<string, SpineContainer> = {};

    public onExit(): void {
        super.onExit();
        gameLayers.MenuButton.scene.active.contents.ui_settings_menu.landscape.x = 0;
        gameLayers.MenuButton.scene.active.contents.ui_settings_menu.portrait.y = 1648;
        this.whammyIntervals.forEach((id) => Timer.clearInterval(id));
        this.whammyIntervals = [];
        this.spines = {};

        const finalWin = gameState.getCurrentGame().getTotalWin();
        Services.get(TransactionService).setTotalWin(finalWin);
    }

    protected onBonusSceneSet(variant?: "low" | "high"): void {
        this.currentWhammies = 0;
        this.maxWhammies = 3;
        this.totalMultiplier = 0;
        this.currentWin = 0;
        this.instantlyStart = true;
        this.maxThresholdMultiplier = variant === "low" ? 5 : 150;

        gameLayers.Bonus.scene.board_game.contents.landscape_lights_2.visible = false;
        gameLayers.Bonus.scene.board_game.contents.landscape_bangstick_2.visible = false;
        gameLayers.Bonus.scene.board_game.contents.portrait_lights_2.visible = false;
        gameLayers.Bonus.scene.board_game.contents.portrait_bangstick_2.visible = false;

        gameLayers.MenuButton.scene.active.contents.ui_settings_menu.landscape.x = -220;
        gameLayers.MenuButton.scene.active.contents.ui_settings_menu.portrait.y = 1300;

        gameLayers.Bonus.scene.board_game.contents.collect.setEnabled(false);
        gameLayers.Bonus.scene.board_game.contents.play.setEnabled(false);

        const thresholdCashValue = this.maxThresholdMultiplier * Services.get(SlotBetService).getTotalStake();
        gameLayers.Bonus.scene.board_game.contents.threshold_multiplier.text = Services.get(CurrencyService).format(thresholdCashValue, false);

        this.initialPositions = {
            gold: gameLayers.Bonus.scene.board_game.contents.landscape_gold.dualPosition.clone(),
            btnCollect: gameLayers.Bonus.scene.board_game.contents.collect.dualPosition.clone(),
            collectFrame: gameLayers.Bonus.scene.board_game.contents.landscape_collect_frame.dualPosition.clone(),
            totalWinLabel: new DualPosition(gameLayers.Bonus.scene.board_game.contents.landscape_total_win_label.landscape.clone(), gameLayers.Bonus.scene.board_game.contents.landscape_total_win_label.portrait.clone()),
            totalWinValue: new DualPosition(gameLayers.Bonus.scene.board_game.contents.landscape_total_win_value.landscape.clone(), gameLayers.Bonus.scene.board_game.contents.landscape_total_win_value.portrait.clone()),
            portrait_gold: gameLayers.Bonus.scene.board_game.contents.portrait_gold.dualPosition.clone(),
            portrait_totalwin_bg: gameLayers.Bonus.scene.board_game.contents.portrait_totalwin_bg.dualPosition.clone(),
            portrait_totalWinLabel: new DualPosition(gameLayers.Bonus.scene.board_game.contents.portrait_total_win_label.landscape.clone(), gameLayers.Bonus.scene.board_game.contents.portrait_total_win_label.portrait.clone()),
            portrait_totalWinValue: new DualPosition(gameLayers.Bonus.scene.board_game.contents.portrait_total_win_value.landscape.clone(), gameLayers.Bonus.scene.board_game.contents.portrait_total_win_value.portrait.clone()),
        };

        for (const el of gameLayers.Bonus.container.children) {
            if (el instanceof SpineContainer) {
                el.stop(true);
            }
        }

        this.setMeter("standard", true).execute();
    }

    protected prizeStep(prize: PYLPrize, stepIndex: number, isLastStep: boolean, variant?: "low" | "high"): Contract<void> {
        const contracts: Array<(...args: unknown[]) => Contract<any>> = [];

        const record = gameState.getCurrentGame().getCurrentRecord();

        this.totalMultiplier += prize.multiplier;
        this.isThresholdReached = this.totalMultiplier >= this.maxThresholdMultiplier;
        const isFinalWhammy = prize.whammy && this.currentWhammies+1 === this.maxWhammies;

        if (stepIndex === 0) {
            contracts.push(() => new Parallel([
                () => Contract.getDelayedContract(0, () => this.showWhammy(0)),
                () => Contract.getDelayedContract(200, () => this.showWhammy(1)),
                () => Contract.getDelayedContract(400, () => this.showWhammy(2)),
            ]));
        }

        contracts.push(...[
            () => this.instantlyStart ? Contract.empty() : this.btnPress(["play"]),
            () => Contract.wrap(() => {
                Components.get(PYLBoardComponent).play(this.getBoardGenFunction(variant, stepIndex));
            }),
            () => Contract.getTimeoutContract(500),
            () => this.btnPress(["play"]),
            () => Components.get(PYLBoardComponent).stop(prize),
        ]);

        if (prize.whammy) {
            this.currentWhammies += 1;
            contracts.push(() => new Parallel([
                () => Components.get(PYLBoardComponent).playWhammyAnimation(),
                () => this.activateWhammy(this.currentWhammies - 1),
            ]));
            if (isFinalWhammy) {
                contracts.push(() => this.setMeter("lost", false, record.cashWon));
            }
        }

        if (prize.extraLife) {
            this.maxWhammies += 1;
            contracts.push(() => this.showWhammy(this.maxWhammies - 1, prize));
            // TODO stop lastlife anim
        }

        const isCollect = !this.isThresholdReached && isLastStep && this.currentWhammies === this.maxWhammies-1;

        if (isCollect) {
            const lastHeart = this.spines[`whammy_flag_${this.currentWhammies}`] as SpineContainer;
            contracts.push(() => Contract.wrap(() => lastHeart.play("lastlife")));
        }

        if (prize.cashWon > 0) {
            contracts.push(() => new Parallel([
                () => Components.get(PYLBoardComponent).awardCashPrize(prize.cashWon),
                () => this.setMeter(isCollect ? "risk" : "standard"),
            ]));
            contracts.push(() => Contract.wrap(() => this.currentWin += prize.cashWon));
            contracts.push(() => Contract.getTimeoutContract(1000));
        }

        if (isCollect) {
            contracts.push(() => new Parallel([
                () => this.setMeter(isCollect ? "risk" : "standard"),
                () => this.displayRiskMessage()
            ]));
            contracts.push(() => this.btnPress(["collect", "play"]));
            contracts.push((action: "collect" | "play") => action === "collect" ? this.collect() : this.continue());
        }

        if (this.isThresholdReached) {
            contracts.push(() => this.thresholdReached());
            contracts.push(() => Contract.getTimeoutContract(2000));
        }

        if (gameLayers.Bonus.scene.board_game.contents.logo_middle.alpha === 0) {
            contracts.push(() => fadeIn2(gameLayers.Bonus.scene.board_game.contents.logo_middle, 150));
        }

        return new Sequence(contracts);
    }

    protected btnPress(buttonIds: Array<"collect" | "play">) : Contract<"collect" | "play"> {
        return new Contract(resolve => {
            const onClickListeners: Record<string, Function> = {};
            const cleanup = () => {
            for (const buttonId of buttonIds) {
                    gameLayers.Bonus.scene.board_game.contents[buttonId].setEnabled(false);
                    gameLayers.Bonus.scene.board_game.contents[buttonId].off(ButtonEvent.CLICK, onClickListeners[buttonId]);
                }
            }
            for (const buttonId of buttonIds) {
                gameLayers.Bonus.scene.board_game.contents[buttonId].setEnabled(true);
                onClickListeners[buttonId] = () => {
                    cleanup();
                    resolve(buttonId);
                    return;
                }
                gameLayers.Bonus.scene.board_game.contents[buttonId].on(ButtonEvent.CLICK, onClickListeners[buttonId]);
            }
        });
    }

    protected continue(): Contract {
        const request = new GMRFeaturePickRequestPayload("1");
        return new Sequence([
            () => Contract.wrap(() => gameLayers.Bonus.scene.board_game.contents.collect.setEnabled(false)),
            () => commsManager.request(request),
            () => new Contract(resolve => {
                gameState.getCurrentGame().setToLatestRecord();

                const record = gameState.getCurrentGame().getCurrentRecord();
                const result = lastInArray(record.getResultsOfType(PYLBonusResult));

                this.instantlyStart = true;

                new Sequence([
                    ...result.prizes.map((prize, i) => () => this.prizeStep(prize, 999, i === result.prizes.length-1, result.variant as "high" | "low")),
                    () => Contract.wrap(() => resolve())
                ]).execute();
            })
        ]);
    }

    protected collect(): Contract {
        const request = new GMRFeaturePickRequestPayload("2");
        return new Sequence([
            () => commsManager.request(request),
            () => new Contract(resolve => {
                gameState.getCurrentGame().setToLatestRecord();
                resolve();
            })
        ]);
    }

    protected getBoardGenFunction(variant: "low" | "high", level: number): () => PYLPrize[] {
        return (prize?: PYLPrize) => {
            const prizes: PYLPrize[] = [];

            if (prize) {
                prizes.push(prize);
            }

            if (Math.random() <= chanceOfMove2OnBoard) {
                prizes.push({ multiplier: 0, cashWon: 0, moveTwo: true });
            }

            if (Math.random() <= chanceOfExtraLifeOnBoard) {
                prizes.push({ multiplier: 0, cashWon: 0, extraLife: true });
            }

            while (prizes.length < 18) {
                const fakeMultiplier = this.generateBoardValue(variant);
                prizes.push({
                    multiplier: fakeMultiplier,
                    cashWon: fakeMultiplier * Services.get(SlotBetService).getTotalStake(),
                    whammy: fakeMultiplier === 0
                });
            }
    
            shuffle(prizes);
    
            return prizes;
        }
    }

    protected generateBoardValue(variant: "low" | "high") {
        return RandomFromArray(fakePressYourLuckPrizes[variant]);
    }

    protected setMeter(type: "standard" | "risk" | "lost" | "threshold", instant = false, totalWinOverride?: number): Contract {
        return new Parallel([
            () => this.setMeterLandscape(type, instant, totalWinOverride),
            () => this.setMeterPortrait(type, instant, totalWinOverride),
        ]);
    }

    protected setMeterLandscape(type: "standard" | "risk" | "lost" | "threshold", instant = false, totalWinOverride?: number): Contract {
        const durationMs = instant ? 0 : 1000;

        const thresholdPercent = clamp(this.totalMultiplier / this.maxThresholdMultiplier, 0, 1);
        
        gameLayers.Bonus.scene.board_game.contents.landscape_gold.anchor.set(0, 1);
        gameLayers.Bonus.scene.board_game.contents.landscape_red.anchor.set(0, 1);

        const targetGoldPosition = this.initialPositions.gold.clone();
        if (type === "risk" || type === "lost") {
            // show red bar
            targetGoldPosition.landscape.height = this.initialPositions.gold.landscape.height * (thresholdPercent - (thresholdPercent * 0.2));
        } else {
            targetGoldPosition.landscape.height = this.initialPositions.gold.landscape.height * thresholdPercent;
        }
        targetGoldPosition.landscape.y = this.initialPositions.gold.landscape.y + this.initialPositions.gold.landscape.height;

        const targetRedPosition = this.initialPositions.gold.clone();
        targetRedPosition.landscape.height = this.initialPositions.gold.landscape.height * thresholdPercent;
        targetRedPosition.landscape.y = this.initialPositions.gold.landscape.y + this.initialPositions.gold.landscape.height;

        if (gameLayers.Bonus.scene.board_game.contents.landscape_red.landscape.height === targetRedPosition.landscape.height && gameLayers.Bonus.scene.board_game.contents.landscape_gold.landscape.height === targetGoldPosition.landscape.height && type !== "lost") {
            return Contract.empty();
        }
        
        const targetCounterEl = type === "lost" ? targetGoldPosition : targetRedPosition

        const targetBtnPos = this.initialPositions.btnCollect.clone();
        targetBtnPos.landscape.y = this.initialPositions.btnCollect.landscape.y - targetCounterEl.landscape.height;

        const targetCollectFramePos = this.initialPositions.collectFrame.clone();
        targetCollectFramePos.landscape.y = this.initialPositions.collectFrame.landscape.y - targetCounterEl.landscape.height;

        const targetTotalWinLabelPos = this.initialPositions.totalWinLabel.clone();
        targetTotalWinLabelPos.landscape.y = this.initialPositions.totalWinLabel.landscape.y - targetCounterEl.landscape.height;

        const targetTotalWinValuePos = this.initialPositions.totalWinValue.clone();
        targetTotalWinValuePos.landscape.y = this.initialPositions.totalWinValue.landscape.y - targetCounterEl.landscape.height;

        const totalWin = totalWinOverride ?? (this.totalMultiplier * Services.get(SlotBetService).getTotalStake());

        if (type === "threshold") {
            targetGoldPosition.landscape.height *= 1.3;
            targetRedPosition.landscape.height *= 1.3;
        }

        return new Parallel([
            () => dualTransform(gameLayers.Bonus.scene.board_game.contents.landscape_gold.dualPosition, targetGoldPosition, durationMs, Easing.Sinusoidal.Out),
            () => dualTransform(gameLayers.Bonus.scene.board_game.contents.landscape_red.dualPosition, targetRedPosition, durationMs, Easing.Sinusoidal.Out),
            () => transform(gameLayers.Bonus.scene.board_game.contents.collect.landscape, targetBtnPos.landscape, durationMs, Easing.Sinusoidal.Out),
            () => dualTransform(gameLayers.Bonus.scene.board_game.contents.landscape_collect_frame, targetCollectFramePos, durationMs, Easing.Sinusoidal.Out),
            () => dualTransform(gameLayers.Bonus.scene.board_game.contents.landscape_total_win_label, targetTotalWinLabelPos, durationMs, Easing.Sinusoidal.Out),
            () => dualTransform(gameLayers.Bonus.scene.board_game.contents.landscape_total_win_value, targetTotalWinValuePos, durationMs, Easing.Sinusoidal.Out),
            () => TweenContract.wrapTween(tweenNumberText({
                text: gameLayers.Bonus.scene.board_game.contents.landscape_total_win_value,
                from: this.currentWin,
                to: totalWin,
                duration: durationMs,
                mutator: (value) => Services.get(CurrencyService).format(value, true),
                playSound: false
            }))
        ]);
    }

    protected setMeterPortrait(type: "standard" | "risk" | "lost" | "threshold", instant = false, totalWinOverride?: number): Contract {
        const durationMs = instant ? 0 : 1000;

        let thresholdPercent = clamp(this.totalMultiplier / this.maxThresholdMultiplier, 0, 1);

        const targetGoldPosition = this.initialPositions.portrait_gold.clone();
        if (type === "risk" || type === "lost") {
            // show red bar
            targetGoldPosition.portrait.width = this.initialPositions.portrait_gold.portrait.width * (thresholdPercent - (thresholdPercent * 0.2));
        } else {
            targetGoldPosition.portrait.width = this.initialPositions.portrait_gold.portrait.width * thresholdPercent;
        }

        const targetRedPosition = this.initialPositions.portrait_gold.clone();
        targetRedPosition.portrait.width = this.initialPositions.portrait_gold.portrait.width * thresholdPercent;

        if (gameLayers.Bonus.scene.board_game.contents.portrait_red.portrait.width === targetRedPosition.portrait.width && gameLayers.Bonus.scene.board_game.contents.portrait_gold.portrait.width === targetGoldPosition.portrait.width && type !== "lost") {
            return Contract.empty();
        }
        
        const targetCounterEl = type === "lost" ? targetGoldPosition : targetRedPosition;

        const targetBtnPos = this.initialPositions.btnCollect.clone();
        targetBtnPos.portrait.x = this.initialPositions.btnCollect.portrait.x + targetCounterEl.portrait.width;

        const targetCollectFramePos = this.initialPositions.portrait_totalwin_bg.clone();
        targetCollectFramePos.portrait.x = this.initialPositions.portrait_totalwin_bg.portrait.x + targetCounterEl.portrait.width;

        const targetTotalWinLabelPos = this.initialPositions.portrait_totalWinLabel.clone();
        targetTotalWinLabelPos.portrait.x = this.initialPositions.portrait_totalWinLabel.portrait.x + targetCounterEl.portrait.width;

        const targetTotalWinValuePos = this.initialPositions.portrait_totalWinValue.clone();
        targetTotalWinValuePos.portrait.x = this.initialPositions.portrait_totalWinValue.portrait.x + targetCounterEl.portrait.width;

        const totalWin = totalWinOverride ?? (this.totalMultiplier * Services.get(SlotBetService).getTotalStake());

        if (type === "threshold") {
            targetGoldPosition.portrait.width *= 1.3;
            targetRedPosition.portrait.width *= 1.3;
        }

        return new Parallel([
            () => dualTransform(gameLayers.Bonus.scene.board_game.contents.portrait_gold.dualPosition, targetGoldPosition, durationMs, Easing.Sinusoidal.Out),
            () => dualTransform(gameLayers.Bonus.scene.board_game.contents.portrait_red.dualPosition, targetRedPosition, durationMs, Easing.Sinusoidal.Out),
            () => transform(gameLayers.Bonus.scene.board_game.contents.collect.portrait, targetBtnPos.portrait, durationMs, Easing.Sinusoidal.Out),
            () => dualTransform(gameLayers.Bonus.scene.board_game.contents.portrait_totalwin_bg, targetCollectFramePos, durationMs, Easing.Sinusoidal.Out),
            () => dualTransform(gameLayers.Bonus.scene.board_game.contents.portrait_total_win_label, targetTotalWinLabelPos, durationMs, Easing.Sinusoidal.Out),
            () => dualTransform(gameLayers.Bonus.scene.board_game.contents.portrait_total_win_value, targetTotalWinValuePos, durationMs, Easing.Sinusoidal.Out),
            () => TweenContract.wrapTween(tweenNumberText({
                text: gameLayers.Bonus.scene.board_game.contents.portrait_total_win_value,
                from: this.currentWin,
                to: totalWin,
                duration: durationMs,
                mutator: (value) => Services.get(CurrencyService).format(value, true)
            }))
        ]);
    }

    protected displayRiskMessage(): Contract {
        const record = gameState.getCurrentGame().getCurrentRecord();
        const result = lastInArray(record.getResultsOfType(PYLBonusResult));
        if (!result.riskValue) {
            return Contract.empty();
        }

        const riskAmount = Services.get(CurrencyService).format(result.riskValue, true);
        gameLayers.Bonus.scene.board_game.contents.steals_value.text = Services.get(TranslationsService).get("whammy_steals", { x: riskAmount });

        const els = [
            gameLayers.Bonus.scene.board_game.contents.whammy_steal,
            gameLayers.Bonus.scene.board_game.contents.steal_title,
            gameLayers.Bonus.scene.board_game.contents.steals_value,
        ];

        for (const el of els) {
            el.alpha = 0;
            el.visible = true;
        }

        return new Parallel([
            () => fadeOut2([
                gameLayers.Bonus.scene.board_game.contents.logo_middle,
                gameLayers.Bonus.scene.board_game.contents.win_backing,
                gameLayers.Bonus.scene.board_game.contents.win_label,
                gameLayers.Bonus.scene.board_game.contents.win_value,
            ], 200),
            () => fadeIn2(els, 200)
        ]);
    }

    protected thresholdReached(): Contract {
        const finalWin = gameState.getCurrentGame().getTotalWin();

        return new Sequence([
            () => fadeOut2([
                gameLayers.Bonus.scene.board_game.contents.win_label,
                gameLayers.Bonus.scene.board_game.contents.win_value,
                gameLayers.Bonus.scene.board_game.contents.win_backing,
            ], 150),
            () => new Parallel([
                () => Components.get(PYLBoardComponent).playWhammyAnimation("win"),
                () => pulse(gameLayers.Bonus.scene.board_game.contents.landscape_x2, { x: 1.3, y: 1.3 }, 150, Easing.Sinusoidal.Out),
                () => pulse(gameLayers.Bonus.scene.board_game.contents.portrait_x2, { x: 1.3, y: 1.3 }, 150, Easing.Sinusoidal.Out),
                () => Contract.wrap(() => this.startBellAnim()),
                () => this.flashLights(),
                () => this.setMeter("threshold", false, finalWin),
                () => Contract.getTimeoutContract(2000),
            ]),
            () => Contract.wrap(() => this.stopBellAnim()),
        ]);
    }

    protected startBellAnim() {
        Services.get(SoundService).customEvent("pyl_bell_loop");

        this.bellInterval = Timer.setInterval(() => {
            gameLayers.Bonus.scene.board_game.contents.landscape_bangstick_1.visible = !gameLayers.Bonus.scene.board_game.contents.landscape_bangstick_1.visible;
            gameLayers.Bonus.scene.board_game.contents.landscape_bangstick_2.visible = !gameLayers.Bonus.scene.board_game.contents.landscape_bangstick_2.visible;
            gameLayers.Bonus.scene.board_game.contents.portrait_bangstick_1.visible = !gameLayers.Bonus.scene.board_game.contents.landscape_bangstick_1.visible;
            gameLayers.Bonus.scene.board_game.contents.portrait_bangstick_2.visible = !gameLayers.Bonus.scene.board_game.contents.landscape_bangstick_2.visible;
        }, 32);
    }

    protected stopBellAnim() {
        Services.get(SoundService).customEvent("pyl_bell_stop");

        Timer.clearInterval(this.bellInterval);
        gameLayers.Bonus.scene.board_game.contents.landscape_bangstick_1.visible = true;
        gameLayers.Bonus.scene.board_game.contents.landscape_bangstick_2.visible = false;
        gameLayers.Bonus.scene.board_game.contents.portrait_bangstick_1.visible = true;
        gameLayers.Bonus.scene.board_game.contents.portrait_bangstick_2.visible = false;
    }

    protected flashLights(duration = 960) {
        const intervalId = Timer.setInterval(() => {
            gameLayers.Bonus.scene.board_game.contents.landscape_lights_1.visible = !gameLayers.Bonus.scene.board_game.contents.landscape_lights_1.visible;
            gameLayers.Bonus.scene.board_game.contents.landscape_lights_2.visible = !gameLayers.Bonus.scene.board_game.contents.landscape_lights_2.visible;
            gameLayers.Bonus.scene.board_game.contents.portrait_lights_1.visible = !gameLayers.Bonus.scene.board_game.contents.portrait_lights_1.visible;
            gameLayers.Bonus.scene.board_game.contents.portrait_lights_2.visible = !gameLayers.Bonus.scene.board_game.contents.portrait_lights_2.visible;
        }, 128);

        return Contract.getDelayedContract(duration, () => Contract.wrap(() => {
            Timer.clearInterval(intervalId);
            gameLayers.Bonus.scene.board_game.contents.landscape_lights_1.visible = true;
            gameLayers.Bonus.scene.board_game.contents.landscape_lights_2.visible = false;
            gameLayers.Bonus.scene.board_game.contents.portrait_lights_1.visible = true;
            gameLayers.Bonus.scene.board_game.contents.portrait_lights_2.visible = false;
        }));
    }

    protected showWhammy(index: number, prize?: PYLPrize): Contract {
        const position = gameLayers.Bonus.scene.board_game.contents.positions[`whammy_heart$whammy_flag_${index}`];
        const flag = Services.get(SpineService).createSpineContainer("whammy_heart", position);
        gameLayers.Bonus.add(flag);
        this.spines[`whammy_flag_${index}`] = flag;

        if (prize?.multiplier) {
            const multiplierText = gameLayers.Bonus.scene.board_game.contents.landscape_total_win_value.clone();
            Object.assign(multiplierText.style, {
                fontSize: 60,
                fill: "#fff",
                fontFamily: "impact-regular",
                stroke: "#000",
                strokeThickness: 4,
                dropShadow: true,
                dropShadowAlpha: 0.5,
                dropShadowBlur: 10,
                dropShadowDistance: 2,
            });
            multiplierText.anchor.set(0.5, 0.5);
            const pos = flag.dualPosition.getCenterPos();
            pos.landscape.x -= 90;
            pos.portrait.x -= 130;
            pos.landscape.y -= 16;
            pos.portrait.y -= 16;
            multiplierText.landscape.setPosition(pos.landscape);
            multiplierText.portrait.setPosition(pos.portrait);
            multiplierText.text = `x${prize.multiplier}`;
            multiplierText.alpha = 0;
            gameLayers.Bonus.add(multiplierText);

            const cashText = gameLayers.Bonus.scene.board_game.contents.landscape_total_win_value.clone();
            Object.assign(cashText.style, {
                fontSize: 60,
                fill: "#fff",
                fontFamily: "impact-regular",
                stroke: "#000",
                strokeThickness: 4,
                dropShadow: true,
                dropShadowAlpha: 0.5,
                dropShadowBlur: 10,
                dropShadowDistance: 2,
            });
            cashText.anchor.set(0.5, 0.5);
            const pos2 = flag.dualPosition.getCenterPos();
            pos2.landscape.x -= 40;
            pos2.portrait.x -= 65;
            pos2.landscape.y -= 150;
            pos2.portrait.y -= 150;
            cashText.landscape.setPosition(pos2.landscape);
            cashText.portrait.setPosition(pos2.portrait);
            cashText.text = Services.get(CurrencyService).format(prize.cashWon, true);
            cashText.alpha = 0;
            gameLayers.Bonus.add(cashText);

            return new Sequence([
                () => new Parallel([
                    () => flag.playOnce("heart_add"),
                    () => fadeIn2(multiplierText, 200),
                    () => Contract.getDelayedContract(1000, () => fadeIn2(cashText, 200)),
                ]),
                () => Contract.wrap(() => {
                    flag.play("loop");
                }),
                () => Contract.getTimeoutContract(1000),
                () => fadeOut2([multiplierText, cashText], 200),
                () => Contract.wrap(() => {
                    gameLayers.Bonus.remove(multiplierText);
                    gameLayers.Bonus.remove(cashText);
                    multiplierText.destroy();
                    cashText.destroy();
                })
            ]);
        } else {
            return new Sequence([
                () => flag.playOnce("heart_in"),
                () => Contract.wrap(() => flag.play("loop"))
            ]);
        }
    }

    protected activateWhammy(index: number): Contract {
        const flag = this.spines[`whammy_flag_${index}`];
        const whammId = Components.get(PYLBoardComponent).activeTile.getWhammyAnimId();
        const whammyNum = parseInt(whammId?.split("_")[1] || "0");
        const whammyPosition = gameLayers.Bonus.scene.board_game.contents.positions[`whammy_${whammyNum}$whammy_${index}_${whammyNum}`];
        const whammy = Services.get(SpineService).createSpineContainer(`whammy_${whammyNum}`, whammyPosition);
        gameLayers.Bonus.add(whammy);
        this.spines[`whammy_${index}$whammy_${whammyNum}_${index}`] = whammy;
        const portraitWhammyPos = gameLayers.Bonus.scene.board_game.contents.positions[`whammy_portrait$whammy_portrait_${index}`];
        const portraitWhammy = Services.get(SpineService).createSpineContainer(`whammy_portrait`, portraitWhammyPos);
        gameLayers.Bonus.add(portraitWhammy);
        this.spines[`whammy_portrait_${index}`] = portraitWhammy;

        return new Sequence([
            () => new Parallel([
                () => flag.playOnce("heart_out"),
                () => whammy.playOnce("in"),
                () => portraitWhammy.playOnce("in"),
                () => Contract.wrap(() => Services.get(SoundService).customEvent("heart_break"))
            ]),
            () => new Parallel([
                () => whammy.playOnce("start"),
                () => portraitWhammy.playOnce("start"),
            ]),
            () => Contract.wrap(() => {
                whammy.play("idle");
                portraitWhammy.play("idle");

                const interval = Timer.setInterval(() => {
                    whammy.playOnce("loop").then(() => whammy.play("idle"));
                    const rnd = RandomRangeInt(1, 3);
                    portraitWhammy.playOnce(`loop${rnd}`).then(() => portraitWhammy.play("idle"));
                }, 5000);
                this.whammyIntervals.push(interval);
            })
        ]);
    }
}