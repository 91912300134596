import { ComponentFactory } from "appworks/components/factories/component-factory";
import { AbstractMatrixComponent } from "slotworks/components/matrix/abstract-matrix-component";
import { ReelSpinner, SpinStage, SpinStageEvents, StageSet } from "slotworks/components/matrix/reel/transition-behaviours/spin/reel-spinner";
import { SpinReelTransition } from "slotworks/components/matrix/reel/transition-behaviours/spin/spin-reel-transition";
import { AnticipateLandPulseSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/anticipate-land-pulse-symbol-behaviour";
import { BlinkSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/blink-symbol-behaviour";
import { slotDefinition } from "slotworks/model/slot-definition";
import { MatrixComponent } from "../matrix/matrix-component";

export class MatrixFactory implements ComponentFactory {
    protected defaultStops: number[];
    protected defaultReelset: string;
    protected spinSpeed: number;
    protected spinStages: SpinStage[] = [];
    protected quickSpinStages: SpinStage[] = [];
    protected skipStages: SpinStage[] = [];
    protected anticipationStages: SpinStage[] = [];
    protected matrixGrid: number[];

    constructor(matrixGrid: number[] = slotDefinition.matrixGrid) {
        this.matrixGrid = matrixGrid;
        const minStopDistance = this.matrixGrid.reduce((max, current) => current > max ? current : max, 0) + 1;

        this.spinSpeed = 0.015;

        this.defaultReelset = "base";
        this.defaultStops = new Array(this.matrixGrid.length).fill(0);

        this.spinStages = [
            {
                name: "accelerate",
                s: -1,
                u: 0,
                v: -this.spinSpeed,
                events: [
                    {
                        name: SpinStageEvents.START,
                        delay: 50
                    }
                ]
            },
            {
                name: "minRepeat",
                s: -1,
                u: -this.spinSpeed,
                v: -this.spinSpeed
            },
            {
                name: "repeat",
                s: -1,
                u: -this.spinSpeed,
                v: -this.spinSpeed,
                events: [
                    {
                        name: SpinStageEvents.START,
                        delay: 0
                    }
                ]
            },
            {
                name: "stop",
                s: -minStopDistance,
                u: -this.spinSpeed,
                v: -this.spinSpeed,
                events: [
                    {
                        name: SpinStageEvents.STOP,
                        delay: 0
                    }
                ]
            },
            {
                name: "land",
                s: 0,
                t: 0
            },
            {
                name: "overshoot",
                s: -0.35,
                u: -0.01,
                v: 0
            },
            {
                name: "complete",
                s: 0.35,
                t: 220,
                easing: "Quadratic.Out",
                events: [
                    {
                        name: SpinStageEvents.SKIP,
                        delay: 100
                    },
                    {
                        name: SpinStageEvents.ANTICIPATE,
                        delay: 200
                    }
                ]
            }
        ];

        this.quickSpinStages = [
            {
                name: "accelerate",
                s: -1,
                u: 0,
                v: -this.spinSpeed * 2,
                events: [
                    {
                        name: SpinStageEvents.START,
                        delay: 50
                    }
                ]
            },
            {
                name: "minRepeat",
                s: -1,
                u: -this.spinSpeed * 2,
                v: -this.spinSpeed * 2
            },
            {
                name: "repeat",
                s: -1,
                u: -this.spinSpeed * 2,
                v: -this.spinSpeed * 2,
                events: [
                    {
                        name: SpinStageEvents.START,
                        delay: 0
                    }
                ]
            },
            {
                name: "stop",
                s: -minStopDistance,
                u: -this.spinSpeed * 2,
                v: -this.spinSpeed * 2,
                events: [
                    {
                        name: SpinStageEvents.STOP,
                        delay: 0
                    },
                    {
                        name: SpinStageEvents.ANTICIPATE,
                        delay: 100
                    }
                ]
            },
            {
                name: "land",
                s: 0,
                t: 0
            },
            {
                name: "overshoot",
                s: -0.35,
                u: -0.01,
                v: 0
            },
            {
                name: "complete",
                s: 0.35,
                t: 220,
                easing: "Quadratic.Out",
                events: [
                    {
                        name: SpinStageEvents.SKIP,
                        delay: 100
                    }
                ]
            }
        ];

        this.skipStages = [
            {
                name: "stop",
                s: -minStopDistance,
                t: 0,
                events: [
                    {
                        name: SpinStageEvents.STOP,
                        delay: 0
                    },
                    {
                        name: SpinStageEvents.SKIP,
                        delay: 0
                    },
                    {
                        name: SpinStageEvents.ANTICIPATE,
                        delay: 100
                    }
                ]
            },
            {
                name: "land",
                s: 0,
                t: 0
            },
            {
                name: "complete",
                s: 0,
                t: 0
            }
        ];

        this.anticipationStages = [
            {
                name: "anticipate",
                s: -10,
                u: -this.spinSpeed,
                v: -this.spinSpeed * 0.5
            },
            {
                name: "tease",
                s: -10,
                u: -this.spinSpeed * 0.5,
                v: -this.spinSpeed * 0.5
            },
            {
                name: "stop",
                s: -minStopDistance,
                u: -this.spinSpeed * 0.5,
                v: 0.0
            },
            {
                name: "land",
                s: 0,
                t: 0
            },
            {
                name: "complete",
                s: 0,
                t: 0,
                events: [
                    {
                        name: SpinStageEvents.STOP,
                        delay: 100
                    },
                    {
                        name: SpinStageEvents.SKIP,
                        delay: 0
                    },
                    {
                        name: SpinStageEvents.ANTICIPATE,
                        delay: 200
                    }
                ]
            }
        ];
    }

    public build() {
        const matrix = this.createMatrix();

        this.addSymbolBehaviours(matrix);

        this.createTransition(matrix);

        return matrix;
    }

    protected createMatrix(): AbstractMatrixComponent {
        return new MatrixComponent(
            this.matrixGrid,
            this.defaultStops,
            slotDefinition.reelsets.get(this.defaultReelset)
        );
    }

    protected addSymbolBehaviours(matrix: AbstractMatrixComponent) {
        matrix.addDefaultSymbolBehaviour((symbol) => new BlinkSymbolBehaviour(symbol));
        matrix.addDefaultSymbolBehaviour((symbol) => new AnticipateLandPulseSymbolBehaviour(symbol), ["FG"]);
    }

    protected createTransition(matrix: AbstractMatrixComponent) {

        ReelSpinner.addStageSet({
            id: "default",
            spin: [this.spinStages],
            skip: [this.skipStages],
            anticipate: [this.anticipationStages],
            quickSpin: [this.quickSpinStages]
        });

        ReelSpinner.setStageSet("default");

        matrix.setTransition(new SpinReelTransition());
    }
}
