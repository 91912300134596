import { shuffle } from "appworks/utils/collection-utils";
import { slotDefinition } from "slotworks/model/slot-definition";
import { SymbolConfig } from "slotworks/model/symbol-definition";

const slingoSymbolDefs: SymbolConfig[] = [];

export function EJGameDefinitionSetup() {
    slotDefinition.matrixGrid = [1, 1, 1, 1, 1];

    SymbolDefinitions();

    ReelSets();
}

function SymbolDefinitions() {
    for (let i = 1; i <= 75; i++) {
        slingoSymbolDefs.push({ id: "numbers/" + i });
    }

    slingoSymbolDefs.push(
        { id: "blank", isBlank: true },
        { id: "J" },
        { id: "SJ" },
        { id: "whammy_1" },
        { id: "whammy_2" },
        { id: "whammy_3" },
        { id: "wheelsymbol" },
        { id: "bottomwheelsymbol" },
        { id: "wheelwhammy" },
    );

    slotDefinition.processSymbols(slingoSymbolDefs);
}

function ReelSets() {
    const numbers: string[] = [];
    for (let i = 1; i <= 75; i++) {
        numbers.push("numbers/" + i);
    }

    if (!slotDefinition.reelsets) {
        slotDefinition.reelsets = new Map();
    }

    slotDefinition.reelsets.set("slingo", [
        [...shuffle(numbers)],
        [...shuffle(numbers)],
        [...shuffle(numbers)],
        [...shuffle(numbers)],
        [...shuffle(numbers)]]
    );

    // const reelsets: string[][] = [[], [], [], [], []];
    // for (let i=0; i<5; i++) {
    //     for (let t=0; t<15; t++) {
    //         const numbers = 
    //     }
    // }
}
