import { gameState } from "appworks/model/game-state";
import { Gameplay } from "appworks/model/gameplay/gameplay";
import { CloseRequestPayload } from "appworks/model/gameplay/requests/close-request-payload";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { SlingoResponse } from "slingo/integration/slingo-schema";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { GMRRecordBuilder } from "gaming-realms/integration/builders/records/gmr-record-builder";
import { GMRFeaturePickRequestPayload } from "gaming-realms/integration/requests/gmr-feature-pick-request";
import { GenericRecord } from "appworks/model/gameplay/records/generic-record";
import { Record } from "appworks/model/gameplay/records/record";
import { builderManager } from "appworks/server/builders/builder-manager";
import { RequestPayload } from "appworks/model/gameplay/requests/request-payload";
import { PickRequestPayload } from "slotworks/model/gameplay/requests/pick-request-payload";
import { SpinRequestPayload } from "slotworks/model/gameplay/requests/spin-request-payload";

export class PYLBonusRecordBuilder extends GMRRecordBuilder {
    public id: string = "PYLBonusRecordBuilder";

    public filter(request: RequestPayload, response: any): boolean {
        return request instanceof GMRFeaturePickRequestPayload;
    }

    public build(gameplay: Gameplay, request: any, response: any): Record[] {
        super.build(gameplay, request, response);

        const responseData = response.data as SlingoResponse;

        const balance = this.processBalances(responseData.balance);
        gameState.getCurrentGame().balance = balance;

        const record = new GenericRecord();

        record.results = builderManager.buildResults(record, responseData);

        return [record];
    }
}
