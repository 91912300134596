import { ButtonElement, ButtonEvent } from "appworks/graphics/elements/button-element";
import { Contract } from "appworks/utils/contracts/contract";

export function buttonPressContract(button: ButtonElement): Contract {
    return new Contract(resolve => {
        const listener = () => {
            button.setEnabled(false);
            button.off(ButtonEvent.POINTER_UP, listener);
            resolve();
        };
        button.on(ButtonEvent.POINTER_UP, listener);
        button.setEnabled(true);
    });
}