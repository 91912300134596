import { UI } from 'appworks/ui/ui';
import { PYLSetup } from 'setup/pyl-setup';
import { SocialStateSetup } from 'setup/social-state-setup';
import { SlingoGameInstanceIdControl } from 'slingo/ui/controls/slingo-game-instance-id-control';
import { SlingoGameInstanceIdControlFactory } from 'slingo/ui/factories/slingo-game-instance-id-control-factory';
import { SocialPylComponentSetup } from 'setup/social-component-setup';
import { Services } from 'appworks/services/services';
import { FPSMeterService } from 'fps-meter-service';
import { CanvasService } from 'appworks/graphics/canvas/canvas-service';

/**
 * Sets up social additions
 *
 * @class SocialSetup
 * @typedef {SocialSetup}
 * @extends {PYLSetup}
 */
export class SocialSetup extends PYLSetup {
  /**
   * Social components
   *
   * @public
   * @override
   */
  public setupComponents(): void {
    SocialPylComponentSetup();
  }

  /**
   * Social Ui setup
   *
   * @public
   * @override
   */
  public setupUI(): void {
    super.setupUI();
    UI.deregister(SlingoGameInstanceIdControlFactory);
    SlingoGameInstanceIdControl.showGameId = false;
  }

  /**
   * Social state setup
   *
   * @public
   * @override
   */
  public setupStates(): void {
    SocialStateSetup();
  }

  /**
   * Social services
   *
   * @public
   * @override
   */
  public setupServices(): void {
    super.setupServices();
    Services.register(new FPSMeterService());
  }
  
  
  /**
   * Social canvas setup
   *
   * @public
   * @override
   */
  public setupCanvas(): void {
    super.setupCanvas();
    Services.get(CanvasService).fullScreenEnabled = false;
  }
}
