import { Components } from "appworks/components/components";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { gameState } from "appworks/model/game-state";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { State } from "appworks/state-machine/states/state";
import { fadeIn2, fadeOut2 } from "appworks/utils/animation/fade2";
import { tweenNumberText } from "appworks/utils/animation/tween-number-text";
import { deepClone, normalizeIndex, removeFromArray, rotateArray, shuffle, swapElements, wrapIndex } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { RandomFromArray, RandomRangeInt } from "appworks/utils/math/random";
import { PYLBoardComponent, chanceOfMove2OnBoard } from "components/pyl-board-component";
import { PYLBonusWinCounterComponent } from "components/pyl-bonus-win-counter-component";
import { PYLWhammyAnimationComponent } from "components/pyl-whammy-animation-component";
import { gameLayers } from "game-layers";
import { indexOf } from "lodash";
import { PYLPrize } from "model/pyl-prize";
import { AdjustmentFilter } from "pixi-filters";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { PYLAbstractBonusState } from "states/pyl-abstract-bonus-state";

export const fakeLuckyPressPrizes = {
    low: [0.5, 1, 2, 3, 4, 5, 6, 8, 10, 12, 15, 20, 25],
    high: [2, 3, 4, 5, 6, 8, 10, 12, 15, 20, 25, 30, 40, 50]
}

export class PYLLuckyPressBonusState extends PYLAbstractBonusState {
    protected dimFilter = new AdjustmentFilter({
        brightness: 0.5
    });
    protected readonly levelWhammies = [0, 2, 2, 4, 4, 6, 6, 8, 8, 10];

    protected onBonusSceneSet(variant?: string): void {
        for (const orientation of ["portrait", "landscape"] as const) {
            gameLayers.Bonus.scene.board_game.contents[`${orientation}_meter_bg`].visible = false;
            gameLayers.Bonus.scene.board_game.contents[`${orientation}_glass`].visible = false;
            gameLayers.Bonus.scene.board_game.contents[`${orientation}_gold`].visible = false;
            gameLayers.Bonus.scene.board_game.contents[`${orientation}_red`].visible = false;
            gameLayers.Bonus.scene.board_game.contents[`${orientation}_total_win_label`].visible = false;
            gameLayers.Bonus.scene.board_game.contents[`${orientation}_total_win_value`].visible = false;
            gameLayers.Bonus.scene.board_game.contents[`${orientation}_bell`].visible = false;
            gameLayers.Bonus.scene.board_game.contents[`${orientation}_bell_bg`].visible = false;
            gameLayers.Bonus.scene.board_game.contents[`${orientation}_bangstick_1`].visible = false;
            gameLayers.Bonus.scene.board_game.contents[`${orientation}_bangstick_2`].visible = false;
            gameLayers.Bonus.scene.board_game.contents[`${orientation}_lights_1`].visible = false;
            gameLayers.Bonus.scene.board_game.contents[`${orientation}_lights_2`].visible = false;
            gameLayers.Bonus.scene.board_game.contents[`${orientation}_x2`].visible = false;
        }
        
        gameLayers.Bonus.scene.board_game.contents.portrait_threshold_bg.visible = false;
        gameLayers.Bonus.scene.board_game.contents.portrait_totalwin_bg.visible = false;
        gameLayers.Bonus.scene.board_game.contents.landscape_collect_frame.visible = false;
        gameLayers.Bonus.scene.board_game.contents.threshold_multiplier.visible = false;
        gameLayers.Bonus.scene.board_game.contents.collect.visible = false;
        gameLayers.Bonus.scene.board_game.contents.multiplier_bg.visible = false;
    }

    protected prizeStep(prize: PYLPrize, stepIndex: number, isLastStep: boolean, variant?: "low" | "high"): Contract<void> {
        const contracts: Array<(...args: unknown[]) => Contract<any>> = [
            () => Contract.wrap(() => {
                Components.get(PYLBoardComponent).play(this.getBoardGenFunction(variant, stepIndex));
            }),
            () => this.btnPress(),
            () => Contract.wrap(() => {
                gameLayers.Bonus.scene.board_game.contents.play.setEnabled(false);
            }),
        ];

        if (prize.whammy) {
            contracts.push(() => new Parallel([
                () => Components.get(PYLBoardComponent).stop(prize),
                () => Components.get(PYLBoardComponent).playWhammyAnimation(),
            ]));
        } else {
            contracts.push(() => Components.get(PYLBoardComponent).stop(prize));
            contracts.push(() => this.displayPrize(prize));
        }

        contracts.push(() => Contract.getTimeoutContract(1000));

        return new Sequence(contracts);
    }

    protected btnPress() : Contract {
        return new Contract(resolve => {
            gameLayers.Bonus.scene.board_game.contents.play.setEnabled(true);

            const onClick = () => {
                gameLayers.Bonus.scene.board_game.contents.play.off(ButtonEvent.CLICK, onClick);
                resolve();
            }

            gameLayers.Bonus.scene.board_game.contents.play.on(ButtonEvent.CLICK, onClick);
        });
    }

    // TODO: share common parts of this function with the one in press-your-luck state via board component
    protected getBoardGenFunction(variant: "low" | "high", level: number): () => PYLPrize[] {
        return (prize?: PYLPrize) => {
            const prizes: PYLPrize[] = [];

            prizes.push(...Array(this.levelWhammies[level] ?? 10).fill({ multiplier: 0, whammy: true }));

            if (prize) {
                if (prize.whammy) {
                    prizes.pop();
                }
                prizes.push(prize);
            }

            let moveTile: PYLPrize;
            if (Math.random() <= chanceOfMove2OnBoard) {
                moveTile = { multiplier: 0, cashWon: 0, moveTwo: true };
                prizes.push(moveTile);
            }
    
            while (prizes.length < 18) {
                const fakeMultiplier = this.generateBoardValue(variant, level);
                prizes.push({
                    multiplier: fakeMultiplier,
                    cashWon: fakeMultiplier * Services.get(SlotBetService).getTotalStake(),
                });
            }
    
            shuffle(prizes);

            return prizes;
        }
    }

    protected generateBoardValue(variant: "low" | "high", level: number): number {
        return RandomFromArray(fakeLuckyPressPrizes[variant]);
    }

    protected displayPrize(prize: PYLPrize) {
        const contracts: Array<() => Contract> = [];

        const elements = [
            gameLayers.Bonus.scene.board_game.contents.win_backing,
            gameLayers.Bonus.scene.board_game.contents.win_label,
            gameLayers.Bonus.scene.board_game.contents.win_value,
        ];

        contracts.push(() => Contract.wrap(() => {
            gameLayers.Bonus.scene.board_game.contents.win_label.text = Services.get(TranslationsService).get("win");
            gameLayers.Bonus.scene.board_game.contents.win_value.text = "0";
        }));

        if (gameLayers.Bonus.scene.board_game.contents.win_label.alpha === 0 && !prize.whammy) {
            contracts.push(() => fadeIn2(elements, 200));
        } else if (prize.whammy) {
            contracts.push(() => fadeOut2([...elements, gameLayers.Bonus.scene.board_game.contents.logo_middle], 200));
        }

        const cashWon = prize.multiplier * Services.get(SlotBetService).getTotalStake();
        if (cashWon && cashWon > 0) {
            const winTween = tweenNumberText({
                text: gameLayers.Bonus.scene.board_game.contents.win_value,
                from: 0,
                to: cashWon,
                duration: 1000,
                mutator: (value) => Services.get(CurrencyService).format(value, true)
            })

            contracts.push(() => new Parallel([
                () => Components.get(PYLBonusWinCounterComponent).addWin(cashWon, 1000),
                () => TweenContract.wrapTween(winTween),
            ]));
        }

        return new Sequence(contracts);
    }
}