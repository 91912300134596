import { Components } from "appworks/components/components";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { SlingoReelSpinResult } from "slingo/model/results/slingo-reel-spin-result";
import { SlingoSpinState } from "slingo/states/slingo-spin-state";

export class PYLSlingoSpinState extends SlingoSpinState {
    protected onReelLand(reelIndex: number, match?: { matchedValue: number; reelIndex: number; }): void {
        super.onReelLand(reelIndex, match);

        const record = gameState.getCurrentGame().getCurrentRecord();
        const result = record.getFirstResultOfType(SlingoReelSpinResult);

        if (result.symbols[reelIndex].includes("whammy")) {
            Services.get(SoundService).customEvent(`whammy_land`);
            Services.get(SoundService).customEvent(`${result.symbols[reelIndex]}_land`);
        }
    }
}