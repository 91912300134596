
export enum SoundNames {
    wheel_button_press = "wheel_button_press",
    wheel_entry = "wheel_entry",
    wheel_exit = "wheel_exit",
    wheel_lose = "wheel_lose",
    wheel_spin = "wheel_spin",
    wheel_win = "wheel_win",
    Bartender = "Bartender",
    BBCrowd_CreditCheer_01 = "BBCrowd_CreditCheer_01",
    BBCrowd_CreditCheer_02 = "BBCrowd_CreditCheer_02",
    BBCrowd_CreditCheer_03 = "BBCrowd_CreditCheer_03",
    BBCrowd_CreditCheer_04 = "BBCrowd_CreditCheer_04",
    BBCrowd_CreditCheer_05 = "BBCrowd_CreditCheer_05",
    BigBucksSymbol = "BigBucksSymbol",
    break = "break",
    Bullride = "Bullride",
    cardflip = "cardflip",
    CruiseShip = "CruiseShip",
    Hula = "Hula",
    July4 = "July4",
    KM_Giggle_01 = "KM_Giggle_01",
    KM_Giggle_04 = "KM_Giggle_04",
    KM_Giggle_05 = "KM_Giggle_05",
    LumberJack = "LumberJack",
    MultiplierLand1 = "MultiplierLand1",
    MultiplierLand2 = "MultiplierLand2",
    MultiplierLand3 = "MultiplierLand3",
    Opera = "Opera",
    Pizza = "Pizza",
    PYL_BONUS_BOARD_V1 = "PYL_BONUS_BOARD_V1",
    PYL_GetCredits_Medium_01 = "PYL_GetCredits_Medium_01",
    PYL_MECH_REEL_STOP_1 = "PYL_MECH_REEL_STOP_1",
    PYL_MECH_SPIN_START_SFX = "PYL_MECH_SPIN_START_SFX",
    PYL_MUS_InGame_Bonus_Loop = "PYL_MUS_InGame_Bonus_Loop",
    PYL_MUS_InGame_Main_Loop = "PYL_MUS_InGame_Main_Loop",
    PYL_SFX_BellRinging_Loop = "PYL_SFX_BellRinging_Loop",
    PYL_SFX_BellRinging_Terminator = "PYL_SFX_BellRinging_Terminator",
    PYL_SFX_BG_AttractPopup = "PYL_SFX_BG_AttractPopup",
    PYL_SFX_BG_Countup_End = "PYL_SFX_BG_Countup_End",
    PYL_SFX_BG_Countup_Loop = "PYL_SFX_BG_Countup_Loop",
    PYL_SFX_BG_EndGame_NoWin = "PYL_SFX_BG_EndGame_NoWin",
    PYL_SFX_BG_EndGame_Win = "PYL_SFX_BG_EndGame_Win",
    PYL_SFX_BG_Land_WhammyBlocker = "PYL_SFX_BG_Land_WhammyBlocker",
    PYL_SFX_BG_Land_Wild = "PYL_SFX_BG_Land_Wild",
    PYL_SFX_BG_Land_WildSuper = "PYL_SFX_BG_Land_WildSuper",
    PYL_SFX_BG_NumberDab = "PYL_SFX_BG_NumberDab",
    PYL_SFX_BG_SlingoCelebration = "PYL_SFX_BG_SlingoCelebration",
    PYL_SFX_BW_Big_Loop = "PYL_SFX_BW_Big_Loop",
    PYL_SFX_BW_Big_Start = "PYL_SFX_BW_Big_Start",
    PYL_SFX_BW_Epic_Loop = "PYL_SFX_BW_Epic_Loop",
    PYL_SFX_BW_Epic_Start = "PYL_SFX_BW_Epic_Start",
    PYL_SFX_BW_Super_Loop = "PYL_SFX_BW_Super_Loop",
    PYL_SFX_BW_Super_Start = "PYL_SFX_BW_Super_Start",
    PYL_SFX_BW_Terminator = "PYL_SFX_BW_Terminator",
    PYL_SFX_SAWB_WheelSpinCog_01a = "PYL_SFX_SAWB_WheelSpinCog_01a",
    PYL_SFX_SAWB_WheelSpinCog_01b = "PYL_SFX_SAWB_WheelSpinCog_01b",
    PYL_SFX_SAWB_WheelSpinCog_01c = "PYL_SFX_SAWB_WheelSpinCog_01c",
    PYL_SFX_SAWB_WheelSpinCog_01d = "PYL_SFX_SAWB_WheelSpinCog_01d",
    PYL_WAMMY_HIT = "PYL_WAMMY_HIT",
    reeltick = "reeltick",
    Rollerskate = "Rollerskate",
    SILENCE_1_SEC = "SILENCE_1_SEC",
    snipup_2 = "snipup_2",
    Tarzan = "Tarzan",
    UFO = "UFO",
    Unicorn = "Unicorn",
    WaterSki = "WaterSki"
}
