import { Layers } from "appworks/graphics/layers/layers";
import { UIFlagState } from "appworks/ui/flags/ui-flag-state";
import { uiFlags, UIMenuFlag } from "appworks/ui/flags/ui-flags";
import { arrayMoveAbove, deepClone } from "appworks/utils/collection-utils";
import { LayerNames, gameLayers } from "game-layers";

export function PYLLayerSetup() {
    const defaultLayerOrder = [
        LayerNames.Background,
        LayerNames.Onboard,
        LayerNames.SlingoLadder,
        LayerNames.TicketMatrixBacking,
        LayerNames.TicketMatrix,
        LayerNames.MatrixBackground,
        LayerNames.MatrixContent,
        LayerNames.SymbolAnimations,
        LayerNames.SpinButton,
        LayerNames.Footer,
        LayerNames.Collect,
        LayerNames.SpinCounter,
        LayerNames.BonusBackground,
        LayerNames.SpinAWinMatrixBackground,
        LayerNames.SpinAWinMatrixContent0,
        LayerNames.SpinAWinMatrixContent1,
        LayerNames.SpinAWinMatrixContent2,
        LayerNames.SpinAWinAnimations,
        LayerNames.SpinAWinMatrixForeground,
        LayerNames.SpinAWinParticles,
        LayerNames.MenuButton,
        LayerNames.Bonus,
        LayerNames.Logo,
        LayerNames.CelebrationBackground,
        LayerNames.CelebrationContent,
        LayerNames.CelebrationForeground,
        LayerNames.CelebrationValue,
        LayerNames.BetBar,
        LayerNames.BonusUI,
        LayerNames.WhammyAnims,
        LayerNames.BonusTransitionLogo,
        LayerNames.SuperSpinWheel,
        LayerNames.Prompts,
        LayerNames.Menus,
        LayerNames.Debug
    ];

    const paytableLayerOrder = deepClone(defaultLayerOrder);
    arrayMoveAbove(paytableLayerOrder, LayerNames.SlingoLadder, LayerNames.SuperSpinWheel);

    Layers.setLayerOrderConfig({
        default: defaultLayerOrder,
        paytable: paytableLayerOrder
    }, LayerNames);

    Layers.get("BonusBackground").onSceneEnter.add(() => {
        const landscape = gameLayers.BonusBackground.getSprite("background_landscape");
        if (landscape) { landscape.interactive = true; }
        const portrait = gameLayers.BonusBackground.getSprite("background_portrait");
        if (portrait) { portrait.interactive = true; }
    });

    // TODO: make assetworks respect bounds/bounds-auto order so the following hacks aren't needed

    Layers.get("Prompts").onSceneEnter.add(() => {
        const cover = Layers.get("Prompts").getShape("cover");
        if (cover) {
            Layers.get("Prompts").add(cover, false, true);
        }
    });

    Layers.getAllLayers().forEach(layer => {
        layer.onSceneEnter.add(() => {
            const clickblocker = layer.getShape("clickblocker");
            if (clickblocker) {
                layer.add(clickblocker, false, true);
            }
        });
    });

    gameLayers.SpinAWinAnimations.onSceneEnter.add(() => {
        gameLayers.SpinAWinAnimations.enableBoundsMask(false);
        gameLayers.SpinAWinAnimations.setCustomMask(gameLayers.SpinAWinAnimations.scene.default.contents.mask);
    });

    uiFlags.hook(new UIFlagState(UIMenuFlag.PAYTABLE)).on.add(() => {
        Layers.setLayerOrder("paytable");
    });

    uiFlags.hook(new UIFlagState(UIMenuFlag.PAYTABLE)).off.add(() => {
        Layers.setLayerOrder("default");
    });
}
