import { AbstractComponent } from "appworks/components/abstract-component";
import { Components } from "appworks/components/components";
import { PIXIParticle, ParticleService } from "appworks/graphics/particles/particle-service";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { Dimmable, brightness } from "appworks/utils/animation/brightness";
import { deepClone } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { logger } from "appworks/utils/logger";
import { RandomFromArray, RandomRangeInt } from "appworks/utils/math/random";
import { PYLMatrixComponent } from "components/pyl-matrix-component";
import { PYLSpinAWinBonusMatrixComponent } from "components/pyl-spin-a-win-bonus-matrix-component";
import { gameLayers } from "game-layers";
import { PYLPrize } from "model/pyl-prize";
import { PYLBonusResult } from "model/results/pyl-bonus-result.ts";
import { Emitter } from "pixi-particles";
import { ReelSpinner } from "slotworks/components/matrix/reel/transition-behaviours/spin/reel-spinner";
import { SpinReelTransition } from "slotworks/components/matrix/reel/transition-behaviours/spin/spin-reel-transition";
import { SlotBetModel } from "slotworks/model/bets/slot-bet-model";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { GridOnlySupplement } from "slotworks/model/gameplay/supplements/grid-only-supplement";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";

export const fakeSpinAWinPrizes = {
    wheel1: {
        low: [6, 7, 8, 10, 12, 15, 20],
        high: [10, 15, 20, 25]
    },
    wheel2: {
        low: [0, 10, 12, 15, 20, 25, 30],
        high: [0, 15, 20, 25, 30, 40, 50]
    },
    wheel3: {
        low: [0, 2, 3, 4, 5, 10],
        high: [0, 2, 3, 4, 5, 10]
    },
}

export class PYLSpinAWinBonusComponent extends AbstractComponent {
    public matrixComponents: PYLSpinAWinBonusMatrixComponent[] = [];
    public particles: PIXIParticle[] = [];

    public init(): void {
        for (const matrixComponent of Components.getAll(PYLSpinAWinBonusMatrixComponent)) {
            this.matrixComponents.push(matrixComponent);
        }

        const leftTickers = [
            gameLayers.SpinAWinMatrixForeground.scene.default.contents.ticker_0_left,
            gameLayers.SpinAWinMatrixForeground.scene.default.contents.ticker_1_left,
        ];
        const rightTickers = [
            gameLayers.SpinAWinMatrixForeground.scene.default.contents.ticker_0_right,
            gameLayers.SpinAWinMatrixForeground.scene.default.contents.ticker_1_right,
        ]

        for (const ticker of leftTickers) {
            ticker.pivot.set(25, 30);
            ticker.landscape.x += 25;
            ticker.landscape.y += 30;
            ticker.portrait.x += 25;
            ticker.portrait.y += 30;
        }

        for (const ticker of rightTickers) {
            ticker.pivot.set(73, 30);
            ticker.landscape.x += 73;
            ticker.landscape.y += 30;
            ticker.portrait.x += 73;
            ticker.portrait.y += 30;
        }

        const maxAngle = -50;

        this.matrixComponents.forEach((matrixComponent, matrixIndex) => {
            matrixComponent.getTransition<SpinReelTransition>().getSpinners().forEach((spinner) => {
                const leftTicker = gameLayers.SpinAWinMatrixForeground.scene.default.contents[`ticker_${matrixIndex}_left`];
                const rightTicker = gameLayers.SpinAWinMatrixForeground.scene.default.contents[`ticker_${matrixIndex}_right`];
                let lastPercent = 0;

                spinner.onMove.add(() => {
                    const percent = spinner.getCurrentPosition() % 1;
                    const angle = maxAngle * (1 - percent);

                    if (leftTicker) { leftTicker.angle = -angle; }
                    if (rightTicker) { rightTicker.angle = angle; }

                    if (percent > lastPercent) { this.playReelTick(); }
                    lastPercent = percent;
                });

                spinner.onComplete.add(() => {
                    this.playReelTick();
                    if (leftTicker) { leftTicker.angle = 0; }
                    if (rightTicker) { rightTicker.angle = 0; }
                });
            });
        })

        this.reelBrightness(0, 1, 0).execute();
        this.reelBrightness(1, 1, 0).execute();
        this.reelBrightness(2, 1, 0).execute();

        this.particles.push(Services.get(ParticleService).add("spinAWin", gameLayers.SpinAWinParticles.container));
        this.particles.push(Services.get(ParticleService).add("spinAWin2", gameLayers.SpinAWinParticles.container));

        this.particles[0].emitter.emit = false;
        this.particles[1].emitter.emit = false;
    }

    public setVisible(visible: boolean) {
        gameLayers.SpinAWinMatrixContent0.container.visible = visible;
        gameLayers.SpinAWinMatrixContent1.container.visible = visible;
        gameLayers.SpinAWinMatrixContent2.container.visible = visible;

        gameLayers.SpinAWinAnimations.container.visible = visible;
        gameLayers.SpinAWinMatrixBackground.container.visible = visible;
        gameLayers.SpinAWinMatrixForeground.container.visible = visible;
    }

    public setEnabled(enabled: boolean) {
        this.matrixComponents.forEach((matrixComponent, i) => {
            matrixComponent.enabled = enabled;
            matrixComponent.matrixLayer.setCustomMask(gameLayers.SpinAWinMatrixForeground.scene.default.contents[`mask_${i}`]);
        });

        Components.get(PYLMatrixComponent).enabled = !enabled;

        if (enabled) {
            ReelSpinner.setStageSet("spinawin");
        } else {
            ReelSpinner.setStageSet("default");
        }
    }

    public setReelsets(variant: string) {
        const reelsets = this.generateReelsets(variant);

        this.matrixComponents.forEach((matrixComponent, i) => {
            matrixComponent.setCustomReelset(reelsets[i]);
            matrixComponent.jump([0], reelsets[i]);
        });
    }

    protected generateReelsets(variant: string): string[][][] {
        const reelsets: string[][][] = [[[]], [[]], [[]]];

        for (let i = 0; i < 50; i++) {
            const fakeCashPrize = this.getFakeMultiplier(variant, 0, false) * Services.get(SlotBetService).getTotalStake();
            if (fakeCashPrize === 0) {
                reelsets[0][0].push("wheelwhammy");
            } else {
                reelsets[0][0].push(`wheelsymbol_${fakeCashPrize}`);
            }

            const fakeCashPrize2 = this.getFakeMultiplier(variant, 1, true) * Services.get(SlotBetService).getTotalStake();
            if (fakeCashPrize2 === 0) {
                reelsets[1][0].push("wheelwhammy");
            } else {
                reelsets[1][0].push(`wheelsymbol_${fakeCashPrize2}`);
            }

            const fakeMultiplier = this.getFakeMultiplier(variant, 2, true);
            if (fakeMultiplier === 0) {
                reelsets[2][0].push(`whammy_${RandomRangeInt(1, 3)}`);
            } else {
                reelsets[2][0].push(`wheelsymbol_${fakeMultiplier}`);
            }
        }

        return reelsets;
    }

    protected getFakeMultiplier(variant: string, wheel: number, includeZero: boolean): number {
        const multipliers = fakeSpinAWinPrizes[`wheel${wheel + 1}`][variant];
        return RandomFromArray(multipliers);
    }

    protected playReelTick() {
        Services.get(SoundService).customEvent("reeltick_" + RandomRangeInt(1, 4));
    }

    public reelBrightness(reelIndex: number, amount: number, durationMs: number, includeMatrixContent = false) {
        const elements: Dimmable[] = [];

        if (reelIndex == 0) {
            elements.push(
                gameLayers.SpinAWinMatrixBackground.scene.default.contents.left_bg,
                gameLayers.SpinAWinMatrixForeground.scene.default.contents.ticker_0_left,
                gameLayers.SpinAWinMatrixForeground.scene.default.contents.ticker_0_right,
                gameLayers.SpinAWinMatrixForeground.scene.default.contents.left_frame,
            );
            if (includeMatrixContent) {
                elements.push(gameLayers.SpinAWinMatrixContent0.container);
            }
        } else if (reelIndex === 1) {
            elements.push(
                gameLayers.SpinAWinMatrixBackground.scene.default.contents.right_bg,
                gameLayers.SpinAWinMatrixForeground.scene.default.contents.ticker_1_left,
                gameLayers.SpinAWinMatrixForeground.scene.default.contents.ticker_1_right,
                gameLayers.SpinAWinMatrixForeground.scene.default.contents.right_frame,
            );
            if (includeMatrixContent) {
                elements.push(gameLayers.SpinAWinMatrixContent1.container);
            }
        } else {
            elements.push(
                gameLayers.SpinAWinMatrixBackground.scene.default.contents.bottom_bg,
                gameLayers.SpinAWinMatrixForeground.scene.default.contents.bottom_frame,
                gameLayers.SpinAWinMatrixForeground.scene.default.contents.selector_bottom,
                gameLayers.SpinAWinMatrixForeground.scene.default.contents.shadow,
            );
            if (includeMatrixContent) {
                elements.push(gameLayers.SpinAWinMatrixContent2.container);
            }
        }

        return new Parallel(elements.map(el => () => brightness(el, amount, durationMs)));
    }
}