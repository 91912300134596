import { gameState } from 'appworks/model/game-state';
import { BlastworksClientEvent, ClientController } from 'client-controller';
import { SlingoReelSpinResult } from 'slingo/model/results/slingo-reel-spin-result';
import { PYLSlingoSpinState } from 'states/pyl-slingo-spin-state';

/**
 * Social spin state, sends reel result messages
 *
 * @class SocialSpinState
 * @typedef {SocialSpinState}
 * @extends {PYLSlingoSpinState}
 */
export class SocialSpinState extends PYLSlingoSpinState {
  /**
   * Send reel result messages
   *
   * @protected
   * @override
   * @param {number} reelIndex
   * @param {?{ matchedValue: number; reelIndex: number }} [match]
   */
  protected onReelLand(
    reelIndex: number,
    match?: { matchedValue: number; reelIndex: number },
  ): void {
    const result = gameState.getCurrentGame().getLatestResultOfType(SlingoReelSpinResult);
    const landedSymbol = result.symbols[reelIndex];

    if (landedSymbol === 'FS') {
      ClientController.getInstance().raiseEvent(BlastworksClientEvent.REEL_STOP_FREE_SPIN);
    }

    if (landedSymbol === 'J') {
      ClientController.getInstance().raiseEvent(BlastworksClientEvent.REEL_STOP_JOKER);
    }

    if (landedSymbol === 'SJ') {
      ClientController.getInstance().raiseEvent(BlastworksClientEvent.REEL_STOP_SUPER_JOKER);
    }

    super.onReelLand(reelIndex, match);
  }
}
