import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { Easing } from "appworks/utils/tween";
import { Tween } from "appworks/utils/tween";

export type TweenNumberTextOptions = {
    text: { text: string};
    from: number;
    to: number;
    duration?: number;
    easing?: (t: number) => number;
    mutator?: (value: number) => string;
    playSound?: boolean;
    onComplete?: () => unknown;
};

const defaultOptions: Partial<TweenNumberTextOptions> = {
    duration: 1000,
    easing: Easing.Sinusoidal.Out,
    mutator: (value: number) => value.toFixed(2),
    playSound: true
}

export function tweenNumberText(options: TweenNumberTextOptions) {
    options = { ...defaultOptions, ...options };

    const obj = { value: options.from };

    const tween = new Tween(obj)
        .to({ value: options.to }, options.duration)
        .easing(options.easing)
        .onStart(() => {
            options.text.text = options.mutator(options.from);
            if (options.playSound) {
                Services.get(SoundService).event(SoundEvent.winnings_countup);
            }
        })
        .onUpdate((updatePercent: number) => {
            options.text.text = options.mutator(obj.value);
        })
        .onComplete(() => {
            options.text.text = options.mutator(options.to);
            if (options.playSound) {
                Services.get(SoundService).event(SoundEvent.winnings_countup_end);
            }
            if (options.onComplete) {
                options.onComplete();
            }
        })
        .onStop(() => {
            options.text.text = options.mutator(options.to);
            if (options.playSound) {
                Services.get(SoundService).event(SoundEvent.winnings_countup_end);
            }
        });

    return tween;
}