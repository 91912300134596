import { Components } from "appworks/components/components";
import { PromptComponent } from "appworks/components/prompt/prompt-component";
import { PromptContentSubcomponent } from "appworks/components/prompt/prompt-content-subcomponent";
import { gameState } from "appworks/model/game-state";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { tweenNumberText } from "appworks/utils/animation/tween-number-text";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { PYLSymbolGrowBehaviour } from "behaviors/pyl-symbol-grow-behaviour";
import { PYLWhammyLandSymbolBehavior } from "behaviors/pyl-whammy-land-symbol-behavior";
import { PYLMatrixComponent } from "components/pyl-matrix-component";
import { gameLayers } from "game-layers";
import { SlingoCompletionReason } from "slingo/integration/slingo-schema";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { SlingoSoundEvent } from "slingo/sound/slingo-sound-events";
import { SlingoGameOverState } from "slingo/states/slingo-game-over-state";
import { BigWinComponent } from "slotworks/components/bigwin/big-win-component";

export class PYLGameOverState extends SlingoGameOverState {
    protected sendInitRequest(): Contract<void> {
        return new Sequence([
            () => super.sendInitRequest(),
            () => Contract.wrap(() => {
                Components.get(PYLMatrixComponent).getAllSymbols().forEach((symbol) => {
                    // lazy hack to fix https://epicindustries.atlassian.net/browse/PYL-59
                    const behaviour = symbol.getFirstBehaviourOfType(PYLWhammyLandSymbolBehavior);
                    behaviour.cleanUp();

                    const behaviour2 = symbol.getFirstBehaviourOfType(PYLSymbolGrowBehaviour);
                    behaviour2.static();
                });
            })
        ])
    }

    protected showWinSummary(): Contract {
        Services.get(SoundService).customEvent(SlingoSoundEvent.end_game_win);

        const summaryPrompt = new PromptContentSubcomponent(["gamesummary_win"], 5000);

        const translations = Services.get(TranslationsService);

        const gameplay = gameState.getCurrentGame();
        const totalWin = gameplay.getTotalWin();

        summaryPrompt.setText("title", translations.get("results"));
        summaryPrompt.setText("total_win", "0");

        return new Parallel([
            () => Components.get(PromptComponent).showPrompt(summaryPrompt),
            () => Contract.getDelayedContract(16, () => TweenContract.wrapTween(
                tweenNumberText({
                    text: gameLayers.Prompts.scene.gamesummary_win.contents.total_win,
                    from: 0,
                    to: totalWin,
                    duration: 1000,
                    mutator: (value) => Services.get(CurrencyService).format(value, true)
                })
            )),
        ])
    }
}