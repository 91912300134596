import { gameState } from "appworks/model/game-state";
import { Record } from "appworks/model/gameplay/records/record";
import { ResultBuilder } from "appworks/server/builders/result-builder";
import { PYLBonusResult } from "model/results/pyl-bonus-result.ts";
import { lastInArray } from "appworks/utils/collection-utils";
import { SlingoChanceGameSchema, SlingoResponse, SlingoSpin } from "slingo/integration/slingo-schema";
import { PYLPrize } from "model/pyl-prize";

export class PYLBonusResultBuilder implements ResultBuilder {
    public filter(data: any, record?: Record, additionalData?: any): boolean {
        return data.feature?.chanceGames?.length;
    }

    public build(record: Record, data: SlingoResponse, additionalData?: any): PYLBonusResult[] {
        const results: PYLBonusResult[] = [];

        if (data.actions.length === 1 && data.actions[0] === "PURCHASE" && data.state === "COMPLETE") {
            record.cashWon = data.gameProgress.totalWin * 100;
        }

        if (gameState.getCurrentGame().isRecovered) {
            for (const chanceGame of data.feature.chanceGames) {
                results.push(this.processChanceGame(chanceGame));
            }
        } else {
            results.push(this.processChanceGame(lastInArray(data.feature.chanceGames)));
        }

        return results;
    }

    protected processChanceGame(game: SlingoChanceGameSchema): PYLBonusResult {
        const gameId = game.gameConfig.id;

        if (gameId.includes("take-your-pick")) {
            return this.buildTakeYourPickBonusResult(game);
        } else if (gameId.includes("press-your-luck")) {
            return this.buildPressYourLuckBonusResult(game);
        } else if (gameId.includes("spin-win")) {
            return this.buildSpinAWinBonusResult(game);
        } else if (gameId.includes("lucky-press")) {
            return this.buildLuckyPressBonusResult(game);
        }
    }

    protected buildTakeYourPickBonusResult(game: SlingoChanceGameSchema): PYLBonusResult {
        const variant = game.gameConfig.id.includes("high") ? "high" : "low";

        const prizes: PYLPrize[] = [];

        const misses = (game.params.misses as string).split(", ");

        for (const payout of game.payout) {
            prizes.push({
                multiplier: payout / game.stake,
                cashWon: payout * 100,
                misses: misses.splice(0, 3).map(miss => parseFloat(miss)),
                whammy: payout === 0
            });
        }

        const result = new PYLBonusResult("take_your_pick", prizes, variant);

        return result;
    }

    protected buildSpinAWinBonusResult(game: SlingoChanceGameSchema): PYLBonusResult {
        const variant = game.gameConfig.id.includes("high") ? "high" : "low";

        const payouts = game.payout;

        const wheel1prize: PYLPrize = {
            multiplier: 0,
            cashWon: payouts[0] * 100
        }

        const wheel2prize: PYLPrize = {
            multiplier: 0,
            cashWon: payouts[1] * 100,
            whammy: payouts[1] === 0
        }

        const wheel3cashWon = (game.totalWin * 100) - wheel1prize.cashWon - wheel2prize.cashWon;
        const wheel3multiplier = (game.totalWin * 100) / (wheel1prize.cashWon + wheel2prize.cashWon);

        const wheel3Prize: PYLPrize = {
            cashWon: wheel3cashWon,
            multiplier: wheel3multiplier,
            whammy: wheel3multiplier === 1
        }

        const result = new PYLBonusResult("spin_a_win", [wheel1prize, wheel2prize, wheel3Prize], variant);

        return result;
    }

    protected buildLuckyPressBonusResult(game: SlingoChanceGameSchema): PYLBonusResult {
        const variant = game.gameConfig.id.includes("high") ? "high" : "low";

        const prizes: PYLPrize[] = [];

        for (const payout of game.payout) {
            prizes.push({
                multiplier: payout / game.stake,
                cashWon: payout * 100,
                whammy: payout === 0
            });
        }

        const result = new PYLBonusResult("lucky_press", prizes, variant);

        return result;
    }

    protected buildPressYourLuckBonusResult(game: SlingoChanceGameSchema): PYLBonusResult {
        const variant = game.gameConfig.id.includes("high") ? "high" : "low";

        const prizes: PYLPrize[] = [];

        for (const round of game.games) {
            for (const payout of round.payout) {
                prizes.push({
                    multiplier: payout / round.stake,
                    cashWon: payout * 100,
                    extraLife: round.params.lifeChange > 0,
                    whammy: (round.params.lifeChange < 0) || payout === 0,
                });
            }
        }

        const result = new PYLBonusResult("press_your_luck", prizes, variant);

        const riskValue = lastInArray(game.games).params?.riskValue ?? game.params?.riskValue;
        if (riskValue) {
            result.riskValue = riskValue * 100;
        }

        return result;
    }
}
