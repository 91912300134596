import { Components } from 'appworks/components/components';
import { PromptComponent } from 'appworks/components/prompt/prompt-component';
import { SpineContainer } from 'appworks/graphics/pixi/spine-container';
import { PYLBigWinFactory } from 'components/factories/pyl-big-win-factory';
import { PYLReelMatrixFactory } from 'components/factories/pyl-reel-matrix-factory';
import { PYLSpinAWinBonusMatrixComponentFactory } from 'components/factories/pyl-spin-a-win-bonus-matrix-component-factory';
import { PYLBoardComponent } from 'components/pyl-board-component';
import { PYLBonusWinCounterComponent } from 'components/pyl-bonus-win-counter-component';
import { PYLFooterComponent } from 'components/pyl-footer-component';
import { PYLSlingoLadderComponent } from 'components/pyl-slingo-ladder-component';
import { PYLSpinAWinBonusComponent } from 'components/pyl-spin-a-win-bonus-component';
import { PYLTicketMatrixComponent } from 'components/pyl-ticket-matrix-component';
import { PYLWhammyAnimationComponent } from 'components/pyl-whammy-animation-component';
import { SocialBetBarComponent } from 'components/social-bet-bar-component';
import { SocialGMRAlertComponent } from 'components/social-gmr-alert-component';
import { SocialPreloaderComponent } from 'components/social-preloader-component';
import { SocialSlingoCelebrationComponent } from 'components/social-slingo-celebration-component';
import { LayerNames } from 'game-layers';
import { GMRSuperSpinWheelComponent } from 'gaming-realms/components/gmr-super-spin-wheel-component';
import { SlingoReelHighlightComponent } from 'slingo/components/slingo-reel-highlight-component';
import { SlingoSpinsCounterComponent } from 'slingo/components/slingo-spins-counter-component';
import { CountMode } from 'slotworks/components/bigwin/big-win-component';
import { CelebrateWinComponent } from 'slotworks/components/celebrate-win/celebrate-win-component';
import { GenericComponentFactory } from 'slotworks/components/factories/generic-component-factory';

/**
 * Replacement for PYLComponentSetup. Replacements commented out.
 */
export function SocialPylComponentSetup() {
  SpineContainer.AUTO_UPDATE_SPEED = true;

  // Components.register(new GenericComponentFactory(GMRPreloaderComponent), null);
  Components.register(new GenericComponentFactory(SocialPreloaderComponent), null);
  Components.register(new GenericComponentFactory(PromptComponent), 0);
  // Components.register(new GenericComponentFactory(GMRAlertComponent), 0);
  Components.register(new GenericComponentFactory(SocialGMRAlertComponent), 0);
  Components.register(new GenericComponentFactory(GMRSuperSpinWheelComponent));
  // Components.register(new GenericComponentFactory(BetBarComponent));
  Components.register(new GenericComponentFactory(SocialBetBarComponent));
  Components.register(new GenericComponentFactory(CelebrateWinComponent));
  Components.register(
    new PYLBigWinFactory(CountMode.LINEAR, true, [
      {
        threshold: 0,
        countTime: 2000,
        waitTime: 3000,
        scene: 'win',
      },
      {
        threshold: 14,
        countTime: 7000,
        waitTime: 3000,
        scene: 'bigwin',
      },
      {
        threshold: 100,
        countTime: 7000,
        waitTime: 3000,
        scene: 'megawin',
      },
    ]),
  );

  Components.register(new GenericComponentFactory(PYLFooterComponent));
  // Components.register(new GenericComponentFactory(SlingoCelebrationComponent, LayerNames.CelebrationContent));
  Components.register(
    new GenericComponentFactory(SocialSlingoCelebrationComponent, LayerNames.CelebrationContent),
  );
  Components.register(
    new GenericComponentFactory(SlingoSpinsCounterComponent, LayerNames.SpinCounter),
  );
  Components.register(
    new PYLReelMatrixFactory(true, {
      fontFamily: 'impact-regular',
      stroke: '#000',
      strokeThickness: 5,
      dropShadow: true,
    }),
    3,
  );
  Components.register(
    new GenericComponentFactory(PYLTicketMatrixComponent, { parallelCelebration: false }),
    3,
  );
  Components.register(new GenericComponentFactory(SlingoReelHighlightComponent));

  Components.register(new GenericComponentFactory(PYLSlingoLadderComponent));
  Components.register(new GenericComponentFactory(PYLWhammyAnimationComponent));
  Components.register(new GenericComponentFactory(PYLBoardComponent));
  Components.register(new PYLSpinAWinBonusMatrixComponentFactory([7], 'SpinAWinMatrixContent0'), 3);
  Components.register(new PYLSpinAWinBonusMatrixComponentFactory([7], 'SpinAWinMatrixContent1'), 3);
  Components.register(new PYLSpinAWinBonusMatrixComponentFactory([9], 'SpinAWinMatrixContent2'), 3);
  Components.register(new GenericComponentFactory(PYLSpinAWinBonusComponent));
  Components.register(new GenericComponentFactory(PYLBonusWinCounterComponent));
}
