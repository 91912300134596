import { Components } from "appworks/components/components";
import { Container } from "appworks/graphics/pixi/container";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Text } from "appworks/graphics/pixi/text";
import { gameState } from "appworks/model/game-state";
import { commsManager } from "appworks/server/comms-manager";
import { Exit } from "appworks/state-machine/data/exit";
import { stateMachine } from "appworks/state-machine/state-machine";
import { deepClone } from "appworks/utils/collection-utils";
import { Timer } from "appworks/utils/timer";
import { Easing, Tween } from "appworks/utils/tween";
import { PYLBoardComponent } from "components/pyl-board-component";
import { PYLSpinAWinBonusComponent } from "components/pyl-spin-a-win-bonus-component";
import { PYLSpinAWinBonusMatrixComponent } from "components/pyl-spin-a-win-bonus-matrix-component";
import { gameLayers } from "game-layers";
import { PYLBonusResult } from "model/results/pyl-bonus-result.ts";
import { Graphics, ParticleContainer, RenderTexture, Texture, autoDetectRenderer } from "pixi.js";
import { offlineForceHelper } from "slotworks/debug/menu/offline/offline-force-helper";
import { mockForceHelper } from "slotworks/debug/menu/offline/mock-force-helper";
import { BonusResult } from "slotworks/model/gameplay/records/results/bonus-result";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";
import { GridOnlySupplement } from "slotworks/model/gameplay/supplements/grid-only-supplement";
import { PYLLuckyPressBonusState } from "states/pyl-lucky-press-bonus-state";
import { PYLPressYourLuckBonusState } from "states/pyl-press-your-luck-bonus-state";
import { PYLSpinAWinBonusState } from "states/pyl-spin-a-win-bonus-state";
import { PYLTakeYourPickBonusState } from "states/pyl-take-your-pick-bonus-state";
import { fadeIn2 } from "appworks/utils/animation/fade2";
import { Services } from "appworks/services/services";
import { SpineService } from "appworks/graphics/spine/spine-service";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { PYLMatrixComponent } from "components/pyl-matrix-component";
import { dualTransform, transform } from "appworks/utils/animation/transform";
import { ResetPivot, CenterPivot } from "appworks/graphics/pixi/group";
import { PYLWhammyAnimationComponent } from "components/pyl-whammy-animation-component";
import { Sequence } from "appworks/utils/contracts/sequence";
import { Contract } from "appworks/utils/contracts/contract";
import { PYLSpinAWinSymbolSubcomponent } from "components/pyl-spin-a-win-symbol-subcomponent";
import { ParticleService } from "appworks/graphics/particles/particle-service";
import { PolygonalChain } from "pixi-particles";
import { GMRAlertComponent } from "gaming-realms/components/gmr-alert-component";
import { GenericRecord } from "appworks/model/gameplay/records/generic-record";
import { gameLoop } from "appworks/core/game-loop";
import { SoundService } from "appworks/services/sound/sound-service";
import { BigWinComponent } from "slotworks/components/bigwin/big-win-component";
import {ShockwaveFilter} from "pixi-filters";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { shake, shakeDualPosition } from "appworks/utils/animation/shake";
import { PYLBigWinComponent } from "components/pyl-big-win-component";
import { tween2 } from "appworks/utils/animation/tween2";
import { Signal } from "signals";
import { wrapContract } from "appworks/utils/contracts/wrap-contract";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { Spine } from "pixi-spine";

export function PYLDebugSetup() {
    (window as any).takeYourPick = (variant = "low") => {
        const result = new PYLBonusResult("take_your_pick", [
            //{ multiplier: 0.1, cashWon: 10, misses: [0.2, 0.3, 0.3] },
            //{ multiplier: 0.5, cashWon: 50, misses: [0.3, 0.5, 0] },
            //{ multiplier: 1, cashWon: 100, misses: [0.5, 0, 0] },
            { multiplier: 0.2, cashWon: 40, misses: [0.3, 0, 0.1], whammy: false },
            { multiplier: 0.2, cashWon: 40, misses: [0, 0, 0.2], whammy: false },
            { multiplier: 0.2, cashWon: 40, misses: [0, 0, 0], whammy: false },
            { multiplier: 0, cashWon: 0, misses: [0, 0, 0], whammy: true }
        ], variant);

        const record = gameState.getCurrentGame().getCurrentRecord();
        record.results.push(result);
        record.cashWon = 100000;

        gameLayers.Collect.jumpToScene("collect");
        gameLayers.BonusTransitionLogo.jumpToScene("collect");

        const state = stateMachine.getNodeByStateType(PYLTakeYourPickBonusState);
        stateMachine.setCurrentState(state);
    }

    (window as any).luckyPress = (variant = "low") => {
        const result = new PYLBonusResult("lucky_press", [
            { multiplier: 0.8, cashWon: 160 },
            { multiplier: 2.2, cashWon: 440 },
            { multiplier: 0, cashWon: 0, whammy: true }
        ], variant);

        const record = gameState.getCurrentGame().getCurrentRecord();
        record.results.push(result);

        gameLayers.Collect.jumpToScene("collect");
        gameLayers.BonusTransitionLogo.jumpToScene("collect");

        const state = stateMachine.getNodeByStateType(PYLLuckyPressBonusState);
        stateMachine.setCurrentState(state);
    }

    (window as any).pressYourLuck = (variant = "low") => {
        const result = new PYLBonusResult("press_your_luck", [
            //{ multiplier: 10, cashWon: 1000 },
            { multiplier: 0.5, cashWon: 100, whammy: false, extraLife: true },
            { multiplier: 0.5, cashWon: 100, whammy: false, extraLife: true },
            { multiplier: 0.5, cashWon: 100, whammy: false, extraLife: true },
            // { multiplier: 0, cashWon: 0, extraLife: true },
            // { multiplier: 10, cashWon: 1000, extraLife: true },
            //{ multiplier: 0, cashWon: 0, whammy: true },
            //{ multiplier: 10, cashWon: 1000 },
        ], variant);

        result.riskValue = 800 * 0.2;

        const record = gameState.getCurrentGame().getCurrentRecord();
        record.cashWon = 800;
        record.results.push(result);

        gameLayers.Collect.jumpToScene("collect");
        gameLayers.BonusTransitionLogo.jumpToScene("collect");

        const state = stateMachine.getNodeByStateType(PYLPressYourLuckBonusState);
        stateMachine.setCurrentState(state);
    }

    (window as any).spinAWin = (variant = "low") => {
        const result = new PYLBonusResult("spin_a_win", [
            { multiplier: 0, cashWon: 50 },
            { multiplier: 0, cashWon: 0, whammy: true },
            //{ multiplier: 2, cashWon: 150 }
            { multiplier: 1, cashWon: 0, whammy: true }
        ], variant);

        const record = gameState.getCurrentGame().getCurrentRecord();
        record.results.push(result);

        gameLayers.Collect.jumpToScene("collect");
        gameLayers.BonusTransitionLogo.jumpToScene("collect");

        const state = stateMachine.getNodeByStateType(PYLSpinAWinBonusState);
        stateMachine.setCurrentState(state);
    }

    (window as any).test = async () => {
        let count = 0;

        const countSpines = (container: PIXI.Container) => {
            for (const child of container.children) {
                if (child instanceof Spine) {
                    count++;
                }
                if (child instanceof PIXI.Container) {
                    countSpines(child);
                }
            }
        };

        countSpines(Services.get(CanvasService).stage);
        console.log(count);
    }

    //Services.get(SoundService).debugSoundEvents = true;

    //commsManager.queueResponses(require("../mocks/wrapperUpdateBug.json"));
    //commsManager.queueResponses(require("../mocks/wheel2.json"));

    //commsManager.queueResponses(require("../mocks/take-your-pick-low.json"));
    //commsManager.queueResponses(require("../mocks/take-your-pick-high.json"));
    //commsManager.queueResponses(require("../mocks/lucky-press-low.json"));
    //commsManager.queueResponses(require("../mocks/lucky-press-high.json"));
    //commsManager.queueResponses(require("../mocks/spin-a-win-low.json"));
    //commsManager.queueResponses(require("../mocks/spin-a-win-high.json"));
    //commsManager.queueResponses(require("../mocks/press-your-luck-low.json"));
    //commsManager.queueResponses(require("../mocks/press-your-luck-high.json"));
    //commsManager.queueResponses(require("../mocks/ssw_bug_4.json"));  
}