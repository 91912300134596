import { Layers } from "appworks/graphics/layers/layers";
import { PYLSpinAWinWhammyLandSymbolBehavior } from "behaviors/pyl-spin-a-win-whammy-land-symbol-behavior";
import { PYLWhammyLandSymbolBehavior } from "behaviors/pyl-whammy-land-symbol-behavior";
import { PYLSpinAWinBonusMatrixComponent } from "components/pyl-spin-a-win-bonus-matrix-component";
import { PYLSpinAWinSymbolSubcomponent } from "components/pyl-spin-a-win-symbol-subcomponent";
import { gameLayers } from "game-layers";
import { MatrixFactory } from "slotworks/components/factories/matrix-factory";
import { AbstractMatrixComponent } from "slotworks/components/matrix/abstract-matrix-component";
import { ReelSpinner, SpinStageEvents } from "slotworks/components/matrix/reel/transition-behaviours/spin/reel-spinner";
import { SpinReelTransition } from "slotworks/components/matrix/reel/transition-behaviours/spin/spin-reel-transition";
import { AnimateSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/animate-symbol-behaviour";
import { FrameSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/frame-behaviour";
import { SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";
import { slotDefinition } from "slotworks/model/slot-definition";

export class PYLSpinAWinBonusMatrixComponentFactory extends MatrixFactory {
    constructor(matrixGrid: number[], protected contentLayer: string) {
        super(matrixGrid);

        const minStopDistance = this.matrixGrid.reduce((max, current) => current > max ? current : max, 0) + 1;

        this.spinSpeed = 0.01;

        this.defaultReelset = "base";
        this.defaultStops = new Array(this.matrixGrid.length).fill(0);

        this.spinStages = [
            {
                name: "accelerate",
                s: -1,
                u: 0,
                v: -this.spinSpeed,
                events: [
                    {
                        name: SpinStageEvents.START,
                        delay: 2000
                    }
                ]
            },
            {
                name: "minRepeat",
                s: -1,
                u: -this.spinSpeed,
                v: -this.spinSpeed
            },
            {
                name: "repeat",
                s: -1,
                u: -this.spinSpeed,
                v: -this.spinSpeed,
                events: [
                    {
                        name: SpinStageEvents.START,
                        delay: 2000
                    }
                ]
            },
            {
                name: "stop",
                s: -minStopDistance,
                u: -this.spinSpeed,
                v: 0,
                events: [
                    {
                        name: SpinStageEvents.STOP,
                        delay: 2000
                    }
                ]
            },
            {
                name: "complete",
                s: 0,
                t: 0,
                easing: "Quadratic.Out"
            }
        ];

    }

    protected createMatrix(): AbstractMatrixComponent {
        return new PYLSpinAWinBonusMatrixComponent(
            this.matrixGrid,
            this.defaultStops,
            [
                ["wheelsymbol_2"],
            ],
            Layers.get(this.contentLayer),
            gameLayers.SpinAWinAnimations,
            PYLSpinAWinSymbolSubcomponent
        );
    }

    protected createTransition(matrix: AbstractMatrixComponent) {
        ReelSpinner.addStageSet({
            id: "spinawin",
            spin: [this.spinStages],
            skip: [this.skipStages],
            anticipate: [this.anticipationStages],
            quickSpin: [this.quickSpinStages]
        });

        matrix.setTransition(new SpinReelTransition());
    }

    protected addSymbolBehaviours(matrix: AbstractMatrixComponent<SymbolSubcomponent>): void {
        matrix.addDefaultSymbolBehaviour((symbol) => new PYLSpinAWinWhammyLandSymbolBehavior(symbol), ["whammy_1", "whammy_2", "whammy_3"]);
    }
}