import { gameState } from "appworks/model/game-state";
import { commsManager } from "appworks/server/comms-manager";
import { Services } from "appworks/services/services";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { uiFlags, UIFlag } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { gameLayers } from "game-layers";
import { GMRAction } from "gaming-realms/integration/gmr-schema";
import { SlingoState } from "slingo/integration/slingo-schema";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoRequestSpinState } from "slingo/states/slingo-request-spin-state";
import { BonusResult } from "slotworks/model/gameplay/records/results/bonus-result";
import { SpinRequestPayload } from "slotworks/model/gameplay/requests/spin-request-payload";

export class PYLSlingoRequestSpinState extends SlingoRequestSpinState {
    public onEnter(cascadeSkip?: boolean): void {
        super.onEnter(cascadeSkip);

        gameLayers.Collect.setScene("default").execute();
        gameLayers.BonusTransitionLogo.setScene("default").execute();
    }

    protected sendRequest(): Contract<void> {
        const contracts: Array<() => Contract> = []

        if (!this.config.skipRequest) {
            contracts.push(() => Contract.wrap(() => uiFlags.set(UIFlag.AWAITING_RESPONSE, true)));
            contracts.push(() => commsManager.request(new SpinRequestPayload()));
        }

        contracts.push(() => Contract.wrap(() => {
            uiFlags.set(UIFlag.AWAITING_RESPONSE, false);

            const gameplay = gameState.getCurrentGame();
            gameplay.setToLatestRecord();

            // Don't update balance if state is complete, it will update before any bonuses etc are played out
            const record = gameplay.getCurrentRecord() as SlingoRecord;
            const hasBonusResult = record.hasResultOfType(BonusResult);
            if (!hasBonusResult || (hasBonusResult && !record.lastChild)) {
                Services.get(TransactionService).setBalance(gameplay.balance);
            }
        }));

        return new Sequence(contracts);
    }
}