import { RequestPayload } from "appworks/model/gameplay/requests/request-payload";

export class GMRFeaturePickRequestPayload extends RequestPayload {
    public pick: string | number;

    constructor(pick: string | number) {
        super();

        this.pick = pick;
    }
}
