import { Components } from "appworks/components/components";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { Layers } from "appworks/graphics/layers/layers";
import { LoaderService } from "appworks/loader/loader-service";
import { Services } from "appworks/services/services";
import { uiFlags, UIFlag } from "appworks/ui/flags/ui-flags";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { PYLSpinAWinBonusComponent } from "components/pyl-spin-a-win-bonus-component";
import { gameLayers } from "game-layers";
import { GamingRealms } from "gaming-realms/gaming-realms";
import { SlingoInitState } from "slingo/states/slingo-init-state";

declare const __VERSION__: string;

export class PYLInitState extends SlingoInitState {
    public onExit(): void {
        super.onExit();
        gameLayers.Background.defaultScene().execute();

        Components.get(PYLSpinAWinBonusComponent).setVisible(false);
        Components.get(PYLSpinAWinBonusComponent).setEnabled(false);
    }

    protected showOnBoard(): Contract<any> {
        return new Contract<void>((resolve) => {
            const onboardLayer = Layers.get("Onboard");
            onboardLayer.setScene("onboard").execute();
            onboardLayer.show();

            gameLayers.Onboard.add(gameLayers.Onboard.scene.active.contents.whammy_1, false, true);

            gameLayers.Onboard.scene.onboard.contents.whammy_1.play("loop");
            gameLayers.Onboard.scene.onboard.contents.whammy_onboard.play("loop");

            gameLayers.Onboard.scene.onboard.contents.version.text = __VERSION__;

            gameLayers.Onboard.scene.onboard.contents.continue.getLabels().forEach((label) => {
                label.visible = false;
            });

            const backgroundLayer = Layers.get("Background");
            if (backgroundLayer && backgroundLayer.hasScene("onboard")) {
                backgroundLayer.setScene("onboard").execute();
                backgroundLayer.show();
            }

            const paginationComponent = this.setupOnboardPagination();

            const loadBarBacking = onboardLayer.getSprite("bar_backing");
            const loadBar = onboardLayer.getSprite("load_bar");
            const mask = onboardLayer.getSprite("load_bar_mask") || onboardLayer.getShape("load_bar_mask");
            loadBar.mask = mask;

            const targetWidth = { landscape: mask.landscape.width, portrait: mask.portrait.width };
            mask.landscape.width = mask.portrait.width = 0;

            const onProgress = (stage, progress) => {
                mask.landscape.width = targetWidth.landscape * (progress / 100);
                mask.portrait.width = targetWidth.portrait * (progress / 100);
            }

            const continueButton = onboardLayer.getButton("continue");
            continueButton.setEnabled(false);
            continueButton.on(ButtonEvent.CLICK, () => {
                if (paginationComponent) { paginationComponent.hide(); }
                onboardLayer.defaultScene().then(resolve);
                GamingRealms.wrapperInstance.updateAction(so.GameAction.RESPONSIBLE_GAMING_CLOSE);
            });

            const loader = Services.get(LoaderService);
            loader.loadStage(3, onProgress).then(() => {
                uiFlags.set(UIFlag.GAME_STARTED, true);
                continueButton.setEnabled(true);

                loadBarBacking.visible = false;
                loadBar.visible = false;
            });
        });
    }
}
