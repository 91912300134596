import { Layers } from "appworks/graphics/layers/layers";
import { Container } from "appworks/graphics/pixi/container";
import { CenterPivot, PIXIElement, ResetPivot } from "appworks/graphics/pixi/group";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { State } from "appworks/state-machine/states/state";
import { UIFlag, uiFlags } from "appworks/ui/flags/ui-flags";
import { fadeIn2, fadeOut2 } from "appworks/utils/animation/fade2";
import { dualTransform, transform } from "appworks/utils/animation/transform";
import { flatten } from "appworks/utils/collection-utils";
import { buttonPressContract } from "appworks/utils/contracts/button-press-contract";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { Timer } from "appworks/utils/timer";
import { Easing } from "appworks/utils/tween";
import { gameLayers } from "game-layers";
import { PYLPrize } from "model/pyl-prize";
import { PYLBonusResult } from "model/results/pyl-bonus-result.ts";
import { AdjustmentFilter } from "pixi-filters";
import { SignalBinding } from "signals";
import * as dat from 'dat.gui';
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { PromptContentSubcomponent } from "appworks/components/prompt/prompt-content-subcomponent";
import { Components } from "appworks/components/components";
import { PromptComponent } from "appworks/components/prompt/prompt-component";
import { Service } from "appworks/services/service";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { PYLPressYourLuckBonusState } from "states/pyl-press-your-luck-bonus-state";
import { tweenNumberText } from "appworks/utils/animation/tween-number-text";
import { BigWinComponent } from "slotworks/components/bigwin/big-win-component";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { ScreenShakeComponent } from "appworks/components/effects/screen-shake-component";
import { shakeDualPosition } from "appworks/utils/animation/shake";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { PYLBigWinComponent } from "components/pyl-big-win-component";

export abstract class PYLAbstractBonusState extends State {
    protected onBonusSceneEnter: SignalBinding;
    protected nonBonusElements: PIXIElement[];
    protected bonusBackgroundColorMatrixFilter: PIXI.filters.ColorMatrixFilter = new PIXI.filters.ColorMatrixFilter();
    protected bonusId: string;
    protected variant: string;
    protected isThresholdReached: boolean = false;

    public onEnter(cascadeSkip?: boolean): void {
        this.nonBonusElements = [
            gameLayers.SlingoLadder.container,
            gameLayers.MatrixContent.container,
            gameLayers.MatrixBackground.container,
            gameLayers.SpinButton.container,
            gameLayers.SpinCounter.container,
            gameLayers.TicketMatrix.container,
            gameLayers.TicketMatrixBacking.container,
            gameLayers.SymbolAnimations.container,
            gameLayers.Footer.container,
            gameLayers.Collect.container,
            gameLayers.MenuButton.container,
        ];

        this.isThresholdReached = false;

        Services.get(TransactionService).setTotalWin(0);

        const record = gameState.getCurrentGame().getCurrentRecord();
        const result = record.getFirstResultOfType(PYLBonusResult);
        this.bonusId = result.id.split("-")[0];
        this.variant = result.variant;

        if (this.onBonusSceneEnter) {
            this.onBonusSceneEnter.detach();
        }
        this.onBonusSceneEnter = gameLayers.Bonus.onSceneEnter.add((sceneName: keyof typeof gameLayers.Bonus.scene) => {
            if (sceneName === this.bonusId || (sceneName === "board_game" && (this.bonusId === "press_your_luck" || this.bonusId === "lucky_press"))) {
                this.onBonusSceneSet(result.variant);
                this.onBonusSceneEnter.detach();
            }
        });

        new Sequence([
            () => Contract.wrap(() => Services.get(SoundService).customEvent("bonus_start")),
            () => this.bonusTransition(),
            () => Contract.wrap(() => {
                gameLayers.Logo.jumpToScene(`${this.bonusId}_${this.variant}`);
                gameLayers.Logo.container.alpha = 0;
            }),
            () => new Parallel([
                () => gameLayers.Bonus.setScene((this.bonusId === "press_your_luck" || this.bonusId === "lucky_press") ? "board_game" : this.bonusId),
                () => gameLayers.WhammyAnims.setScene((this.bonusId === "press_your_luck" || this.bonusId === "lucky_press") ? "board_game" : this.bonusId),
                this.bonusId === "press_your_luck" ? () => Contract.empty() : () => gameLayers.BonusUI.setScene("bonus"),
                () => fadeIn2([
                    gameLayers.Logo.container,
                    gameLayers.MenuButton.container
                ], 800),
            ]),
            () => fadeIn2(this.nonBonusElements, 0),
            ...this.fixedPrizesContracts()
        ]).execute();
    }

    public complete(): void {
        this.onBonusSceneEnter.detach();

        const record = gameState.getCurrentGame().getCurrentRecord();

        new Sequence([
            record.cashWon > 0 ? () => this.bigwin() : () => Contract.empty(),
            () => new Parallel([
                () => Contract.wrap(() => {
                    uiFlags.set(UIFlag.BONUS, false);
                    Services.get(SoundService).customEvent("bonus_end")
                }),
                () => gameLayers.Bonus.defaultScene(),
                () => gameLayers.WhammyAnims.defaultScene(),
                () => gameLayers.BonusUI.defaultScene(),
                () => gameLayers.BonusBackground.defaultScene(),
                () => gameLayers.Logo.defaultScene(),
                () => fadeIn2(gameLayers.BetBar.scene.active.contents.ui_stake_menu)
            ]),
            () => Contract.wrap(() => {
                super.complete();
            })
        ]).execute();
    }

    protected fixedPrizesContracts() {
        const record = gameState.getCurrentGame().getCurrentRecord();
        const results = record.getResultsOfType(PYLBonusResult);
        const prizes = flatten(results.map(result => result.prizes));

        return [
            ...prizes.map((prize, i) => () => this.prizeStep(prize, i, i === prizes.length - 1, results[0].variant)),
            () => Contract.wrap(() => this.complete())
        ];
    }

    protected onBonusSceneSet(variant?: string): void { }

    protected prizeStep(prize: PYLPrize, stepIndex: number, isLastStep: boolean, variant?: string): Contract {
        return Contract.empty();
    }

    protected bonusTransition(): Contract {
        return new Sequence([
            () => Contract.wrap(() => {
                if (gameLayers.BonusTransitionLogo.currentSceneIs("collect")) {
                    ResetPivot(gameLayers.BonusTransitionLogo.scene.collect.contents[`${this.bonusId}_logo_${this.variant}`]);
                    CenterPivot(gameLayers.BonusTransitionLogo.scene.collect.contents[`${this.bonusId}_logo_${this.variant}`]);
                }
            }),
            () => new Parallel([
                gameLayers.BonusTransitionLogo.currentSceneIs("collect") ?
                    () => dualTransform(gameLayers.BonusTransitionLogo.scene.collect.contents[`${this.bonusId}_logo_${this.variant}`], gameLayers.BonusTransitionLogo.scene.collect.contents.positions[`${this.bonusId}_logo_end`].getCenterPos(), 1000, Easing.Back.Out)
                    : () => Contract.empty(),
                () => gameLayers.WhammyAnims.scene.default.contents.bonus_intro_landscape.playOnce("animation"),
                () => gameLayers.WhammyAnims.scene.default.contents.bonus_intro_portrait.playOnce("animation", false, 1.45),
                () => Contract.getDelayedContract(1300, () => Contract.wrap(() => {
                    [...this.nonBonusElements, gameLayers.Logo.container, gameLayers.BetBar.scene.active.contents.ui_stake_menu].forEach(el => el.alpha = 0);
                    gameLayers.Bonus.jumpToScene(`entry_${this.bonusId}`);
                    gameLayers.BonusBackground.jumpToScene("bonus");
                    gameLayers.BonusBackground.container.filters = [this.bonusBackgroundColorMatrixFilter];

                    const scene = `entry_${this.bonusId}`;
                    const lowVariantLogo = `${this.bonusId}_logo_low`;
                    const highVariantLogo = `${this.bonusId}_logo_high`;

                    gameLayers.Bonus.scene[scene].contents[lowVariantLogo].visible = this.variant === "low";
                    gameLayers.Bonus.scene[scene].contents[highVariantLogo].visible = this.variant === "high";

                    this.setBonusBackgroundColor(this.bonusId);

                    uiFlags.set(UIFlag.BONUS, true);
                    uiFlags.set(UIFlag.SPINNING, false);
                }))
            ]),
            () => buttonPressContract(gameLayers.Bonus.getButton("continue")),
            () => gameLayers.BonusTransitionLogo.defaultScene(),
        ]);
    }

    protected setBonusBackgroundColor(bonusId: string) {
        this.bonusBackgroundColorMatrixFilter.reset();

        //this.enableDebugging();

        if (bonusId === "take_your_pick") {
            this.bonusBackgroundColorMatrixFilter.saturate(0, true);
            this.bonusBackgroundColorMatrixFilter.hue(0, true);
            this.bonusBackgroundColorMatrixFilter.brightness(1.36, true);
            this.bonusBackgroundColorMatrixFilter.contrast(-0.2, true);
        } else if (bonusId === "press_your_luck") {
            this.bonusBackgroundColorMatrixFilter.saturate(0.75, true);
            this.bonusBackgroundColorMatrixFilter.hue(0, true);
            this.bonusBackgroundColorMatrixFilter.brightness(1.36, true);
            this.bonusBackgroundColorMatrixFilter.contrast(-0.4, true);
        } else if (bonusId === "lucky_press") {
            this.bonusBackgroundColorMatrixFilter.saturate(1.9, true);
            this.bonusBackgroundColorMatrixFilter.hue(172, true);
            this.bonusBackgroundColorMatrixFilter.brightness(0.81, true);
            this.bonusBackgroundColorMatrixFilter.contrast(1, true);
            this.bonusBackgroundColorMatrixFilter.greyscale(0.08, true);
        } else {
            this.bonusBackgroundColorMatrixFilter.saturate(1.9, true);
            this.bonusBackgroundColorMatrixFilter.hue(140, true);
            this.bonusBackgroundColorMatrixFilter.brightness(0.73, true);
            this.bonusBackgroundColorMatrixFilter.contrast(1, true);
            this.bonusBackgroundColorMatrixFilter.greyscale(0.11, true);
        }
    }

    protected bigwin(): Contract<any> {
        const record = gameState.getCurrentGame().getCurrentRecord();
        const totalWin = record.cashWon;
        const stake = Services.get(SlotBetService).getTotalStake();
        const bigWinContract = Components.get(PYLBigWinComponent).showWin(totalWin, stake, true, this.isThresholdReached, this.bonusId, this.variant);
        
        return bigWinContract;
    }

    protected enableDebugging() {
        (window as any).state = this;

        const gui = (window as any).gui = new dat.GUI({
            name: "Bonus Background Color Matrix Filter",
        });

        const paramsEnabled = {
            saturation: false,
            hue: false,
            brightness: false,
            contrast: false,
            grey: false,
            night: false,
            predator: false,
        };
        
        const params = {
            saturation: 0,
            hue: 0,
            brightness: 1,
            contrast: 0,
            grey: 0.5,
            night: 0.5,
            predator: 0.5,
        }

        const saturation = gui.addFolder("saturation");
        saturation.add(params, "saturation", -1, 10, 0.01).onChange(() => applyMatrix());
        saturation.add(paramsEnabled, "saturation").onChange(() => applyMatrix());;

        const hue = gui.addFolder("hue");
        hue.add(params, "hue", 0, 360, 0.01).onChange(() => applyMatrix());
        hue.add(paramsEnabled, "hue").onChange(() => applyMatrix());;

        const brightness = gui.addFolder("brightness");
        brightness.add(params, "brightness", 0, 15, 0.01).onChange(() => applyMatrix());
        brightness.add(paramsEnabled, "brightness").onChange(() => applyMatrix());;

        const contrast = gui.addFolder("contrast");
        contrast.add(params, "contrast", -1, 15, 0.01).onChange(() => applyMatrix());
        contrast.add(paramsEnabled, "contrast").onChange(() => applyMatrix());;

        const grey = gui.addFolder("grey");
        grey.add(params, "grey", 0, 1, 0.01).onChange(() => applyMatrix());
        grey.add(paramsEnabled, "grey").onChange(() => applyMatrix());;

        const night = gui.addFolder("night");
        night.add(params, "night", 0, 1, 0.01).onChange(() => applyMatrix());
        night.add(paramsEnabled, "night").onChange(() => applyMatrix());;

        const predator = gui.addFolder("predator");
        predator.add(params, "predator", 0, 1, 0.01).onChange(() => applyMatrix());
        predator.add(paramsEnabled, "predator").onChange(() => applyMatrix());;

        const applyMatrix = () => {
            this.bonusBackgroundColorMatrixFilter.reset();

            if (paramsEnabled.saturation) {
                this.bonusBackgroundColorMatrixFilter.saturate(params.saturation, true);
            }

            if (paramsEnabled.hue) {
                this.bonusBackgroundColorMatrixFilter.hue(params.hue, true);
            }

            if (paramsEnabled.brightness) {
                this.bonusBackgroundColorMatrixFilter.brightness(params.brightness, true);
            }

            if (paramsEnabled.contrast) {
                this.bonusBackgroundColorMatrixFilter.contrast(params.contrast, true);
            }

            if (paramsEnabled.grey) {
                this.bonusBackgroundColorMatrixFilter.greyscale(params.grey, true);
            }

            if (paramsEnabled.night) {
                this.bonusBackgroundColorMatrixFilter.night(params.night, true);
            }

            if (paramsEnabled.predator) {
                this.bonusBackgroundColorMatrixFilter.predator(params.predator, true);
            }
        }

        gui.add({
            reset: () => {
                console.log("reset");
                gui.__controllers.forEach((controller: any) => {
                    controller.setValue(controller.initialValue);
                });
                applyMatrix();
            }
        }, "reset");
    }
}