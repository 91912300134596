import { FadeTransition } from "appworks/transitions/fade-transition";
import { SpineBigWinTransition } from "appworks/transitions/spine-big-win-transition";
import { gameLayers } from "game-layers";
import { PYLFullHouseTransition } from "transitions/pyl-fullhouse-transition";

export function PYLSetupTransitions() {
    gameLayers.Bonus.setTransition(new FadeTransition(gameLayers.Bonus));
    gameLayers.BetBar.setTransition(new FadeTransition(gameLayers.BetBar));
    gameLayers.Logo.setTransition(new FadeTransition(gameLayers.Logo));
    gameLayers.BonusBackground.setTransition(new FadeTransition(gameLayers.BonusBackground));
    gameLayers.BonusUI.setTransition(new FadeTransition(gameLayers.BonusUI));
    gameLayers.CelebrationContent.addTransition("fullhouse", new PYLFullHouseTransition(gameLayers.CelebrationContent));
    gameLayers.CelebrationContent.addTransition("bigwin", new SpineBigWinTransition(gameLayers.CelebrationContent, "celebrations", "bw"));
    gameLayers.CelebrationContent.addTransition("megawin", new SpineBigWinTransition(gameLayers.CelebrationContent, "celebrations", "mw"));
}