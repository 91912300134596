import { AbstractComponent } from "appworks/components/abstract-component";
import { AnimatedSprite } from "appworks/graphics/animation/animated-sprite";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { GraphicsService } from "appworks/graphics/graphics-service";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { CancelGroup } from "appworks/utils/contracts/cancel-group";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { RandomFromArray } from "appworks/utils/math/random";
import { gameLayers } from "game-layers";

export enum WhammyAnimations {
    BARTENDER = "WhamAnim_BARTENDER",
    BULLRIDE = "WhamAnim_BULLRIDE",
    CRUISESHIP = "WhamAnim_CRUISESHIP",
    HULA = "WhamAnim_HULA",
    JULY4 = "WhamAnim_JULY4",
    OPERA = "WhamAnim_OPERA",
    PIZZA = "WhamAnim_PIZZA",
    ROLLERSKATE = "WhamAnim_ROLLERSKATE",
    TARZAN = "WhamAnim_Tarzan",
    UFO = "WhamAnim_UFO",
    UNICORN = "WhamAnim_UNICORN",
    WATERSKI = "WhamAnim_WATERSKI"
}

const winAnims = [
    WhammyAnimations.JULY4,
    WhammyAnimations.OPERA,
    WhammyAnimations.PIZZA,
    WhammyAnimations.ROLLERSKATE,
];

const loseAnims = [
    WhammyAnimations.BARTENDER,
    WhammyAnimations.BULLRIDE,
    WhammyAnimations.CRUISESHIP,
    WhammyAnimations.HULA,
    WhammyAnimations.TARZAN,
    WhammyAnimations.UFO,
    WhammyAnimations.UNICORN,
    WhammyAnimations.WATERSKI,
]

export class PYLWhammyAnimationComponent extends AbstractComponent {
    protected activeAnim: AnimatedSprite;
    protected cancelGroup: CancelGroup = new CancelGroup();

    public init(): void {
        gameLayers.WhammyAnims.onSceneEnter.add((sceneName: string) => {
            if (gameLayers.WhammyAnims.scene.active.contents.mask) {
                gameLayers.WhammyAnims.scene.active.contents.mask.visible = false;
            }
            if (gameLayers.WhammyAnims.scene.active.contents.bg) {
                gameLayers.WhammyAnims.scene.active.contents.bg.visible = false;
            }

            const hitbox = gameLayers.WhammyAnims.scene.active.contents.hitbox;
            if (hitbox) {
                hitbox.alpha = 0;
                hitbox.addListener("pointertap", () => {
                    if (this.cancelGroup) {
                        this.cancelGroup.skip();
                    }
                });
            }
        });
    }

    public playAnim(type: "win" | "lose" = "lose"): Contract {
        this.cancelGroup.clear();

        return this.cancelGroup.contract((resolve, cancel) => {
            const animName = type === "win" ? RandomFromArray(winAnims) : RandomFromArray(loseAnims);

            if (this.activeAnim && !this.activeAnim.destroyed) {
                this.activeAnim.destroy();
            }

            this.activeAnim = Services.get(GraphicsService).createAnimation(animName);
            const position = gameLayers.WhammyAnims.getPosition("whammy");
    
            this.activeAnim.setDualPosition(position);
            gameLayers.WhammyAnims.add(this.activeAnim);
    
            if (gameLayers.WhammyAnims.scene.active.contents.mask) {
                this.activeAnim.mask = gameLayers.WhammyAnims.scene.active.contents.mask;
                gameLayers.WhammyAnims.scene.active.contents.mask.visible = true;
            }
    
            const hitbox = gameLayers.WhammyAnims.scene.active.contents.hitbox;
            if (hitbox) {
                hitbox.cursor = "pointer";
                hitbox.interactive = true;
            }
    
            let resolved = false;

            new Sequence([
                () => new Parallel([
                    () => this.activeAnim.playOnce(),
                    () => Services.get(SoundService).customEventContract(animName)
                ]),
                () => Contract.wrap(() => {
                    if (!resolved) {
                        resolve();
                        resolved = true;
                    }
                })
            ]).execute();

            cancel(() => {
                resolved = true;
                if (this.activeAnim && !this.activeAnim.destroyed) {
                    this.activeAnim.destroy();
                }
                Services.get(SoundService).customEvent("whammy_stop");
                const hitbox = gameLayers.WhammyAnims.scene.active.contents.hitbox;
                if (hitbox) {
                    hitbox.interactive = false;
                }
            });
        }, true);
    }
}
