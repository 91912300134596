import { Sprite } from "appworks/graphics/pixi/sprite";
import { Contract } from "appworks/utils/contracts/contract";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { Tween } from "appworks/utils/tween";
import { ColorMapFilter, ColorOverlayFilter } from "pixi-filters";
import {filters} from "pixi.js";

export type Dimmable = { filters: PIXI.Filter[] | null };

/** 0 = black, 1 = default brightness */
export function brightness(el: Dimmable, brightness: number, fadeTime = 0) : Contract {
    if (el.filters === null) {
        el.filters = [];
    }

    let dimFilter: filters.ColorMatrixFilter = el.filters.find(filter => filter instanceof filters.ColorMatrixFilter) as filters.ColorMatrixFilter;

    if (!dimFilter) {
        dimFilter = new filters.ColorMatrixFilter();
        el.filters.push(dimFilter);
        dimFilter.brightness(0, false);
    }

    if (dimFilter.alpha === 1-brightness) {
        return Contract.empty();
    }

    if (fadeTime === 0) {
        dimFilter.alpha = 1-brightness;
        return Contract.empty();
    }

    return TweenContract.wrapTween(new Tween(dimFilter).to({ alpha: 1-brightness }, fadeTime));
}