import { ClientController, BlastworksClientEvent } from 'client-controller';
import { PYLSpinAWinBonusState } from 'states/pyl-spin-a-win-bonus-state';

/**
 * Social bonus state to send client messages
 *
 * @class SocialSpinAWinBonusState
 * @typedef {SocialSpinAWinBonusState}
 * @extends {PYLSpinAWinBonusState}
 */
export class SocialSpinAWinBonusState extends PYLSpinAWinBonusState {
  /**
   * Send bonus start message
   *
   * @public
   * @override
   * @param {?boolean} [cascadeSkip]
   */
  public onEnter(cascadeSkip?: boolean): void {
    ClientController.getInstance().raiseEvent(BlastworksClientEvent.BONUS_START);
    super.onEnter(cascadeSkip);
  }

  /**
   * Send bonus complete message
   *
   * @public
   * @override
   */
  public onExit() {
    ClientController.getInstance().raiseEvent(BlastworksClientEvent.BONUS_COMPLETE);
    super.onExit();
  }
}
