import { Components } from "appworks/components/components";
import { Layers } from "appworks/graphics/layers/layers";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { deepClone } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { Timer } from "appworks/utils/timer";
import { gameLayers } from "game-layers";
import { PYLPrize } from "model/pyl-prize";
import { MatrixComponent } from "slotworks/components/matrix/matrix-component";
import { ReelSpinner, SpinStageEvent, SpinStageEvents } from "slotworks/components/matrix/reel/transition-behaviours/spin/reel-spinner";
import { SpinReelTransition } from "slotworks/components/matrix/reel/transition-behaviours/spin/spin-reel-transition";
import { SymbolComponentType, SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";
import { SpinRecord } from "slotworks/model/gameplay/records/spin-record";

export class PYLSpinAWinBonusMatrixComponent<T extends SymbolSubcomponent = SymbolSubcomponent> extends MatrixComponent<T> {
    public enabled = false;
    public spinning = false;
    
    constructor(grid: number[], stops: number[], reelset: string[][], matrixLayer?: Layers, animationLayer?: Layers, public readonly symbolType: SymbolComponentType<T> = SymbolSubcomponent as any) {
        super(grid, stops, reelset, matrixLayer, animationLayer, symbolType);

        this.matrixLayer.enableBoundsMask(false);
    }

    public startTransition(jumpStart?: boolean, quickSpin?: boolean): Contract<void> {
        this.spinning = true;

        return super.startTransition(jumpStart, quickSpin);
    }

    public stop(symbolId: string): Contract<void> {
        const reelStrip = deepClone(this.getCurrentReelset())[0].slice(12);

        const stopIndex = this.matrixLayer === gameLayers.SpinAWinMatrixContent2 ? 4 : 3;
        reelStrip[stopIndex] = symbolId;

        const reelSpinner = this.getTransition().getSpinners()[0];

        return new Sequence([
            () => reelSpinner.stop(0, reelStrip, false),
            () => Contract.wrap(() => {
                this.spinning = false;
            })
        ]);
    }

    protected update(): void {
        if (this.enabled) {
            super.update();
        }
    }

    protected postProcessReels() {
        this.clearSymbolProcessing();

        this.getAllSymbols().forEach((symbol) => {
            symbol.updateTransform();
        });
    }
}