import { Components } from "appworks/components/components";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { gameLayers } from "game-layers";
import { SlingoTicketMatrixComponent } from "slingo/components/slingo-ticket-matrix-component";
import { SlingoFullHouseState } from "slingo/states/slingo-full-house-state";

export class PYLSlingoFullHouseState extends SlingoFullHouseState {
    protected showFullHouseAnimation(): Contract {
        return new Sequence([
            () => super.showFullHouseAnimation(),
            () => gameLayers.CelebrationContent.setScene("fullhouse"),
            () => Contract.getTimeoutContract(2000),
            () => gameLayers.CelebrationContent.defaultScene()
        ]);
    }
}