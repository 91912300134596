import { ClientController, BlastworksClientEvent } from 'client-controller';
import { PYLTakeYourPickBonusState } from 'states/pyl-take-your-pick-bonus-state';

/**
 * Social bonus state to send client messages
 *
 * @class SocialTakeYourPickBonusState
 * @typedef {SocialTakeYourPickBonusState}
 * @extends {PYLTakeYourPickBonusState}
 */
export class SocialTakeYourPickBonusState extends PYLTakeYourPickBonusState {
  /**
   * Send bonus start message
   *
   * @public
   * @override
   * @param {?boolean} [cascadeSkip]
   */
  public onEnter(cascadeSkip?: boolean): void {
    ClientController.getInstance().raiseEvent(BlastworksClientEvent.BONUS_START);
    super.onEnter(cascadeSkip);
  }

  /**
   * Send bonus complete message
   *
   * @public
   * @override
   */
  public onExit() {
    ClientController.getInstance().raiseEvent(BlastworksClientEvent.BONUS_COMPLETE);
    super.onExit();
  }
}
