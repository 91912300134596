import { AbstractComponent } from "appworks/components/abstract-component";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Orientation } from "appworks/graphics/canvas/orientation";
import { ButtonElement, ButtonEvent } from "appworks/graphics/elements/button-element";
import { Layers } from "appworks/graphics/layers/layers";
import { Container } from "appworks/graphics/pixi/container";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Text } from "appworks/graphics/pixi/text";
import { gameState } from "appworks/model/game-state";
import { commsManager } from "appworks/server/comms-manager";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { fadeIn2 } from "appworks/utils/animation/fade2";
import { dualTransform } from "appworks/utils/animation/transform";
import { tween2 } from "appworks/utils/animation/tween2";
import { contains } from "appworks/utils/collection-utils";
import { wrapContract } from "appworks/utils/contracts/wrap-contract";
import { Timer } from "appworks/utils/timer";
import { Easing } from "appworks/utils/tween";
import { GMRAction } from "gaming-realms/integration/gmr-schema";
import { RenderTexture } from "pixi.js";
import { Signal } from "signals";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoStakeToSpinRequestPayload } from "slingo/model/requests/slingo-stake-to-spin-request-payload";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { SlingoStakeToSpinResult } from "slingo/model/results/slingo-stake-to-spin-result";
import { slingoModel } from "slingo/model/slingo-model";

export class GMRSuperSpinWheelComponent extends AbstractComponent {
    public onSpin: Signal = new Signal();
    public onSpun: Signal = new Signal();
    public onError: Signal = new Signal();

    protected readonly WHEEL_RADIUS: number = 178;
    protected readonly WHEEL_COLOR_WIN: number = 0x00ab00;
    protected readonly WHEEL_COLOR_LOSE: number = 0xff0000;
    protected readonly INTRO_SLIDE_DURATION: number = 1000;
    protected readonly OUTRO_SLIDE_DURATION: number = 1000;
    protected readonly REPOSITION_DURATION: number = 1000;
    protected readonly INSTRUCTIONS_FADE_DURATION: number = 300; // TODO: use this
    protected readonly BUTTON_TEXT_FADE_DURATION: number = 150;
    protected readonly SPIN_DURATION: number = 2000;
    protected readonly SPIN_ROTATIONS: number = 5;
    protected readonly COUNTER_MASK_MAX: number = 10;
    protected readonly COUNTER_MASK_MIN: number = -102;

    protected layer: Layers;
    protected spines: {
        landscape: SpineContainer;
        portrait: SpineContainer;
    };
    protected visibleWheelPos: DualPosition;
    protected hiddenWheelPos: DualPosition;
    protected enabled: boolean = false;
    protected spinBtn: ButtonElement;
    protected helpBtn: ButtonElement;
    protected counter: Text;
    protected active: boolean = false;
    protected tooltipOpen: boolean = false;
    protected winRatio: number = 0;
    protected counterEnabled: boolean = true;
    protected maximumStakeToSpinAttempts: number = -1;
    protected currentSpinsRemaining: number = 0;
    protected wheelGroup: Container;
    protected tooltipTextures: {
        landscape: {
            wheel_info: PIXI.Texture;
            wheel_win: PIXI.Texture;
            wheel_lose: PIXI.Texture;
            counter_info: PIXI.Texture;
            counter_win: PIXI.Texture;
            counter_lose: PIXI.Texture;
        };
        portrait: {
            wheel_info: PIXI.Texture;
            wheel_win: PIXI.Texture;
            wheel_lose: PIXI.Texture;
            counter_info: PIXI.Texture;
            counter_win: PIXI.Texture;
            counter_lose: PIXI.Texture;
        };
    };
    protected callToActionBacking: Sprite;
    protected callToActionText: Text;

    public init(): void {
        super.init();

        this.layer = Layers.get("SuperSpinWheel");
        this.spines = {
            landscape: this.layer.getSpine("wheel_landscape"),
            portrait: this.layer.getSpine("wheel_portrait"),
        };
        this.spinBtn = this.layer.getButton("spin");
        this.helpBtn = this.layer.getButton("help");
        this.counter = this.layer.getText("counter");
        this.callToActionText = this.layer.getText("cta");
        this.callToActionBacking = this.layer.getSprite("cta_bg");

        this.callToActionText.alpha = 0;
        this.callToActionBacking.alpha = 0;

        this.spinBtn.interactive = false;
        this.helpBtn.interactive = false;

        this.wheelGroup = new Container();
        this.wheelGroup.addChild(this.spines.landscape);
        this.wheelGroup.addChild(this.spines.portrait);
        this.wheelGroup.addChild(this.spinBtn);
        this.wheelGroup.addChild(this.helpBtn);
        this.wheelGroup.addChild(this.counter);
        this.layer.add(this.wheelGroup);

        if (!slingoModel.read().gameConfig.stakeToSpinEnabled) {
            this.forEachOrientation(async spine => {
                spine.destroy();
                spine = undefined;
            });
            this.spinBtn.destroy();
            this.helpBtn.destroy();
            this.counter.destroy();
            this.callToActionBacking.destroy();
            this.callToActionText.destroy();
            return;
        }

        this.maximumStakeToSpinAttempts = slingoModel.read().gameConfig.maximumStakeToSpinAttempts;
        this.counterEnabled = this.maximumStakeToSpinAttempts > 0;

        if (!this.counterEnabled) {
            this.counter.visible = false;
        }

        // I hate this but certain spine operations such as getting attachments don't work unless the spine is in the displayList,
        // which it isn't if visible is false or alpha is 0
        const onOrientationChangeHandler = (orientation: Orientation) => {
            this.drawTooltipText();
            this.drawWheelBackground();
            this.updateSpinCounter();

            this.spines.landscape.visible = orientation === Orientation.LANDSCAPE;
            this.spines.portrait.visible = orientation === Orientation.PORTRAIT;
        };
        Services.get(CanvasService).onOrientationChange.add((orientation: Orientation) => onOrientationChangeHandler(orientation));
        onOrientationChangeHandler(Services.get(CanvasService).orientation);

        // push off screen so we can ease it back in
        this.visibleWheelPos = this.wheelGroup.dualPosition.clone();
        this.hiddenWheelPos = this.wheelGroup.dualPosition.clone();
        const landscapeSpineTransform = this.spines.landscape.getSpinePosition().landscape.clone();
        const portraitSpineTransform = this.spines.portrait.getSpinePosition().portrait.clone();
        this.hiddenWheelPos.landscape.x += landscapeSpineTransform.width;
        this.hiddenWheelPos.portrait.y += portraitSpineTransform.height;

        // set pivots so we can animate its expansion when spinning
        const dualPivot = new DualPosition();
        dualPivot.landscape.x += landscapeSpineTransform.width;
        dualPivot.landscape.y += landscapeSpineTransform.height * 0.5;
        dualPivot.portrait.x += portraitSpineTransform.width * 0.5;
        dualPivot.portrait.y += portraitSpineTransform.height;
        this.wheelGroup.dualPivot = dualPivot;

        // account for the pivot change
        this.hiddenWheelPos.landscape.x += landscapeSpineTransform.width;
        this.hiddenWheelPos.landscape.y += landscapeSpineTransform.height * 0.5;
        this.visibleWheelPos.landscape.x += landscapeSpineTransform.width;
        this.visibleWheelPos.landscape.y += landscapeSpineTransform.height * 0.5;
        this.hiddenWheelPos.portrait.x += portraitSpineTransform.width * 0.5;
        this.hiddenWheelPos.portrait.y += portraitSpineTransform.height;
        this.visibleWheelPos.portrait.x += portraitSpineTransform.width * 0.5;
        this.visibleWheelPos.portrait.y += portraitSpineTransform.height;

        this.wheelGroup.setDualTransform(this.hiddenWheelPos);

        this.forEachOrientation(async spine => {
            spine.playOnce(ANIMS.STATIC_WHEEL, false, 1, 0).execute();
        });

        this.setupButtonEvents();

        this.createTooltipTextures();
    }

    public async show(): Promise<void> {
        if (this.active) {
            return;
        }
        this.active = true;

        Services.get(SoundService).customEvent("wheel_entry");

        const record = gameState.getCurrentGame().getCurrentRecord() as SlingoRecord;
        const gameProgressResult = record.getFirstResultOfType(SlingoGameProgressResult);

        this.winRatio = gameProgressResult.nextStakeToSpinChance || 0;
        this.currentSpinsRemaining = this.maximumStakeToSpinAttempts;

        this.drawWheelBackground();
        this.updateSpinCounter();

        this.spinBtn.alpha = 0;
        this.activateButton();

        await Promise.all([
            tween2({ target: this.callToActionBacking, to: { alpha: 1 }, duration: 100 }),
            tween2({ target: this.callToActionText, to: { alpha: 1 }, duration: 100 }),
            wrapContract(dualTransform(this.wheelGroup, this.visibleWheelPos, this.INTRO_SLIDE_DURATION, Easing.Quartic.Out)),
        ]);
    }

    public async hide(): Promise<void> {
        if (!this.active) {
            return;
        }
        this.active = false;

        this.spinBtn.interactive = false;
        this.helpBtn.interactive = false;

        Services.get(SoundService).customEvent("wheel_exit");

        await Promise.all([
            tween2({ target: this.callToActionBacking, to: { alpha: 0 }, duration: 100 }),
            tween2({ target: this.callToActionText, to: { alpha: 0 }, duration: 100 }),
            await wrapContract(dualTransform(this.wheelGroup, this.hiddenWheelPos, this.OUTRO_SLIDE_DURATION, Easing.Quartic.Out)),
        ]);
    }

    public async shrink() {
        await this.forEachOrientation(async (spine, orientation) => {
            await Promise.all([
                tween2({
                    target: this.wheelGroup.landscape.scale,
                    to: { x: 1 + Number.EPSILON, y: 1 + Number.EPSILON },
                    duration: this.REPOSITION_DURATION,
                    easing: Easing.Quartic.Out,
                }),
                tween2({
                    target: this.wheelGroup.portrait.scale,
                    to: { x: 1 + Number.EPSILON, y: 1 + Number.EPSILON },
                    duration: this.REPOSITION_DURATION,
                    easing: Easing.Quartic.Out,
                }),
            ]);
        });
    }

    public async activateButton(): Promise<void> {
        const record = gameState.getCurrentGame().getCurrentRecord() as SlingoRecord;
        const result = record.getFirstResultOfType(SlingoGameProgressResult);

        await this.forEachOrientation(async (spine, orientation) => {
            await wrapContract(
                spine.playOnce((this.counterEnabled ? ANIMS.BUTTON_EXPAND_WITH_COUNTER : ANIMS.BUTTON_EXPAND_WITHOUT_COUNTER)[orientation], false, 1, 0)
            );
        });

        this.updateSpinCounter();

        const spinCostCurrency = Services.get(CurrencyService).format(result.nextStakeToSpin, false);
        const text = Services.get(TranslationsService)
            .get("slingo_buy_spin", { value: spinCostCurrency })
            .toLocaleUpperCase();

        this.spinBtn.setLabelText(text);

        await wrapContract(fadeIn2(this.spinBtn, this.BUTTON_TEXT_FADE_DURATION));

        if (this.counterEnabled) {
            await wrapContract(fadeIn2(this.counter, this.BUTTON_TEXT_FADE_DURATION));
        }

        this.spinBtn.interactive = true;
        this.helpBtn.interactive = true;
    }

    protected async spin() {
        this.onSpin.dispatch();

        this.spinBtn.interactive = false;
        this.helpBtn.interactive = false;
        this.spinBtn.alpha = 0;
        this.counter.alpha = 0;

        this.closeToolTip();

        Services.get(SoundService).customEvent("wheel_button_press");

        tween2({ target: this.callToActionBacking, to: { alpha: 0 }, duration: 100 });
        tween2({ target: this.callToActionText, to: { alpha: 0 }, duration: 100 });

        await wrapContract(commsManager.request(new SlingoStakeToSpinRequestPayload()));

        const gameplay = gameState.getCurrentGame();
        if (gameplay.getLatestRecord() === gameplay.getCurrentRecord()) {
            // No record was built which means something went wrong (likely insufficient funds) so reset, error, and don't spin
            this.callToActionBacking.alpha = 1;
            this.callToActionText.alpha = 1;
            this.spinBtn.interactive = true;
            this.helpBtn.interactive = true;
            this.spinBtn.alpha = 1;
            this.counter.alpha = 1;

            this.onError.dispatch();
            return Promise.resolve();
        }

        gameplay.setToLatestRecord();

        Services.get(TransactionService).setBalance(gameplay.balance);

        const record = gameplay.getCurrentRecord() as SlingoRecord;
        const stakeToSpinResult = record.getFirstResultOfType(SlingoStakeToSpinResult);

        await this.forEachOrientation(async (spine, orientation) => {
            spine.spines[orientation].state.clearTrack(1);

            const rotations = this.SPIN_ROTATIONS;
            const wedgeSize: number = 360 * this.winRatio;
            const minAngle: number = -(wedgeSize / 2);
            const offset: number = minAngle + (stakeToSpinResult.rng.result / stakeToSpinResult.rng.bound) * 360;
            const finalAngle: number = -(rotations * 360 + offset);

            const arrow = spine.spines[orientation].skeleton.findBone("Arrow");
            const rotationTween = tween2({
                target: (arrow as unknown) as { rotation: number },
                to: { rotation: finalAngle },
                duration: this.SPIN_DURATION,
                easing: Easing.Quartic.InOut,
            });

            await Promise.all([
                wrapContract(
                    spine.playOnce((this.counterEnabled ? ANIMS.BUTTON_SHRINK_WITH_COUNTER : ANIMS.BUTTON_SHRINK_WITHOUT_COUNTER)[orientation], false, 1, 0)
                ),
                tween2({
                    target: this.wheelGroup.landscape.scale,
                    to: { x: 2, y: 2 },
                    easing: Easing.Quartic.Out,
                    duration: this.REPOSITION_DURATION,
                    onStart: () => {
                        Services.get(SoundService).customEvent("wheel_spin");
                    },
                }),
                tween2({
                    target: this.wheelGroup.portrait.scale,
                    to: { x: 2, y: 2 },
                    easing: Easing.Quartic.Out,
                    duration: this.REPOSITION_DURATION,
                }),
                rotationTween,
            ]);
        });

        if (stakeToSpinResult.rng.isWin) {
            Services.get(SoundService).customEvent("wheel_win");
        }

        if (stakeToSpinResult.rng.isWin || !contains(record.actions, GMRAction.STAKE_TO_SPIN)) {
            await Promise.all([this.hide(), this.shrink()]);
        } else {
            Services.get(SoundService).customEvent("wheel_lose");

            this.currentSpinsRemaining--;

            await this.shrink();
            await this.activateButton();
        }

        const gameProgressResult = record.getFirstResultOfType(SlingoGameProgressResult);
        this.winRatio = gameProgressResult.nextStakeToSpinChance || 0;

        this.updateSpinCounter();

        this.onSpun.dispatch(stakeToSpinResult.rng.isWin);
    }

    protected async openToolTip() {
        if (this.tooltipOpen) {
            return;
        }

        this.tooltipOpen = true;

        Services.get(SoundService).customEvent("wheel_button_press");

        this.forEachOrientation(async (spine, orientation) => {
            spine.spines[orientation].state.clearTrack(1);
            wrapContract(spine.playOnce(ANIMS.XBUTTON_UP[orientation], false, 1, 1));
            wrapContract(
                spine.playOnce(
                    (this.counterEnabled ? ANIMS.TOOL_TIP_PANEL_INTRO_WITH_COUNTER : ANIMS.TOOL_TIP_PANEL_INTRO_WITHOUT_COUNTER)[orientation],
                    false,
                    1,
                    2
                )
            );
        });

        this.drawTooltipText();
        this.updateSpinCounter();
    }

    protected async closeToolTip() {
        if (!this.tooltipOpen) {
            return;
        }

        this.tooltipOpen = false;

        Services.get(SoundService).customEvent("wheel_button_press");

        this.forEachOrientation(async (spine, orientation) => {
            spine.spines[orientation].state.clearTrack(1);
            wrapContract(spine.playOnce(ANIMS.QBUTTON_UP[orientation], false, 1, 1));
            wrapContract(
                spine.playOnce(
                    (this.counterEnabled ? ANIMS.TOOL_TIP_PANEL_OUTRO_WITH_COUNTER : ANIMS.TOOL_TIP_PANEL_OUTRO_WITHOUT_COUNTER)[orientation],
                    false,
                    1,
                    2
                )
            );
        });

        this.updateSpinCounter();
    }

    protected drawWheelBackground() {
        try {
            const circle = new PIXI.Graphics();
            circle.beginFill(this.WHEEL_COLOR_LOSE);
            circle.drawCircle(0, 0, this.WHEEL_RADIUS);
            circle.endFill();

            const segment = new PIXI.Graphics();
            segment.beginFill(this.WHEEL_COLOR_WIN);
            segment.moveTo(0, 0);
            segment.arc(0, 0, this.WHEEL_RADIUS, 0, Math.PI * 2 * this.winRatio);
            segment.lineTo(0, 0);
            segment.endFill();
            circle.addChild(segment);

            circle.angle = 270 - 180 * this.winRatio;

            const texture = Services.get(CanvasService).renderer.generateTexture(circle, PIXI.SCALE_MODES.LINEAR, 1.0);

            let success = true;

            const orientation = Services.get(CanvasService).orientation;

            this.spines[orientation].spines[orientation].hackTextureBySlotName(SLOTS.WHEEL_BACKGROUND, texture);

            circle.destroy({ children: true, texture: true, baseTexture: true });

            if (!success) {
                throw new Error("Failed to draw wheel background");
            }
        } catch (err) {
            // hackTextureBySlotName only works if the texture is active in the spine AND is visible on-screen, e.g. alpha has to be at least 0.000001, not 0
            Timer.setTimeout(() => this.drawWheelBackground(), 16);
        }
    }

    protected drawTooltipText() {
        const orientation = Services.get(CanvasService).orientation;

        try {
            if (orientation === Orientation.LANDSCAPE) {
                this.spines.landscape.spines.landscape.hackTextureBySlotName("ToolTipTextField", this.tooltipTextures.landscape.wheel_info);
                this.spines.landscape.spines.landscape.hackTextureBySlotName("ToolTipTextField3", this.tooltipTextures.landscape.wheel_win);
                this.spines.landscape.spines.landscape.hackTextureBySlotName("ToolTipTextField4", this.tooltipTextures.landscape.wheel_lose);
                if (this.counterEnabled) {
                    this.spines.landscape.spines.landscape.hackTextureBySlotName("ToolTipTextField2", this.tooltipTextures.landscape.counter_info);
                    this.spines.landscape.spines.landscape.hackTextureBySlotName("ToolTipTextField5", this.tooltipTextures.landscape.counter_win);
                    this.spines.landscape.spines.landscape.hackTextureBySlotName("ToolTipTextField6", this.tooltipTextures.landscape.counter_lose);
                }
            } else {
                this.spines.portrait.spines.portrait.hackTextureBySlotName("toolTipTextFieldPortrait01", this.tooltipTextures.portrait.wheel_info);
                this.spines.portrait.spines.portrait.hackTextureBySlotName("toolTipTextFieldPortrait05", this.tooltipTextures.portrait.wheel_win);
                this.spines.portrait.spines.portrait.hackTextureBySlotName("toolTipTextFieldPortrait06", this.tooltipTextures.portrait.wheel_lose);
                if (this.counterEnabled) {
                    this.spines.portrait.spines.portrait.hackTextureBySlotName("toolTipTextFieldPortrait02", this.tooltipTextures.portrait.counter_info);
                    this.spines.portrait.spines.portrait.hackTextureBySlotName("toolTipTextFieldPortrait03", this.tooltipTextures.portrait.counter_win);
                    this.spines.portrait.spines.portrait.hackTextureBySlotName("toolTipTextFieldPortrait04", this.tooltipTextures.portrait.counter_lose);
                }
            }
        } catch (err) {
            // hackTextureBySlotName only works if the texture is active in the spine AND is visible on-screen, e.g. alpha has to be at least 0.000001, not 0
            Timer.setTimeout(() => this.drawTooltipText(), 16);
        }
    }

    protected updateSpinCounter() {
        const count1OffSet: number = (this.COUNTER_MASK_MAX - this.COUNTER_MASK_MIN) * (1 / this.maximumStakeToSpinAttempts);
        this.counter.text = this.currentSpinsRemaining.toString();

        this.forEachOrientation(async (spine, orientation) => {
            const maskBone = (spine.spines[orientation].skeleton.findBone(BONES.COUNTER_MASK) as unknown) as { y: number };
            maskBone.y = this.COUNTER_MASK_MIN + count1OffSet * Math.max(0, this.currentSpinsRemaining - 1);
        });
    }

    protected setupButtonEvents() {
        this.spinBtn.on(ButtonEvent.POINTER_OVER, () => {
            this.forEachOrientation(async spine => {
                spine.playOnce(ANIMS.BUTTON_OVER, false, 1, 1).execute();
                this.updateSpinCounter();
            });
        });
        this.spinBtn.on(ButtonEvent.POINTER_OUT, () => {
            this.forEachOrientation(async spine => {
                spine.playOnce(ANIMS.BUTTON_UP, false, 1, 1).execute();
                this.updateSpinCounter();
            });
        });
        this.spinBtn.on(ButtonEvent.POINTER_DOWN, () => {
            this.forEachOrientation(async spine => {
                spine.playOnce(ANIMS.BUTTON_DOWN, false, 1, 1).execute();
                this.updateSpinCounter();
            });
            this.spin();
        });
        this.spinBtn.on(ButtonEvent.POINTER_UP, () => {
            this.forEachOrientation(async spine => {
                spine.playOnce(ANIMS.BUTTON_UP, false, 1, 1).execute();
                this.updateSpinCounter();
            });
        });

        this.helpBtn.on(ButtonEvent.POINTER_OVER, () => {
            this.forEachOrientation(async (spine, orientation) => {
                const anim = this.tooltipOpen ? ANIMS.XBUTTON_OVER[orientation] : ANIMS.QBUTTON_OVER[orientation];
                spine.playOnce(anim, false, 1, 1).execute();
                this.updateSpinCounter();
            });
        });
        this.helpBtn.on(ButtonEvent.POINTER_OUT, () => {
            this.forEachOrientation(async (spine, orientation) => {
                const anim = this.tooltipOpen ? ANIMS.XBUTTON_UP[orientation] : ANIMS.QBUTTON_UP[orientation];
                spine.playOnce(anim, false, 1, 1).execute();
                this.updateSpinCounter();
            });
        });
        this.helpBtn.on(ButtonEvent.POINTER_DOWN, () => {
            this.forEachOrientation(async (spine, orientation) => {
                const anim = this.tooltipOpen ? ANIMS.XBUTTON_DOWN[orientation] : ANIMS.QBUTTON_DOWN[orientation];
                spine.playOnce(anim, false, 1, 1).execute();
                this.updateSpinCounter();
            });
        });
        this.helpBtn.on(ButtonEvent.POINTER_UP, () => {
            this.forEachOrientation(async (spine, orientation) => {
                const anim = this.tooltipOpen ? ANIMS.XBUTTON_UP[orientation] : ANIMS.QBUTTON_UP[orientation];
                spine.playOnce(anim, false, 1, 1).execute();
                this.updateSpinCounter();
            });

            if (!this.tooltipOpen) {
                this.openToolTip();
            } else {
                this.closeToolTip();
            }
        });
    }

    protected createTooltipTextures() {
        this.tooltipTextures = {
            landscape: {
                wheel_info: this.createTooltipTexture("wheel_info", 483, 178),
                wheel_win: this.createTooltipTexture("wheel_win", 800, 160),
                wheel_lose: this.createTooltipTexture("wheel_lose", 800, 160),
                counter_info: this.createTooltipTexture("counter_info", 483, 222),
                counter_win: this.createTooltipTexture("counter_win", 800, 160),
                counter_lose: this.createTooltipTexture("counter_lose", 800, 160),
            },
            portrait: {
                wheel_info: this.createTooltipTexture("wheel_info", 1760, 274),
                wheel_win: this.createTooltipTexture("wheel_win", 800, 160),
                wheel_lose: this.createTooltipTexture("wheel_lose", 800, 160),
                counter_info: this.createTooltipTexture("counter_info", 1760, 274),
                counter_win: this.createTooltipTexture("counter_win", 800, 160),
                counter_lose: this.createTooltipTexture("counter_lose", 800, 160),
            },
        };
    }

    protected createTooltipTexture(i18nKey: string, width: number, height: number): RenderTexture {
        const text = new Text(
            Services.get(TranslationsService)
                .get(i18nKey)
                .toLocaleUpperCase(),
            {
                fontFamily: "leaguespartan-bold",
                fill: 0xffffff,
                align: "center",
                wordWrap: true,
                wordWrapWidth: width,
            }
        );
        text.landscape.fontSize = 150;
        text.portrait.fontSize = 150;
        text.landscape.width = width;
        text.portrait.width = width;
        text.landscape.height = height;
        text.portrait.height = height;

        const texture = Services.get(CanvasService).renderer.generateTexture(text, PIXI.SCALE_MODES.LINEAR, 1.0, new PIXI.Rectangle(0, 0, width, height));

        text.destroy();

        return texture;
    }

    protected async forEachOrientation(callback: (spine: SpineContainer, orientation: "landscape" | "portrait") => Promise<void>) {
        await Promise.all([callback(this.spines.landscape, "landscape"), callback(this.spines.portrait, "portrait")]);
    }
}

const ANIMS = {
    STATIC_WHEEL: "WheelSlideInLandscape",
    BUTTON_EXPAND_WITHOUT_COUNTER: { landscape: "ButtonExpandLandscapeWithoutCounter", portrait: "ButtonExpandPortraitWithoutCounter" },
    BUTTON_EXPAND_WITH_COUNTER: { landscape: "ButtonExpandLandscapeWithCounter", portrait: "ButtonExpandPortraitWithCounter" },
    BUTTON_SHRINK_WITHOUT_COUNTER: { landscape: "ButtonShrinkLandscapeWithoutCounter", portrait: "ButtonShrinkPortraitWithoutCounter" },
    BUTTON_SHRINK_WITH_COUNTER: { landscape: "ButtonShrinkLandscapeWithCounter", portrait: "ButtonShrinkPortraitWithCounter" },
    BUTTON_DISABLED: "ButtonDisabledLandscape",
    BUTTON_DOWN: "ButtonDownLandscape",
    BUTTON_OVER: "ButtonOverLandscape",
    BUTTON_UP: "ButtonUpLandscape",
    COUNTER_LANDSCAPE: "counterLandscape",
    COUNTER_PORTRAIT: "counterPortrait",
    SOMETHING: "DGHWheelRimAndArrowPointer",
    GRID_OVERLAY: "GridOverlay",
    QBUTTON_CALL_TO_ACTION: { landscape: "ButtonQMarkCallToActionLandscape", portrait: "ButtonQMarkCallToActionPortrait" },
    QBUTTON_DISABLED: { landscape: "ButtonQMarkDisabledLandscape", portrait: "ButtonQMarkDisabledPortrait" },
    QBUTTON_DOWN: { landscape: "ButtonQMarkDownLandscape", portrait: "ButtonQMarkDownPortrait" },
    QBUTTON_OVER: { landscape: "ButtonQMarkOverLandscape", portrait: "ButtonQMarkOverPortrait" },
    QBUTTON_UP: { landscape: "ButtonQMarkUpLandscape", portrait: "ButtonQMarkUpPortrait" },
    XBUTTON_DISABLED: { landscape: "ButtonXMarkDisabledLandscape", portrait: "ButtonXMarkDisabledPortrait" },
    XBUTTON_DOWN: { landscape: "ButtonXMarkDownLandscape", portrait: "ButtonXMarkDownPortrait" },
    XBUTTON_OVER: { landscape: "ButtonXMarkOverLandscape", portrait: "ButtonXMarkOverPortrait" },
    XBUTTON_UP: { landscape: "ButtonXMarkUpLandscape", portrait: "ButtonXMarkUpPortrait" },
    TOOL_TIP_PANEL_INTRO_WITH_COUNTER: { landscape: "ToolTipPanelIntroLandscapeWithCounter", portrait: "ToolTipPanelIntroPortraitWithCounter" },
    TOOL_TIP_PANEL_OUTRO_WITH_COUNTER: { landscape: "ToolTipPanelOutroLandscapeWithCounter", portrait: "ToolTipPanelOutroPortraitWithCounter" },
    TOOL_TIP_PANEL_STATIC_WITH_COUNTER: { landscape: "ToolTipPanelStaticLandscapeWithCounter", portrait: "ToolTipPanelStaticPortraitWithCounter" },
    TOOL_TIP_PANEL_INTRO_WITHOUT_COUNTER: { landscape: "ToolTipPanelIntroLandscapeWithoutCounter", portrait: "ToolTipPanelIntroPortraitWithoutCounter" },
    TOOL_TIP_PANEL_OUTRO_WITHOUT_COUNTER: { landscape: "ToolTipPanelOutroLandscapeWithoutCounter", portrait: "ToolTipPanelOutroPortraitWithoutCounter" },
    TOOL_TIP_PANEL_STATIC_WITHOUT_COUNTER: { landscape: "ToolTipPanelStaticLandscapeWithoutCounter", portrait: "ToolTipPanelStaticPortraitWithoutCounter" },
} as const;

const SLOTS = {
    WHEEL_BACKGROUND: "Pie",
} as const;

const BONES = {
    WHEEL_POINTER: "Arrow",
    COUNTER_MASK: "CounterMask",
} as const;
