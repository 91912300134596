import { gameState } from "appworks/model/game-state";
import { Exit } from "appworks/state-machine/data/exit";
import { Decision } from "appworks/state-machine/decisions/decision";

export class IsBonusTriggeredDecision extends Decision {

    protected bonusId: string;

    constructor(bonus: string) {
        super();

        this.bonusId = bonus;
    }

    public evaluate() {
        const record = gameState.getCurrentGame().getCurrentRecord();

        let triggered = false;

        if (record) {
            if (record.isBonusTriggered(this.bonusId)) {
                triggered = true;
            }
        }

        return triggered ? Exit.True : Exit.False;
    }
}
