import { Services } from "appworks/services/services";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { PYLPrize } from "model/pyl-prize";
import { BonusResult } from "slotworks/model/gameplay/records/results/bonus-result";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";

export class PYLBonusResult extends BonusResult {
    public readonly id: "take_your_pick" | "lucky_press" | "press_your_luck" | "spin_a_win";
    public prizes: PYLPrize[];
    public variant?: string;
    public riskValue?: number;

    constructor(id: PYLBonusResult["id"], prizes: PYLPrize[], variant: string) {
        super(id);

        this.resultType = "PYLBonusResult";
        this.prizes = prizes;
        this.variant = variant;
    }
}