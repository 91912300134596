import { Components } from "appworks/components/components";
import { PromptComponent } from "appworks/components/prompt/prompt-component";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { builderManager } from "appworks/server/builders/builder-manager";
import { Services } from "appworks/services/services";
import { SlingoSetup } from "slingo/setup/slingo-setup";
import { BetBarComponent } from "slotworks/components/betbar/bet-bar-component";
import { CountMode } from "slotworks/components/bigwin/big-win-component";
import { CelebrateWinComponent } from "slotworks/components/celebrate-win/celebrate-win-component";
import { BigWinFactory } from "slotworks/components/factories/big-win-factory";
import { GenericComponentFactory } from "slotworks/components/factories/generic-component-factory";
import { EJGameDefinitionSetup } from "./pyl-game-definition-setup";
import { PYLLayerSetup } from "./pyl-layer-setup";
import { PYLSoundSetup } from "./pyl-sound-setup";
import { PYLStateSetup } from "./pyl-state-setup";
import { PYLDebugSetup } from "setup/pyl-debug-setup";
import { PYLComponentSetup } from "setup/pyl-component-setup";
import { PYLCommsSetup } from "setup/pyl-comms-setup";
import { Layers } from "appworks/graphics/layers/layers";
import { FadeTransition } from "appworks/transitions/fade-transition";
import { UI } from "appworks/ui/ui";
import { UIFlagState } from "appworks/ui/flags/ui-flag-state";
import { UIFlag } from "appworks/ui/flags/ui-flags";
import { PYLSetupTransitions } from "setup/pyl-setup-transitions";
import { Text } from "appworks/graphics/pixi/text";
import { TextPopulator } from "appworks/graphics/layers/populators/populate-text";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { ParticleConfig } from "appworks/config/asset-config-schema";
import { PYLSetupParticles } from "setup/pyl-setup-particles";
import { PYLI18NSetup } from "setup/pyl-i18n-setup";
import { gameLoop } from "appworks/core/game-loop";
import { SlingoGameInstanceIdControl } from "slingo/ui/controls/slingo-game-instance-id-control";
import { commsManager } from "appworks/server/comms-manager";
import { lastInArray } from "appworks/utils/collection-utils";

export class PYLSetup extends SlingoSetup {
    public setupDebug(): void {
        super.setupDebug();
        PYLDebugSetup();
    }

    public setupCanvas(): void {
        super.setupCanvas();
        Services.get(CanvasService).cssCanvasEnabled = true;
    }

    public setupI18N(): void {
        PYLI18NSetup();
        super.setupI18N();
    }

    public setupGameDefinition(): void {
        super.setupGameDefinition();
        EJGameDefinitionSetup();
    }

    public setupComponents() {
        super.setupComponents();
        PYLComponentSetup();
    }

    public setupStates() {
        super.setupStates();
        PYLStateSetup();
    }

    public setupLayers(): void {
        super.setupLayers();
        PYLLayerSetup();
    }

    public setupComms(): void {
        super.setupComms();
        PYLCommsSetup();
    }

    public setupTransitions(): void {
        super.setupTransitions();
        PYLSetupTransitions();
    }

    public setupSound(): void {
        super.setupSound();
        PYLSoundSetup();
    }

    public setupParticles(): { [id: string]: ParticleConfig; } {
        return PYLSetupParticles();
    }

    public setupUI(): void {
        super.setupUI();
        SlingoGameInstanceIdControl.showGameId = true;
    }

    public onReady(): void {
        gameLoop.setPaused("GMRwrapper", false); // TODO: this shouldn't be necessary
        gameLoop.setPaused("realitycheck", false); // TODO: this shouldn't be necessary
    }
}
