import { Contract } from "appworks/utils/contracts/contract";
import { Timer } from "appworks/utils/timer";
import { AbstractSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/abstract-symbol-behaviour";
import { SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";

export class BlinkSymbolBehaviour extends AbstractSymbolBehaviour {

    public behaviourName: string = "Blink";

    protected blinkCount: number;
    protected blinkDuration: number;

    protected timerId: number;

    protected show: boolean;

    constructor(symbol: SymbolSubcomponent, blinkDuration: number = 200, blinkCount: number = 2) {
        super(symbol);

        this.blinkDuration = blinkDuration;
        this.blinkCount = blinkCount;
    }

    public static() {
        super.static();

        this.symbol.setLayer(this.matrixLayer);

        Timer.clearInterval(this.timerId);

        this.symbol.setVisible(true);
        this.show = true;
    }

    /**
     * Blinks the symbol 4 times with 500ms between (by default)
     */
    public win(): Contract<void> {
        return new Contract<void>((resolve) => {

            this.symbol.setLayer(this.animationLayer);

            let blinks = this.blinkCount;

            let resolved = false;

            this.blink(this.show);

            this.timerId = Timer.setInterval(() => {
                this.show = !this.show;
                this.blink(this.show);

                if (this.show) {
                    blinks--;
                }

                if (blinks <= 0) {
                    Timer.clearInterval(this.timerId);

                    if (!resolved) {
                        resolve(null);
                        resolved = true;
                    }
                }
            }, this.blinkDuration);
        });
    }

    protected blink(on: boolean) {
        this.symbol.setVisible(on);
    }
}
