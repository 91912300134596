import { Components } from "appworks/components/components";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { State } from "appworks/state-machine/states/state";
import { Dimmable, brightness } from "appworks/utils/animation/brightness";
import { fadeIn2, fadeOut2 } from "appworks/utils/animation/fade2";
import { pulse } from "appworks/utils/animation/scale";
import { flatten } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { RandomRangeInt } from "appworks/utils/math/random";
import { Timer } from "appworks/utils/timer";
import { Easing } from "appworks/utils/tween";
import { PYLBonusWinCounterComponent } from "components/pyl-bonus-win-counter-component";
import { PYLMatrixComponent } from "components/pyl-matrix-component";
import { PYLSpinAWinBonusComponent } from "components/pyl-spin-a-win-bonus-component";
import { PYLSpinAWinBonusMatrixComponent } from "components/pyl-spin-a-win-bonus-matrix-component";
import { PYLSpinAWinSymbolSubcomponent } from "components/pyl-spin-a-win-symbol-subcomponent";
import { PYLWhammyAnimationComponent } from "components/pyl-whammy-animation-component";
import { gameLayers } from "game-layers";
import { result } from "lodash";
import { PYLPrize } from "model/pyl-prize";
import { PYLBonusResult } from "model/results/pyl-bonus-result.ts";
import { DisplayObject } from "pixi.js";
import { PYLAbstractBonusState } from "states/pyl-abstract-bonus-state";

export class PYLSpinAWinBonusState extends PYLAbstractBonusState {
    protected flashButtonInterval: number = 0;

    public onEnter(cascadeSkip?: boolean): void {
        super.onEnter();

        Components.get(PYLSpinAWinBonusComponent).reelBrightness(0, 1, 0, true).execute();
        Components.get(PYLSpinAWinBonusComponent).reelBrightness(1, 1, 0, true).execute();
        Components.get(PYLSpinAWinBonusComponent).reelBrightness(2, 1, 0, true).execute();

        gameLayers.SpinAWinMatrixForeground.scene.default.contents.fade_1.alpha = 1;
        gameLayers.SpinAWinMatrixForeground.scene.default.contents.fade_2.alpha = 1;
        gameLayers.SpinAWinMatrixForeground.scene.default.contents.fade_3.alpha = 0;

        Components.get(PYLSpinAWinBonusComponent).particles[0].emitter.emit = false;
        Components.get(PYLSpinAWinBonusComponent).particles[1].emitter.emit = false;
    }

    public complete(): void {
        Components.get(PYLSpinAWinBonusComponent).setEnabled(false);

        const spinAWinMatrixLayers = [
            gameLayers.SpinAWinAnimations.container,
            gameLayers.SpinAWinMatrixBackground.container,
            gameLayers.SpinAWinMatrixForeground.container,
            gameLayers.SpinAWinMatrixContent0.container,
            gameLayers.SpinAWinMatrixContent1.container,
            gameLayers.SpinAWinMatrixContent2.container,
            gameLayers.SpinAWinMatrixBackground.container
        ];

        fadeOut2(spinAWinMatrixLayers).execute();

        Components.get(PYLSpinAWinBonusComponent).particles[0].emitter.emit = false;
        Components.get(PYLSpinAWinBonusComponent).particles[1].emitter.emit = false;

        super.complete();
    }

    protected onBonusSceneSet(variant?: string): void {
        const spinAWinMatrixLayers = [
            gameLayers.SpinAWinAnimations.container,
            gameLayers.SpinAWinMatrixBackground.container,
            gameLayers.SpinAWinMatrixForeground.container,
            gameLayers.SpinAWinMatrixContent0.container,
            gameLayers.SpinAWinMatrixContent1.container,
            gameLayers.SpinAWinMatrixContent2.container,
            gameLayers.SpinAWinMatrixBackground.container
        ];

        spinAWinMatrixLayers.forEach((layer) => {
            layer.alpha = 0;
            layer.visible = true;
        });

        const record = gameState.getCurrentGame().getCurrentRecord();
        const results = record.getResultsOfType(PYLBonusResult);
        const prizes = flatten(results.map(result => result.prizes));

        Components.get(PYLSpinAWinBonusComponent).setEnabled(true);
        Components.get(PYLSpinAWinBonusComponent).setReelsets(variant);

        Components.get(PYLSpinAWinBonusComponent).matrixComponents[0].startTransition().execute();;
        Components.get(PYLSpinAWinBonusComponent).matrixComponents[1].startTransition().execute();;
        Components.get(PYLSpinAWinBonusComponent).reelBrightness(2, 0.2, 400, true).execute();

        fadeIn2(spinAWinMatrixLayers).execute();
    }

    protected fixedPrizesContracts() {
        const record = gameState.getCurrentGame().getCurrentRecord();
        const results = record.getResultsOfType(PYLBonusResult);
        const prizes = flatten(results.map(result => result.prizes));

        const contracts: Array<() => Contract> = [];
        
        contracts.push(() => this.btnPress());

        // stop the 1st reel
        contracts.push(() => Contract.getTimeoutContract(2000));
        contracts.push(() => Components.get(PYLSpinAWinBonusComponent).matrixComponents[0].stop(this.getSymbolId(prizes[0], false)));
        contracts.push(() => new Parallel([
            () => pulse((Components.get(PYLSpinAWinBonusComponent).matrixComponents[0].getSymbol(0, 3) as PYLSpinAWinSymbolSubcomponent).text, { x: 1.2, y: 1.2 }, 300, Easing.Sinusoidal.Out),
            () => Components.get(PYLBonusWinCounterComponent).addWin(prizes[0].cashWon, 1000)
        ]));

        // stop the 2nd reel
        contracts.push(() => Components.get(PYLSpinAWinBonusComponent).matrixComponents[1].stop(this.getSymbolId(prizes[1], false)));

        if (prizes[1].whammy) {
            contracts.push(() => Contract.wrap(() =>  Services.get(SoundService).customEvent(`whammy_${RandomRangeInt(1, 3)}_land`)));
        } else {
            contracts.push(() => new Parallel([
                () => pulse((Components.get(PYLSpinAWinBonusComponent).matrixComponents[1].getSymbol(0, 3) as PYLSpinAWinSymbolSubcomponent).text, { x: 1.2, y: 1.2 }, 300, Easing.Sinusoidal.Out),
                () => Components.get(PYLBonusWinCounterComponent).addWin(prizes[1].cashWon, 1000)
            ]));
        }

        // start the 3rd reel
        contracts.push(() => new Parallel([
            () => Components.get(PYLSpinAWinBonusComponent).matrixComponents[2].startTransition(),
            () => Components.get(PYLSpinAWinBonusComponent).reelBrightness(0, 0.2, 400),
            () => Components.get(PYLSpinAWinBonusComponent).reelBrightness(1, 0.2, 400),
            () => Components.get(PYLSpinAWinBonusComponent).reelBrightness(2, 1, 400, true),
            () => fadeIn2(gameLayers.SpinAWinMatrixForeground.scene.active.contents.fade_3, 1000),
            () => Contract.wrap(() => {
                Components.get(PYLSpinAWinBonusComponent).particles[0].emitter.emit = true;
                Components.get(PYLSpinAWinBonusComponent).particles[1].emitter.emit = true;
            })
        ]));
        contracts.push(() => this.btnPress());

        // stop the 3rd reel
        contracts.push(() => Components.get(PYLSpinAWinBonusComponent).matrixComponents[2].stop(this.getSymbolId(prizes[2], true)));

        if (prizes[2].whammy) {
            contracts.push(() => new Parallel([
                () => Components.get(PYLSpinAWinBonusComponent).matrixComponents[2].getSymbol(0, 4).win(),
                () => Contract.wrap(() => {
                    Services.get(SoundService).customEvent(`whammy_${RandomRangeInt(1, 3)}_land`);
                })
            ]));
            contracts.push(() => fadeIn2(gameLayers.WhammyAnims.scene.spin_a_win.contents.bg));
            contracts.push(() => Components.get(PYLWhammyAnimationComponent).playAnim());
            contracts.push(() => fadeOut2(gameLayers.WhammyAnims.scene.spin_a_win.contents.bg));
        } else {
            contracts.push(() => new Parallel([
                () => pulse((Components.get(PYLSpinAWinBonusComponent).matrixComponents[2].getSymbol(0, 4) as PYLSpinAWinSymbolSubcomponent).text, { x: 1.2, y: 1.2 }, 300, Easing.Sinusoidal.Out),
                () => Components.get(PYLBonusWinCounterComponent).addWin(prizes[2].cashWon, 1000)
            ]));
        }

        contracts.push(() => Contract.getTimeoutContract(2000));
        contracts.push(() => Contract.wrap(() => {
            Components.get(PYLSpinAWinBonusComponent).matrixComponents[2].getSymbol(0, 4).static();
            this.complete()
        }));

        return contracts;
    }

    protected btnPress() : Contract {
        return new Contract(resolve => {
            const cleanup = () => {
                gameLayers.Bonus.scene.active.contents.play.setEnabled(false);
                gameLayers.Bonus.scene.active.contents.play.off(ButtonEvent.CLICK, onClick);
            }
            gameLayers.Bonus.scene.active.contents.play.setEnabled(true);
            const onClick = () => {
                cleanup();
                this.stopFlashButton();
                resolve();
                return;
            }
            gameLayers.Bonus.scene.active.contents.play.on(ButtonEvent.CLICK, onClick);
            this.flashButton();
        });
    }

    protected flashButton() {
        const btn = gameLayers.Bonus.scene.active.contents.play;
        Timer.clearInterval(this.flashButtonInterval);
        brightness(btn, 1).execute();
        this.flashButtonInterval = Timer.setInterval(() => {
            new Sequence([
                () => brightness(btn, 1.5, 200),
                () => brightness(btn, 1, 200),
            ]).execute();
        }, 1000);
    }

    protected stopFlashButton() {
        Timer.clearInterval(this.flashButtonInterval);
        brightness(gameLayers.Bonus.scene.active.contents.play, 1).execute();
    }

    protected getSymbolId(prize: PYLPrize, lastWheel: boolean): string {
        const prefix = lastWheel ? "bottom" : "";

        if (prize.whammy) {
            if (lastWheel) {
                return `whammy_${RandomRangeInt(1, 3)}`;
            } else {
                return `wheelwhammy`;
            }
        } else {
            return `${prefix}wheelsymbol_${prize.multiplier || prize.cashWon}`;
        }
    }
}