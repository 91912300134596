import { Sprite } from "appworks/graphics/pixi/sprite";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { ToRadians } from "appworks/utils/math/angle";
import { Easing } from "appworks/utils/tween";
import { Tween } from "appworks/utils/tween";
import { AbstractSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/abstract-symbol-behaviour";
import { SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";

export class PYLSymbolGrowBehaviour extends AbstractSymbolBehaviour {
    public behaviourName: string = "PYLSymbolGrowBehaviour";

    protected sprite: Sprite;
    protected activeTween: Tween;

    protected contract: Contract<void>;

    public static() {
        super.static();
        this.symbol.setVisible(true);

        if (this.contract) {
            this.contract.cancel();
        }

        if (this.activeTween) {
            this.activeTween.stop();
        }

        if (this.sprite) {
            this.sprite.destroy();
            this.sprite = null;
        }
    }

    public land(): Contract<void> {
        this.symbol.setVisible(false);
        this.sprite = this.createStatic();

        const landscapeTween = new Tween(this.sprite.landscape.scale).to({ x: 1.2, y: 1.2 }, 400).easing(Easing.Back.Out);
        const portraitTween = new Tween(this.sprite.portrait.scale).to({ x: 1.2, y: 1.2 }, 400).easing(Easing.Back.Out);

        this.contract = new Parallel([
            () =>  TweenContract.wrapTween(landscapeTween),
            () =>  TweenContract.wrapTween(portraitTween)
        ]);

        return this.contract;
    }

    protected createStatic() {
        if (this.sprite) {
            this.sprite.destroy();
        }

        const sprite = this.symbol.createStaticCopy();
        this.animationLayer.add(sprite);

        return sprite;
    }
}