import { Gameplay } from "appworks/model/gameplay/gameplay";
import { SlingoInitRecordBuilder } from "slingo/integration/builders/records/slingo-init-record-builder";
import { SlingoRecord } from "slingo/model/records/slingo-record";

export class PYLInitRecordBuilder extends SlingoInitRecordBuilder {
    public build(gameplay: Gameplay, request: any, response: any): SlingoRecord[] {
        const record = super.build(gameplay, request, response)[0];

        record.cashWon = 0;

        return [record];
    }
}