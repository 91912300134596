
import { Contract } from "appworks/utils/contracts/contract";
import { BaseAnimateSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/base-animate-symbol-behaviour";
import { SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";

export class AnimateSymbolBehaviour extends BaseAnimateSymbolBehaviour {
    public behaviourName: string = "Animate";

    /**
     * Same as AnimateOverBehaviour except the static is hidden during the animation
     *
     * @param symbol {SymbolSubcomponent}
     * @param [defaultAniamtion="win"] {string}
     */
    constructor(symbol: SymbolSubcomponent, defaultAnimation: string = "win", protected alwaysOnTop: boolean = false) {
        super(symbol, defaultAnimation);
    }

    public static() {
        if (this.animation) {
            this.symbol.setVisible(true);
        }

        super.static();
    }

    public highlight(): Contract<void> {
        return this.animate(["_highlight", "_win"], [this.defaultAnimation], false, true);
    }

    public win(): Contract<void> {

        return this.animate("_win", [this.defaultAnimation]);
    }

    public cycle(): Contract<void> {

        return this.animate("_win", [this.defaultAnimation]);
    }

    public animate(animSuffix: string | string[], defaultAnimations?: string[], autoReset: boolean = false, loop: boolean = false) {
        this.resolveUnfinishedAnimation();

        this.symbol.setVisible(false);

        return new Contract<void>((resolve) => {
            super.animate(animSuffix, defaultAnimations, autoReset, loop).then(() => {
                if (!loop) {
                    this.symbol.setVisible(true);
                }
                resolve(null);
            });
        });
    }

    public updateTransform(): void {
        super.updateTransform();

        if (this.alwaysOnTop) {
            this.animation?.parent?.addChildAt(this.animation, this.animation?.parent?.children?.length ?? 0);
        }
    }
}
