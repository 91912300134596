import { MatrixComponent } from "slotworks/components/matrix/matrix-component";

// this just exists to stop clashes with SpinAWinBonusMatrixComponent
export class PYLMatrixComponent extends MatrixComponent {
    public enabled = true;

    protected update(): void {
        if (this.enabled) {
            super.update();
        }
    }

    protected postProcessReels() {
        this.clearSymbolProcessing();

        // this.processLockedReels();
        // this.processStackedSymbols();
        // this.processWideSymbols();
        // this.processStickyWilds();
        // this.processHiddenReels();

        if (this.zSortEnabled) {
            this.zSort();
        }

        this.getAllSymbols().forEach((symbol) => {
            symbol.updateTransform();
        });
    }
};