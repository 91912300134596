import { Components } from "appworks/components/components";
import { ParticleService } from "appworks/graphics/particles/particle-service";
import { Container } from "appworks/graphics/pixi/container";
import { CenterPivot } from "appworks/graphics/pixi/group";
import { ParticleContainer } from "appworks/graphics/pixi/particle-container";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { pulse } from "appworks/utils/animation/scale";
import { dualTransform, transform } from "appworks/utils/animation/transform";
import { flatten, shuffle } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { RandomRangeInt } from "appworks/utils/math/random";
import { Easing, Tween } from "appworks/utils/tween";
import { PYLBonusWinCounterComponent } from "components/pyl-bonus-win-counter-component";
import { PYLPickBonusCardComponent } from "components/pyl-pick-bonus-card-component";
import { PYLWhammyAnimationComponent } from "components/pyl-whammy-animation-component";
import { gameLayers } from "game-layers";
import { PYLPrize } from "model/pyl-prize";
import { PYLBonusResult } from "model/results/pyl-bonus-result.ts";
import { SignalBinding } from "signals";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { PYLAbstractBonusState } from "states/pyl-abstract-bonus-state";

export class PYLTakeYourPickBonusState extends PYLAbstractBonusState {
    protected cards: PYLPickBonusCardComponent[];
    protected cardOnPickListeners: SignalBinding[];

    protected onBonusSceneSet(): void {
        this.cards = [
            new PYLPickBonusCardComponent(0),
            new PYLPickBonusCardComponent(1),
            new PYLPickBonusCardComponent(2),
            new PYLPickBonusCardComponent(3)
        ];
        this.cards.forEach((card) => card.setButtonEnabled(false));

        const particles = gameLayers.Bonus.scene.take_your_pick.contents.generic as unknown as ParticleContainer;
        particles.emitter.emit = false;
    }

    protected waitForPick(): Contract<number> {
        if (this.cardOnPickListeners) {
            this.cardOnPickListeners.forEach(listener => listener.detach());
        }
        this.cardOnPickListeners = [];

        return new Contract(resolve => {
            this.cards.forEach((card) => {
                card.setButtonEnabled(true);

                const listener = card.onPick.add((index: number) => {
                    this.cards.forEach((card) => card.setButtonEnabled(false));
                    this.cards[index].bringToTop();
                    resolve(index);
                });
                this.cardOnPickListeners.push(listener);
            });
        });
    }

    protected prizeStep(prize: PYLPrize, stepIndex: number): Contract<void> {
        let pickIndex = -1;

        const seq = [
            () => this.waitForPick(),
            (index: number) => Contract.wrap(() => pickIndex = index),
            () => new Parallel([
                () => new Sequence([
                    () => this.cards[pickIndex].reveal(prize),
                    prize.cashWon ? () => pulse(this.cards[pickIndex].valueText, { x: 1.2, y: 1.2 }, 250, Easing.Back.Out) : () => Contract.empty()
                ]),
                () => Components.get(PYLBonusWinCounterComponent).addWin(prize.cashWon, 1000),
                () => this.lovelyParticles(pickIndex),
            ]),
            () => new Parallel([
                () => this.revealMisses(pickIndex, prize.misses),
                prize.cashWon ? () => Contract.empty() : () => Components.get(PYLWhammyAnimationComponent).playAnim()
            ])
        ];

        if (prize.cashWon > 0) {
            seq.push(
                () => Contract.getTimeoutContract(1000),
                () => this.playWhammyAddAnim(),
                () => new Parallel([
                    ...this.cards.map((card) => () => card.moveToCenter()),
                    () => Contract.getDelayedContract(1600,
                        () => new Parallel(this.cards.map((card) => () => card.flipToBlank()))
                    )
                ]),
                () => new Parallel(
                    this.cards.map((card) => () => card.moveToStartPos())
                )
            );
        }

        return new Sequence(seq);
    }

    protected playWhammyAddAnim(): Contract {
        const spine = gameLayers.Bonus.getSpine("bonus_01_whammy_fullscreen");
        const originalPos = spine.dualPosition.clone();
        gameLayers.Bonus.add(spine);
        Services.get(SoundService).customEvent("whammy_add_" + RandomRangeInt(1, 3));
        const cardToTransform = this.cards.find(card => !card.prize.whammy);
        const targetPos = spine.dualPosition.clone();
        const targetIndex = this.cards.findIndex((card) => card === cardToTransform);

        const xAmount = 180;
        const yAmount = 165;

        if (targetIndex === 0) {
            targetPos.landscape.x -= xAmount;
            targetPos.landscape.y -= yAmount;
            targetPos.portrait.x -= xAmount;
            targetPos.portrait.y -= yAmount;
        } else if (targetIndex === 1) {
            targetPos.landscape.x += xAmount;
            targetPos.landscape.y -= yAmount;
            targetPos.portrait.x += xAmount;
            targetPos.portrait.y -= yAmount;
        } else if (targetIndex === 2) {
            targetPos.landscape.x -= xAmount;
            targetPos.landscape.y += yAmount;
            targetPos.portrait.x -= xAmount;
            targetPos.portrait.y += yAmount;
        } else {
            targetPos.landscape.x += xAmount;
            targetPos.landscape.y += yAmount;
            targetPos.portrait.x += xAmount;
            targetPos.portrait.y += yAmount;
        }

        return new Sequence([
            () => new Parallel([
                () => spine.playOnce(0, true, 0.5),
                () => Contract.getDelayedContract(1300, () => new Parallel([
                    () => TweenContract.wrapCancellableTween(new Tween(spine.landscape).to({ x: targetPos.landscape.x, y: targetPos.landscape.y }, 1000).easing(Easing.Cubic.Out)),
                    () => TweenContract.wrapCancellableTween(new Tween(spine.portrait).to({ x: targetPos.portrait.x, y: targetPos.portrait.y }, 1000).easing(Easing.Cubic.Out)),
                    () => cardToTransform.transformToWhammy(),
                ]))
            ]),
            () => Contract.wrap(() => {
                spine.dualPosition.setPosition(originalPos);
            })
        ]);
    }

    protected revealMisses(index: number, misses: number[]): Contract {
        const contracts: Array<() => Contract> = [];
        const missValues = shuffle([...misses]);

        for (let i = 0; i < 4; i++) {
            if (i === index) { continue; }
            contracts.push(() => {
                const multiplier = missValues.pop();
                const fakePrize = {
                    multiplier: multiplier,
                    cashWon: multiplier * Services.get(SlotBetService).getTotalStake(),
                    whammy: multiplier === 0
                };
                return this.cards[i].reveal(fakePrize, true)
            });
        }
        return new Parallel(contracts);
    }

    protected lovelyParticles(pos: number) {
        const particles = gameLayers.Bonus.scene.take_your_pick.contents.generic as unknown as ParticleContainer;

        particles.dualPosition.setPosition(gameLayers.Bonus.scene.take_your_pick.contents.positions[`pos${pos}`]);
        particles.emitter.spawnRect.width = gameLayers.Bonus.scene.take_your_pick.contents.positions[`pos${pos}`].landscape.width;
        particles.emitter.spawnRect.height = gameLayers.Bonus.scene.take_your_pick.contents.positions[`pos${pos}`].landscape.height;

        return new Sequence([
            () => Contract.getTimeoutContract(200),
            () => Contract.wrap(() => particles.emitter.emit = true),
            () => Contract.getTimeoutContract(1000),
            () => Contract.wrap(() => particles.emitter.emit = false)
        ]);
    }
}