import { AbstractComponent } from "appworks/components/abstract-component";
import { ComponentFactory } from "appworks/components/factories/component-factory";
import { PYLBigWinComponent } from "components/pyl-big-win-component";
import { BigWinFactory } from "slotworks/components/factories/big-win-factory";

export class PYLBigWinFactory extends BigWinFactory {
    public build(): AbstractComponent {
        const bigWin = new PYLBigWinComponent();
        bigWin.countupMode = this.countMode;
        bigWin.upgrade = this.upgrade;
        bigWin.skipTier = this.skipTier;

        if (this.tiers) {
            for (const tier of this.tiers) {
                bigWin.addWinTier(tier);
            }
        }

        return bigWin;
    }
}
