import { BlastworksClientEvent, ClientController } from 'client-controller';
import { PYLGameOverState } from 'states/pyl-game-over-state';

/**
 * Social game over state to send game over message
 *
 * @class SocialGameOverState
 * @typedef {SocialGameOverState}
 * @extends {PYLGameOverState}
 */
export class SocialGameOverState extends PYLGameOverState {
  /**
   * Send game over message
   *
   * @public
   * @override
   * @param {?boolean} [cascadeSkip]
   */
  public onEnter(cascadeSkip?: boolean): void {
    ClientController.getInstance().raiseEvent(BlastworksClientEvent.GAME_OVER);
    super.onEnter(cascadeSkip);
  }
}
