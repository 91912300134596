import { AbstractSceneTransition } from "appworks/graphics/layers/scene-transitions/abstract-scene-transition";
import { fadeIn2, fadeOut2 } from "appworks/utils/animation/fade2";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { gameLayers } from "game-layers";
import { Easing } from "revolt-fx";

export class PYLFullHouseTransition extends AbstractSceneTransition {
    public in(): Contract<any> {
        const spine = gameLayers.CelebrationContent.scene.fullhouse.contents.full_house;

        return new Sequence([
            () => spine.playOnce("fh-in", false, 1.5),
            () => Contract.wrap(() => spine.play("fh-loop", 1.5))
        ]);
    }

    public out(): Contract<any> {
        const spine = gameLayers.CelebrationContent.scene.fullhouse.contents.full_house;

        return new Contract((resolve) => {
            spine.onComplete.addOnce(() => {
                spine.playOnce("fh-out", false, 1.5).then(() => resolve())
            });
        });
    }
}