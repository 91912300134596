import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Text } from "appworks/graphics/pixi/text";
import { Services } from "appworks/services/services";
import { brightness } from "appworks/utils/animation/brightness";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { Timer } from "appworks/utils/timer";
import { Easing, Tween } from "appworks/utils/tween";
import { gameLayers } from "game-layers";
import { AdjustmentFilter } from "pixi-filters";
import { DisplayObject } from "pixi.js";
import { SlingoLadderComponent } from "slingo/components/slingo-ladder-component";

const maskHeights = {
    0: 0,
    1: 0.115,
    2: 0.2,
    3: 0.26,
    4: 0.37,
    5: 0.455,
    6: 0.54,
    7: 0.62,
    8: 0.705,
    9: 0.79,
    10: 0.875,
    12: 1
};

type PYLLadderElement = {
    count?: Text;
    text?: Text;
    logo?: Sprite;
    background: Sprite;
    backgroundPortrait: Sprite;
};

export class PYLSlingoLadderComponent extends SlingoLadderComponent {
    protected levelElements: PYLLadderElement[] = [];
    protected intervalId: number = 0;

    public init(): void {
        gameLayers.SlingoLadder.onSceneEnter.add(() => this.setup());
    }

    protected setup() {
        this.layer = gameLayers.SlingoLadder;

        this.updateCashPrizeValues();

        gameLayers.SlingoLadder.scene.active.contents.ladder_whammy_landscape.play("idle");
        gameLayers.SlingoLadder.scene.active.contents.ladder_whammy_portrait.play("loop");

        if (this.intervalId) {
            Timer.clearInterval(this.intervalId);
        }
        this.intervalId = Timer.setInterval(() => {
            new Parallel([
                () => gameLayers.SlingoLadder.scene.active.contents.ladder_whammy_landscape.playOnce("anim_1"),
                () => gameLayers.SlingoLadder.scene.active.contents.ladder_whammy_portrait.playOnce("anim_1"),
            ]).then(() => {
                gameLayers.SlingoLadder.scene.active.contents.ladder_whammy_landscape.play("idle");
                gameLayers.SlingoLadder.scene.active.contents.ladder_whammy_portrait.play("loop");
            });
        }, 10000);

        for (let i = 1; i <= SlingoLadderComponent.MAX_SLINGOS; i++) {
            if (i === 11) {
                continue;
            }

            this.levelElements[i] = {
                count: gameLayers.SlingoLadder.scene.active.contents[`ladder_count_${i}`],
                text: gameLayers.SlingoLadder.scene.active.contents[`ladder_text_${i}`],
                logo: gameLayers.SlingoLadder.scene.active.contents[`level_${i}_logo`],
                background: gameLayers.SlingoLadder.scene.active.contents[`level_${i}`],
                backgroundPortrait: gameLayers.SlingoLadder.scene.active.contents[`level_${i}_portrait`]
            };

            if (this.levelElements[i].count && this.levelElements[i].text && this.levelElements[i].logo) {
                brightness(this.levelElements[i].count, 1, 0).execute();
                brightness(this.levelElements[i].text, 1, 0).execute();
                brightness(this.levelElements[i].logo, 1, 0).execute();
            }

            brightness(this.levelElements[i].background, 1, 0).execute();
            brightness(this.levelElements[i].backgroundPortrait, 1, 0).execute();
            
            this.levelElements[i].background.visible = false;
            this.levelElements[i].backgroundPortrait.visible = false;
        }

        const lvl = this.currentLevel;
        this.currentLevel = -1;
        this.setValue(lvl).execute();
    }

    protected setValue(level: number, playSound?: boolean, durationMs = 0): Contract<void> {
        const maskAmount = maskHeights[level];
        this.setMask(maskAmount, durationMs).execute();

        for (let i = 1; i <= 12; i++) {
            if (i === 11) {
                continue;
            }

            const elements = this.levelElements[i];

            elements.background.alpha = 1;
            elements.backgroundPortrait.alpha = 1;

            if (i === level) {
                if (elements.count) {
                    brightness(elements.count, 1, 0).execute();
                    brightness(elements.text, 1, 0).execute();
                    brightness(elements.logo, 1, 0).execute();
                }
                brightness(elements.background, 1, 0).execute();
                brightness(elements.backgroundPortrait, 1, 0).execute();
                
                elements.background.visible = true;
                elements.backgroundPortrait.visible = true;
            } else if (i < level) {
                if (elements.count) {
                    brightness(elements.count, 0.5, 0).execute();
                    brightness(elements.text, 0.5, 0).execute();
                    brightness(elements.logo, 0.5, 0).execute();
                }
                brightness(elements.background, 0.5, 0).execute();
                brightness(elements.backgroundPortrait, 0.5, 0).execute();
                
                elements.background.visible = true;
                elements.backgroundPortrait.visible = true;
            } else {
                if (elements.count) {
                    brightness(elements.count, 0.5, 0).execute();
                    brightness(elements.text, 0.5, 0).execute();
                    brightness(elements.logo, 0.5, 0).execute();
                }

                elements.background.visible = false;
                elements.backgroundPortrait.visible = false;
            }
        }

        return super.setValue(level, playSound);
    }

    public highlightLevel(level: number) {
        const elements = this.levelElements[level];
        if (elements.count) {
            brightness(elements.count, 1, 0).execute();
            brightness(elements.text, 1, 0).execute();
            brightness(elements.logo, 1, 0).execute();
        }
        brightness(elements.background, 1, 0).execute();
        brightness(elements.backgroundPortrait, 1, 0).execute();
        elements.background.alpha = 0.5;
        elements.backgroundPortrait.alpha = 0.5;
        elements.background.visible = true;
        elements.backgroundPortrait.visible = true;
    }

    protected setMask(amount: number, durationMs = 0) {
        if (gameLayers.SlingoLadder.currentSceneIs("paytable")) {
            gameLayers.SlingoLadder.scene.active.contents.pre_ladder_glitter.mask = gameLayers.SlingoLadder.scene.active.contents.glitter_mask;
            gameLayers.SlingoLadder.scene.active.contents.ladder_glittter.mask = gameLayers.SlingoLadder.scene.active.contents.glitter_mask;
            const targetY = (gameLayers.SlingoLadder.scene.active.contents.glitter_mask.texture.height - 100) * (1 - amount);
            gameLayers.SlingoLadder.scene.active.contents.glitter_mask.landscape.y = targetY;
            return Contract.empty();
        } else if (!gameLayers.SlingoLadder.currentSceneIs("base")) {
            return Contract.empty();
        }

        gameLayers.SlingoLadder.scene.active.contents.pre_ladder_glitter.mask = gameLayers.SlingoLadder.scene.active.contents.glitter_mask;
        gameLayers.SlingoLadder.scene.active.contents.ladder_glittter.mask = gameLayers.SlingoLadder.scene.active.contents.glitter_mask;

        gameLayers.SlingoLadder.scene.active.contents.portrait_pre_ladder_glitter.mask = gameLayers.SlingoLadder.scene.active.contents.portrait_glitter_mask;
        gameLayers.SlingoLadder.scene.active.contents.portrait_ladder_glittter.mask = gameLayers.SlingoLadder.scene.active.contents.portrait_glitter_mask;

        const targetY = (gameLayers.SlingoLadder.scene.active.contents.glitter_mask.texture.height - 100) * (1 - amount);
        const targetXPortrait = -(gameLayers.SlingoLadder.scene.active.contents.portrait_glitter_mask.texture.width - 150) * (1 - amount);

        const tween = new Tween(gameLayers.SlingoLadder.scene.active.contents.glitter_mask.landscape)
            .to({
                y: targetY
            }, durationMs);

        const tweenPortrait = new Tween(gameLayers.SlingoLadder.scene.active.contents.portrait_glitter_mask.portrait)
            .to({
                x: targetXPortrait
            }, durationMs);

        return new Parallel([
            () => TweenContract.wrapTween(tween),
            () => TweenContract.wrapTween(tweenPortrait)
        ]);
    }
}