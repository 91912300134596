import { stateMachine } from "appworks/state-machine/state-machine";
import { PYLMatrixComponent } from "components/pyl-matrix-component";
import { SlingoReelMatrixFactory } from "slingo/components/factories/slingo-reel-matrix-factory";
import { SlingoFreeSpinsRemainingDecision } from "slingo/states/decisions/slingo-free-spins-remaining-decision";
import { SlingoHasJokerDecision } from "slingo/states/decisions/slingo-has-joker-decision";
import { SlingoIsMaximumStakeLimitDecision } from "slingo/states/decisions/slingo-is-maximum-stake-limit-decision";
import { SlingoRecoveryIsBeforeFirstSpinDecision } from "slingo/states/decisions/slingo-recovery-is-before-first-spin-decision";
import { SlingoSpinPurchasedDecision } from "slingo/states/decisions/slingo-spin-purchased-decision";
import { SlingoStakeToSpinWonDecision } from "slingo/states/decisions/slingo-stake-to-spin-won-decision";
import { SlingoStandardSpinsRemainingDecision } from "slingo/states/decisions/slingo-standard-spins-remaining-decision";
import { SlingoTicketCompletedDecision } from "slingo/states/decisions/slingo-ticket-completed-decision";
import { SlingoBigWinState } from "slingo/states/slingo-big-win-state";
import { SlingoFullHouseState } from "slingo/states/slingo-full-house-state";
import { SlingoGameOverState } from "slingo/states/slingo-game-over-state";
import { SlingoIdleState } from "slingo/states/slingo-idle-state";
import { SlingoPickJokerState } from "slingo/states/slingo-pick-joker-state";
import { SlingoPurchaseEntryState } from "slingo/states/slingo-purchase-entry-state";
import { SlingoPurchaseSpinOrCollectState } from "slingo/states/slingo-purchase-spin-or-collect-state";
import { SlingoRecoveryState } from "slingo/states/slingo-recovery-state";
import { SlingoRequestSpinState } from "slingo/states/slingo-request-spin-state";
import { SlingoSpinState } from "slingo/states/slingo-spin-state";
import { IsBonusTriggeredDecision } from "slotworks/state-machine/standard/decisions/is-bonus-triggered-decision";
import { IsRecoveryDecision } from "slotworks/state-machine/standard/decisions/is-recovery-decision";
import { PYLBigWinState } from "states/pyl-big-win-state";
import { PYLGameOverState } from "states/pyl-game-over-state";
import { PYLInitState } from "states/pyl-init-state";
import { PYLLuckyPressBonusState } from "states/pyl-lucky-press-bonus-state";
import { PYLPressYourLuckBonusState } from "states/pyl-press-your-luck-bonus-state";
import { PYLPurchaseSpinOrCollectState } from "states/pyl-purchase-spin-or-collect-state";
import { PYLSlingoFullHouseState } from "states/pyl-slingo-full-house-state";
import { PYLSlingoRequestSpinState } from "states/pyl-slingo-request-spin-state";
import { PYLSlingoSpinState } from "states/pyl-slingo-spin-state";
import { PYLSpinAWinBonusState } from "states/pyl-spin-a-win-bonus-state";
import { PYLTakeYourPickBonusState } from "states/pyl-take-your-pick-bonus-state";

export function PYLStateSetup() {
    // States
    const initState = new PYLInitState();
    const idleState = new SlingoIdleState({ matrixComponentType: PYLMatrixComponent });
    const recoveryState = new SlingoRecoveryState();
    const purchaseEntryState = new SlingoPurchaseEntryState();
    const requestSpinState = new PYLSlingoRequestSpinState({ slingoMatrixComponentType: PYLMatrixComponent });
    const stakeToSpinRequestSpinState = new PYLSlingoRequestSpinState({ slingoMatrixComponentType: PYLMatrixComponent, skipRequest: true });
    const spinState = new PYLSlingoSpinState({ slingoMatrixComponentType: PYLMatrixComponent });
    const pickJokerState = new SlingoPickJokerState({ setWrapperIdleState: false });
    const purchaseOrCollectState = new PYLPurchaseSpinOrCollectState();
    const gameOverState = new PYLGameOverState();
    const fullHouseState = new PYLSlingoFullHouseState();
    const takeYourPickBonusState = new PYLTakeYourPickBonusState();
    const pressYourLuckBonusState = new PYLPressYourLuckBonusState();
    const luckyPressBonusState = new PYLLuckyPressBonusState();
    const spinAWinBonusState = new PYLSpinAWinBonusState();

    // Decisions
    const isRecoveryDecision = new IsRecoveryDecision();
    const hasJokerDecision = new SlingoHasJokerDecision();
    const standardSpinsRemainingDecision = new SlingoStandardSpinsRemainingDecision();
    const freeSpinsRemainingDecision = new SlingoFreeSpinsRemainingDecision();
    const ticketCompletedDecision = new SlingoTicketCompletedDecision();
    const spinPurchasedDecision = new SlingoSpinPurchasedDecision();
    const recoveryBeforeFirstSpinDecision = new SlingoRecoveryIsBeforeFirstSpinDecision();
    const isTakeYourPickBonus = new IsBonusTriggeredDecision("take_your_pick");
    const isPressYourLuckBonus = new IsBonusTriggeredDecision("press_your_luck");
    const isLuckyPressBonus = new IsBonusTriggeredDecision("lucky_press");
    const isSpinAWinBonus = new IsBonusTriggeredDecision("spin_a_win");
    const isStakeToSpinWonDecision = new SlingoStakeToSpinWonDecision();

    stateMachine.addStates([
        { state: initState,                          links: { complete: isRecoveryDecision }, entryPoint: true },
        { state: recoveryState,                      links: { complete: recoveryBeforeFirstSpinDecision } },
        { state: idleState,                          links: { complete: purchaseEntryState } },
        { state: purchaseEntryState,                 links: { complete: requestSpinState, error: idleState } },
        { state: requestSpinState,                   links: { complete: spinState, error: purchaseOrCollectState } },
        { state: stakeToSpinRequestSpinState,        links: { complete: spinState } },
        { state: spinState,                          links: { complete: ticketCompletedDecision } },
        { state: pickJokerState,                     links: { complete: ticketCompletedDecision } },
        { state: purchaseOrCollectState,             links: { complete: isStakeToSpinWonDecision } },
        { state: gameOverState,                      links: { complete: idleState } },
        { state: takeYourPickBonusState,             links: { complete: gameOverState } },
        { state: pressYourLuckBonusState,            links: { complete: gameOverState } },
        { state: luckyPressBonusState,               links: { complete: gameOverState } },
        { state: spinAWinBonusState,                 links: { complete: gameOverState } },
        { state: fullHouseState,                     links: { complete: pressYourLuckBonusState } },
    ]);

    stateMachine.addDecisions([
        { decision: isRecoveryDecision,              links: { true: recoveryState,               false: idleState } },
        { decision: recoveryBeforeFirstSpinDecision, links: { true: requestSpinState,            false: spinState } },
        { decision: hasJokerDecision,                links: { true: pickJokerState,              false: standardSpinsRemainingDecision } },
        { decision: standardSpinsRemainingDecision,  links: { true: requestSpinState,            false: freeSpinsRemainingDecision } },
        { decision: freeSpinsRemainingDecision,      links: { true: requestSpinState,            false: purchaseOrCollectState } },
        { decision: spinPurchasedDecision,           links: { true: requestSpinState,            false: isTakeYourPickBonus } },
        { decision: ticketCompletedDecision,         links: { true: isTakeYourPickBonus,         false: hasJokerDecision } },
        { decision: isTakeYourPickBonus,             links: { true: takeYourPickBonusState,      false: isPressYourLuckBonus } },
        { decision: isPressYourLuckBonus,            links: { true: pressYourLuckBonusState,     false: isLuckyPressBonus } },
        { decision: isLuckyPressBonus,               links: { true: luckyPressBonusState,        false: isSpinAWinBonus } },
        { decision: isSpinAWinBonus,                 links: { true: spinAWinBonusState,          false: gameOverState } },
        { decision: isStakeToSpinWonDecision,        links: { true: stakeToSpinRequestSpinState, false: spinPurchasedDecision } },
    ]);
}
