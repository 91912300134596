import { BlastworksClientEvent, ClientController } from 'client-controller';
import { PYLSlingoRequestSpinState } from 'states/pyl-slingo-request-spin-state';

/**
 * Social request spin state, sends spin result message
 *
 * @class SocialRequestSpinState
 * @typedef {SocialRequestSpinState}
 * @extends {PYLSlingoRequestSpinState}
 */
export class SocialRequestSpinState extends PYLSlingoRequestSpinState {
  /**
   * Send complete message
   *
   * @public
   * @override
   */
  public complete(): void {
    ClientController.getInstance().raiseEvent(BlastworksClientEvent.SPIN_RESULT);
    super.complete();
  }
}
