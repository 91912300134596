import { Position } from "appworks/graphics/pixi/position";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Timer } from "appworks/utils/timer";

export function shakeDualPosition(el: { landscape: Position, portrait: Position }, duration: number, magnitude = 1) : Contract<void> {
    return new Parallel([
        () => shake(el.landscape, duration, magnitude),
        () => shake(el.portrait, duration, magnitude),
    ]);
}

export function shake(el: { x: number, y: number }, duration: number, magnitude = 1) : Contract<void> {
    const startX = el.x;
    const startY = el.y;
    return new Contract<void>((resolve) => {
        const update = () => {
            const offsetX = ((Math.random()*2)-1) * magnitude;
            const offsetY = ((Math.random()*2)-1) * magnitude;
            el.x = startX + offsetX;
            el.y = startY + offsetY;
        };
        const interval = Timer.setInterval(() => update(), 1000 / 60);
        Timer.setTimeout(() => {
            Timer.clearInterval(interval);
            el.x = startX;
            el.y = startY;
            resolve();
        }, duration);
        update();
    });
}