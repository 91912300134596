import { Gameplay } from "appworks/model/gameplay/gameplay";
import { RequestBuilder } from "appworks/server/builders/request-builder";
import { AxiosRequestConfig } from "axios";
import { GMRFeaturePickRequestPayload } from "gaming-realms/integration/requests/gmr-feature-pick-request";

export class GMRFeaturePickRequestBuilder implements RequestBuilder {
    public filter(data: any): boolean {
        return data instanceof GMRFeaturePickRequestPayload;
    }

    public build(gameplay: Gameplay, data: GMRFeaturePickRequestPayload) {
        return {
            method: "put",
            url: "/actions/COLLECT",
            data: {
                playFeature: true,
                data: {
                    pick: data.pick.toString()
                }
            }
        } as AxiosRequestConfig;
    }

}
