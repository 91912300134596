import { AbstractComponent } from "appworks/components/abstract-component";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { Contract } from "appworks/utils/contracts/contract";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { Tween } from "appworks/utils/tween";
import { gameLayers } from "game-layers";

export class PYLBonusWinCounterComponent extends AbstractComponent {
    public value: number;

    constructor() {
        super();

        this.value = 0;

        gameLayers.BonusUI.onSceneEnter.add(() => {
            this.value = 0;
            this.updateText();
        });
    }

    public addWin(value: number, durationMs = 400, skipTransactionService = false): Contract {
        if (value === 0) {
            return Contract.empty();
        }
        
        if (!skipTransactionService) {
            Services.get(TransactionService).addWin(value).execute();
        }

        return this.setWin(this.value + value, durationMs);
    }

    public setWin(value: number, durationMs = 400): Contract {
        const tweenObj = { value: this.value };

        const tween = new Tween(tweenObj)
            .to({ value }, durationMs)
            .onUpdate(() => {
                this.value = tweenObj.value;
                this.updateText();
            })
            .onStart(() => {
                Services.get(SoundService).event(SoundEvent.winnings_countup);
            })
            .onComplete(() => {
                this.value = value;
                this.updateText();
                Services.get(SoundService).event(SoundEvent.winnings_countup_end);
            });

        return TweenContract.wrapTween(tween);
    }

    public setVisible(visible: boolean): void {
        if (!gameLayers.BonusUI.hasAssetOfName("total_win_value")) {
            return;
        }

        gameLayers.BonusUI.scene.bonus.contents.total_win_label.visible = visible;
        gameLayers.BonusUI.scene.bonus.contents.total_win_value.visible = visible;
        gameLayers.BonusUI.scene.bonus.contents.win_plaque.visible = visible;
    }

    protected updateText() {
        if (!gameLayers.BonusUI.hasAssetOfName("total_win_value")) {
            return;
        }

        const cashText = Services.get(CurrencyService).format(this.value, true);
        gameLayers.BonusUI.scene.bonus.contents.total_win_value.text = cashText;
    }
}