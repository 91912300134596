import { AnimatedSprite } from "appworks/graphics/animation/animated-sprite";
import { GraphicsService } from "appworks/graphics/graphics-service";
import { Container } from "appworks/graphics/pixi/container";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { HTMLText } from "appworks/graphics/pixi/html-text";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { gameState } from "appworks/model/game-state";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { brightness } from "appworks/utils/animation/brightness";
import { fadeIn2, fadeOut2 } from "appworks/utils/animation/fade2";
import { lastInArray } from "appworks/utils/collection-utils";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { roundToMultiple } from "appworks/utils/math/number";
import { RandomFromArray } from "appworks/utils/math/random";
import { gameLayers } from "game-layers";
import { PYLPrize } from "model/pyl-prize";
import { PYLBonusResult } from "model/results/pyl-bonus-result.ts";
import { fakeLuckyPressPrizes } from "states/pyl-lucky-press-bonus-state";
import { fakePressYourLuckPrizes } from "states/pyl-press-your-luck-bonus-state";
import { getMultiplierTint, pylTint } from "utils/pyl-tint";

export class PYLBoardTile extends Container {
    protected whammies: AnimatedSprite[] = [];
    protected background: Sprite;
    protected centerText: HTMLText;
    protected moveText: HTMLText;
    protected moveValue: Sprite;
    protected moveArrow: Sprite;
    protected heart: Sprite;
    protected currentPrize?: PYLPrize;

    constructor(pos: DualPosition) {
        super();

        this.landscape.x = pos.landscape.x;
        this.landscape.y = pos.landscape.y;
        this.portrait.x = pos.portrait.x;
        this.portrait.y = pos.portrait.y;

        this.background = Services.get(GraphicsService).createSprite("Bonus-board_game/tile");
        this.background.dualPosition.setSize(pos);

        for (let i=1; i<=3; i++) {
            const whammyAnim = Services.get(GraphicsService).createAnimation(`whammy_${i}_win`);
            whammyAnim.name = `whammy_${i}`;
            whammyAnim.dualPosition.setSize(pos);
            whammyAnim.loop = true;
            whammyAnim.gotoAndStop(0);
            whammyAnim.alpha = 0;
            this.whammies.push(whammyAnim);
        }

        this.heart = Services.get(GraphicsService).createSprite("board/heart");
        this.heart.anchor.set(0.5, 0.6);
        this.heart.dualPosition.setPosition(this.background.dualPosition.getCenterPos());

        const multiplierTextTemplate = gameLayers.Bonus.scene.board_game.contents.multiplier_value_template as unknown as HTMLText
        this.centerText = this.createText(multiplierTextTemplate);
        this.centerText.setDualPosition(this.background.dualPosition);

        const moveTextTemplate = gameLayers.Bonus.scene.board_game.contents.move_text_template as unknown as HTMLText
        this.moveText = this.createText(moveTextTemplate);
        this.moveText.setDualPosition(this.background.dualPosition);
        this.moveText.landscape.y = -25;
        this.moveText.portrait.y = -25;

        this.moveValue = Services.get(GraphicsService).createSprite("Bonus-board_game/move_value");
        this.moveValue.dualPosition.setPosition(this.background.dualPosition);
        this.moveValue.landscape.x = 63;
        this.moveValue.landscape.y = 53;
        this.moveValue.portrait.x = 68;
        this.moveValue.portrait.y = 63;

        this.moveArrow = Services.get(GraphicsService).createSprite("Bonus-board_game/move_arrow");
        this.moveArrow.dualPosition.setPosition(this.background.dualPosition);
        this.moveArrow.pivot.set(31, 24);
        this.moveArrow.landscape.x = 37;
        this.moveArrow.landscape.y = 78;
        this.moveArrow.portrait.x = 45;
        this.moveArrow.portrait.y = 85;

        this.addChild(this.background, ...this.whammies, this.heart, this.centerText, this.moveText, this.moveValue, this.moveArrow);

        brightness(this, 1, 0).execute();

        this.reset();
    }

    public setPrize(prize: PYLPrize, tileIndex: number): Contract {
        this.reset();

        this.currentPrize = prize;

        if (prize.multiplier > 0 && !prize.extraLife) {
            this.background.alpha = 1;
            this.centerText.text = `<s>x</s><center>${roundToMultiple(prize.multiplier, 0.01)}</center>`;
            this.centerText.alpha = 1;
        } else if (prize.whammy) {
            this.background.alpha = 1;
            const randomWhammy = RandomFromArray(this.whammies);
            randomWhammy.alpha = 1;
        } else if (prize.extraLife) {
            this.background.alpha = 1;
            const life = Services.get(TranslationsService).get("life");
            this.centerText.text = `<m>+1\n${life.toLocaleUpperCase()}</m>`;
            this.centerText.alpha = 1;
            this.heart.alpha = 1;
        } else if (prize.moveTwo) {
            this.background.alpha = 1;
            this.moveText.alpha = 1;
            this.moveValue.alpha = 1;
            this.moveArrow.alpha = 1;
            if (tileIndex >= 0 && tileIndex <= 4) {
                this.moveArrow.angle = 180;
            } else if (tileIndex >= 5 && tileIndex <= 8) {
                this.moveArrow.angle = 270;
            } else if (tileIndex >= 9 && tileIndex <= 13) {
                this.moveArrow.angle = 0;
            } else {
                this.moveArrow.angle = 90;
            }
        } else {
            throw new Error(`Unhandled prize: ${JSON.stringify(prize)}`)
        }

        this.setTint(prize);

        return Contract.empty();
    }

    public getPrize(): Readonly<PYLPrize> {
        return this.currentPrize;
    }

    public highlight(time = 0): Contract {
        return brightness(this, 1, time);
    }

    public unhighlight(time = 0): Contract {
        return brightness(this, 0.5, time);
    }

    public flashHighlight(): Contract {
        const numOfFlashes = 4;

        return new Sequence(Array(numOfFlashes).fill(0).map((_) => {
            return () => new Sequence([
                () => this.unhighlight(),
                () => Contract.getTimeoutContract(50),
                () => this.highlight(),
                () => Contract.getTimeoutContract(400),
            ]);
        }));
    }

    public whammyAnim() {
        let anim = this.whammies.find(whammy => whammy.alpha !== 0);
        if (!anim) {
            return;
        }
        anim.gotoAndPlay(0);
        anim.alpha = 1;
        
        const whammyIndex = this.whammies.indexOf(anim);
        Services.get(SoundService).customEvent(`whammy_${whammyIndex + 1}_land`);
    }

    public getWhammyAnimId(): string | undefined {
        const activeWhammy = this.whammies.find(whammy => whammy.alpha !== 0);
        return activeWhammy?.name;
    }

    public destroyed() {
        return this._destroyed;
    }

    protected reset() {
        this.whammies.forEach(anim => {
            anim.gotoAndStop(0);
            anim.alpha = 0;
        });
        this.centerText.alpha = 0;
        this.background.alpha = 0;
        this.moveText.alpha = 0;
        this.moveValue.alpha = 0;
        this.moveArrow.alpha = 0;
        this.heart.alpha = 0;
    }

    protected setTint(prize: PYLPrize) {
        if (prize.moveTwo) {
            this.background.tint = pylTint.red;
        } else if (prize.extraLife) {
            this.background.tint = pylTint.lightGreen;
        } else {
            const record = gameState.getCurrentGame().getCurrentRecord();
            const result = record.getFirstResultOfType(PYLBonusResult);
            const bonusId = result.id;
            const variant = result.variant;

            const maxMult = bonusId === "lucky_press" ? lastInArray(fakeLuckyPressPrizes[variant]) : lastInArray(fakePressYourLuckPrizes[variant]);

            this.background.tint = getMultiplierTint(prize.multiplier, maxMult);
        }
    }

    protected createText(textTemplate: HTMLText): HTMLText {
        const text = textTemplate.clone();

        text.text = textTemplate.text.toLocaleUpperCase();

        text.tagStyles = {
            default: {
                ...text.defaultStyle,
                fill: "#fff",
                fontFamily: "impact-regular",
                stroke: "#000",
                strokeThickness: 4,
                dropShadow: true,
                dropShadowAlpha: 0.5,
                dropShadowBlur: 10,
                dropShadowDistance: 2,
                lineSpacing: -10
            },
            center: {
                fontSize: 50,
            },
            m: {
                fontSize: 50,
            },
            s: {
                fontSize: 40
            }
        };

        return text;
    }
}