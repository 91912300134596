import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { CenterPivot, PIXIElement, ResetPivot } from "appworks/graphics/pixi/group";
import { gameState } from "appworks/model/game-state";
import { CloseRequestPayload } from "appworks/model/gameplay/requests/close-request-payload";
import { commsManager } from "appworks/server/comms-manager";
import { Services } from "appworks/services/services";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { uiFlags, UIFlag } from "appworks/ui/flags/ui-flags";
import { fadeIn2, fadeOut2 } from "appworks/utils/animation/fade2";
import { Contract } from "appworks/utils/contracts/contract";
import { Sequence } from "appworks/utils/contracts/sequence";
import { gameLayers } from "game-layers";
import { PYLBonusResult } from "model/results/pyl-bonus-result.ts";
import { SlingoGameProgressResult } from "slingo/model/results/slingo-game-progress-result";
import { SlingoLadderResult } from "slingo/model/results/slingo-ladder-result";
import { SlingoPurchaseSpinOrCollectState } from "slingo/states/slingo-purchase-spin-or-collect-state";
import { SlingoPotentialWin } from "slingo/util/slingo-get-potential-wins";
import { BonusResult } from "slotworks/model/gameplay/records/results/bonus-result";

export class PYLPurchaseSpinOrCollectState extends SlingoPurchaseSpinOrCollectState {
    protected potentialWin: SlingoPotentialWin;

    public onExit(): void {
        const record = gameState.getCurrentGame().getCurrentRecord();
        if (!record.hasResultOfType(BonusResult)) {
            gameLayers.BonusTransitionLogo.defaultScene().execute();
        }

        fadeIn2(gameLayers.SpinButton.container).execute();

        super.onExit();
    }

    protected showPotentialWin(win: SlingoPotentialWin): void {
        this.potentialWin = win;

        this.hideElementsIfNoPotentialWins();

        super.showPotentialWin(win);
    }
    
    protected getPotentialWinDisplayElements(): PIXIElement[] {
        const record = gameState.getCurrentGame().getCurrentRecord();

        const scene = gameLayers.Collect.scene.collect.contents;
        const win = this.potentialWin;
        let elements: PIXIElement[] = [];

        if (win.lines === 4) {
            elements = [
                scene.potential_win_frame,
                scene.title,
                scene.footer,
                scene.take_your_pick_logo_low,
            ];
            scene.footer.text = Services.get(TranslationsService).get("take_your_pick");
        } else if (win.lines === 5) {
            elements = [
                scene.potential_win_frame,
                scene.title,
                scene.footer,
                scene.take_your_pick_logo_high,
            ];
            scene.footer.text = Services.get(TranslationsService).get("take_your_pick");
        } else if (win.lines === 6) {
            elements = [
                scene.potential_win_frame,
                scene.title,
                scene.footer,
                scene.press_your_luck_logo_low,
            ];
            scene.footer.text = Services.get(TranslationsService).get("press_your_luck");
        } else if (win.lines === 7) {
            elements = [
                scene.potential_win_frame,
                scene.title,
                scene.footer,
                scene.lucky_press_logo_low,
            ];
            scene.footer.text = Services.get(TranslationsService).get("lucky_press");
        } else if (win.lines === 8) {
            elements = [
                scene.potential_win_frame,
                scene.title,
                scene.footer,
                scene.lucky_press_logo_high,
            ];
            scene.footer.text = Services.get(TranslationsService).get("lucky_press");
        } else if (win.lines === 9) {
            elements = [
                scene.potential_win_frame,
                scene.title,
                scene.footer,
                scene.spin_a_win_logo_low,
            ];
            scene.footer.text = Services.get(TranslationsService).get("spin_a_win");
        } else if (win.lines === 10) {
            elements = [
                scene.potential_win_frame,
                scene.title,
                scene.footer,
                scene.spin_a_win_logo_high,
            ];
            scene.footer.text = Services.get(TranslationsService).get("spin_a_win");
        } else if (win.lines === 12) {
            elements = [
                scene.potential_win_frame,
                scene.title,
                scene.footer,
                scene.press_your_luck_logo_high,
            ];
            scene.footer.text = Services.get(TranslationsService).get("press_your_luck");
        }

        return elements.map(el => {
            ResetPivot(el);
            CenterPivot(el);
            return el;
        });
    }

    protected hideElementsIfNoPotentialWins() {
        for (const element of gameLayers.Collect.getAllElements()) {
            element.alpha = 0;
        }

        gameLayers.Collect.scene.collect.contents.purchase.alpha = 1;
        gameLayers.Collect.scene.collect.contents.collect.alpha = 1;
        gameLayers.Collect.scene.collect.contents.collect_bonus.alpha = 1;
        gameLayers.BonusTransitionLogo.scene.collect.contents.spin_a_win_logo_high.alpha = 1;
        gameLayers.BonusTransitionLogo.scene.collect.contents.take_your_pick_logo_high.alpha = 1;
        gameLayers.BonusTransitionLogo.scene.collect.contents.press_your_luck_logo_high.alpha = 1;
        gameLayers.BonusTransitionLogo.scene.collect.contents.lucky_press_logo_high.alpha = 1;
        gameLayers.BonusTransitionLogo.scene.collect.contents.spin_a_win_logo_low.alpha = 1;
        gameLayers.BonusTransitionLogo.scene.collect.contents.take_your_pick_logo_low.alpha = 1;
        gameLayers.BonusTransitionLogo.scene.collect.contents.press_your_luck_logo_low.alpha = 1;
        gameLayers.BonusTransitionLogo.scene.collect.contents.lucky_press_logo_low.alpha = 1;
    }

    protected setCollectButton(): void {
        if (gameLayers.SpinButton.container.alpha !== 0) {
            fadeOut2(gameLayers.SpinButton.container).execute();
        }

        gameLayers.BonusTransitionLogo.setScene("collect").execute();

        const gameplay = gameState.getCurrentGame();
        const record = gameplay.getCurrentRecord();
        const ladderResult = record.getFirstResultOfType(SlingoLadderResult);

        gameLayers.Collect.scene.collect.contents.collect.visible = false;
        gameLayers.Collect.scene.collect.contents.collect_bonus.visible = false;
        gameLayers.BonusTransitionLogo.scene.collect.contents.spin_a_win_logo_high.visible = false;
        gameLayers.BonusTransitionLogo.scene.collect.contents.take_your_pick_logo_high.visible = false;
        gameLayers.BonusTransitionLogo.scene.collect.contents.lucky_press_logo_high.visible = false;
        gameLayers.BonusTransitionLogo.scene.collect.contents.press_your_luck_logo_high.visible = false;
        gameLayers.BonusTransitionLogo.scene.collect.contents.spin_a_win_logo_low.visible = false;
        gameLayers.BonusTransitionLogo.scene.collect.contents.take_your_pick_logo_low.visible = false;
        gameLayers.BonusTransitionLogo.scene.collect.contents.lucky_press_logo_low.visible = false;
        gameLayers.BonusTransitionLogo.scene.collect.contents.press_your_luck_logo_low.visible = false;

        gameLayers.BonusTransitionLogo.scene.collect.contents.spin_a_win_logo_low.setDualPosition(gameLayers.BonusTransitionLogo.scene.collect.contents.positions.spin_a_win_logo_start);
        gameLayers.BonusTransitionLogo.scene.collect.contents.take_your_pick_logo_low.setDualPosition(gameLayers.BonusTransitionLogo.scene.collect.contents.positions.take_your_pick_logo_start);
        gameLayers.BonusTransitionLogo.scene.collect.contents.lucky_press_logo_low.setDualPosition(gameLayers.BonusTransitionLogo.scene.collect.contents.positions.lucky_press_logo_start);
        gameLayers.BonusTransitionLogo.scene.collect.contents.press_your_luck_logo_low.setDualPosition(gameLayers.BonusTransitionLogo.scene.collect.contents.positions.press_your_luck_logo_start);
        gameLayers.BonusTransitionLogo.scene.collect.contents.spin_a_win_logo_high.setDualPosition(gameLayers.BonusTransitionLogo.scene.collect.contents.positions.spin_a_win_logo_start);
        gameLayers.BonusTransitionLogo.scene.collect.contents.take_your_pick_logo_high.setDualPosition(gameLayers.BonusTransitionLogo.scene.collect.contents.positions.take_your_pick_logo_start);
        gameLayers.BonusTransitionLogo.scene.collect.contents.lucky_press_logo_high.setDualPosition(gameLayers.BonusTransitionLogo.scene.collect.contents.positions.lucky_press_logo_start);
        gameLayers.BonusTransitionLogo.scene.collect.contents.press_your_luck_logo_high.setDualPosition(gameLayers.BonusTransitionLogo.scene.collect.contents.positions.press_your_luck_logo_start);

        let collectButton = gameLayers.Collect.scene.collect.contents.collect;
        collectButton.setLabelText(Services.get(TranslationsService).get("end_game"));

        if (ladderResult.total === 4) {
            collectButton = gameLayers.Collect.scene.collect.contents.collect_bonus;
            collectButton.setLabelText(`${Services.get(TranslationsService).get("collect")} ${Services.get(TranslationsService).get("take_your_pick")}`);
            gameLayers.BonusTransitionLogo.scene.collect.contents.take_your_pick_logo_low.visible = true;
        } else if (ladderResult.total === 5) {
            collectButton = gameLayers.Collect.scene.collect.contents.collect_bonus;
            collectButton.setLabelText(`${Services.get(TranslationsService).get("collect")} ${Services.get(TranslationsService).get("take_your_pick")}`);
            gameLayers.BonusTransitionLogo.scene.collect.contents.take_your_pick_logo_high.visible = true;
        } else if (ladderResult.total === 6) {
            collectButton = gameLayers.Collect.scene.collect.contents.collect_bonus;
            collectButton.setLabelText(`${Services.get(TranslationsService).get("collect")} ${Services.get(TranslationsService).get("press_your_luck")}`);
            gameLayers.BonusTransitionLogo.scene.collect.contents.press_your_luck_logo_low.visible = true;
        } else if (ladderResult.total === 7) {
            collectButton = gameLayers.Collect.scene.collect.contents.collect_bonus;
            collectButton.setLabelText(`${Services.get(TranslationsService).get("collect")} ${Services.get(TranslationsService).get("lucky_press")}`);
            gameLayers.BonusTransitionLogo.scene.collect.contents.lucky_press_logo_low.visible = true;
        } else if (ladderResult.total === 8) {
            collectButton = gameLayers.Collect.scene.collect.contents.collect_bonus;
            collectButton.setLabelText(`${Services.get(TranslationsService).get("collect")} ${Services.get(TranslationsService).get("lucky_press")}`);
            gameLayers.BonusTransitionLogo.scene.collect.contents.lucky_press_logo_high.visible = true;
        } else if (ladderResult.total === 9) {
            collectButton = gameLayers.Collect.scene.collect.contents.collect_bonus;
            collectButton.setLabelText(`${Services.get(TranslationsService).get("collect")} ${Services.get(TranslationsService).get("spin_a_win")}`);
            gameLayers.BonusTransitionLogo.scene.collect.contents.spin_a_win_logo_low.visible = true;
        } else if (ladderResult.total === 10) {
            collectButton = gameLayers.Collect.scene.collect.contents.collect_bonus;
            collectButton.setLabelText(`${Services.get(TranslationsService).get("collect")} ${Services.get(TranslationsService).get("spin_a_win")}`);
            gameLayers.BonusTransitionLogo.scene.collect.contents.spin_a_win_logo_high.visible = true;
        } else if (ladderResult.total === 12) {
            collectButton = gameLayers.Collect.scene.collect.contents.collect_bonus;
            collectButton.setLabelText(`${Services.get(TranslationsService).get("collect")} ${Services.get(TranslationsService).get("press_your_luck")}`);
            gameLayers.BonusTransitionLogo.scene.collect.contents.press_your_luck_logo_high.visible = true;
        }

        collectButton.setEnabled(true);
        collectButton.visible = true;
        collectButton.on(ButtonEvent.CLICK, () => this.collect());
    }

    protected enableButtons(): Contract<any> {
        gameLayers.Collect.scene.collect.contents.collect_bonus.setEnabled(true);

        return super.enableButtons();
    }

    protected disableButtons() {
        if (gameLayers.Collect.currentSceneIs("collect")) {
            gameLayers.Collect.scene.collect.contents.collect_bonus.setEnabled(false);
        }

        return super.disableButtons();
    }
}