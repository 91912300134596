import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { Orientation } from "appworks/graphics/canvas/orientation";
import { GraphicsService } from "appworks/graphics/graphics-service";
import { CenterPivot } from "appworks/graphics/pixi/group";
import { Services } from "appworks/services/services";
import { Contract } from "appworks/utils/contracts/contract";
import { Rectangle } from "appworks/utils/geom/rectangle";
import * as TweenJS from "appworks/utils/tween";
import { AbstractSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/abstract-symbol-behaviour";
import { SymbolSubcomponent } from "../symbol-subcomponent";

export class LandPulseSymbolBehaviour extends AbstractSymbolBehaviour {

    public behaviourName: string = "LandPulse";

    protected masterSymbol: string = "symbol_0_0";

    protected scale: number;

    protected offset: Rectangle;

    constructor(symbol: SymbolSubcomponent, protected animationDuration: number = 200, protected zoomTo: number = 1.1) {
        super(symbol);
    }

    public static(): void {
        super.static();
        this.cleanup();
        this.symbol.setVisible(true);
    }

    public land(): Contract<void> {
        this.createSprite();

        this.symbol.setVisible(false);

        const originalScale = this.behaviourSprite.scale.x;
        this.scale = originalScale;

        const orientation = Services.get(CanvasService).orientation === Orientation.LANDSCAPE ? "landscape" : "portrait";

        const masterRect = this.matrixLayer.getPosition(this.masterSymbol)[orientation];
        let animRect = this.matrixLayer.getPosition(this.symbol.symbolId)?.[orientation];

        if (!animRect) {
            animRect = this.matrixLayer.getPosition("static")[orientation];
        }

        const offsetX = animRect.x - masterRect.x;
        const offsetY = animRect.y - masterRect.y;
        this.offset = new Rectangle(
            offsetX + (animRect.width / 2),
            offsetY + (animRect.height / 2),
            (animRect.width / masterRect.width),
            (animRect.height / masterRect.height)
        );

        this.updateTransform();
        
        return new Contract<void>((resolve) => {
            const growTween = new TweenJS.Tween(this)
                .to({ scale: originalScale * this.zoomTo }, this.animationDuration / 2);

            const shrinkTween = new TweenJS.Tween(this)
                .to({ scale: originalScale }, this.animationDuration / 2);

            growTween.chain(shrinkTween);

            shrinkTween.onComplete(() => {
                this.cleanup();
                this.symbol.setVisible(true);
                resolve();
            });

            growTween.start();
        });
    }

    public updateTransform() {
        super.updateTransform();

        if (this.behaviourSprite) {
            this.behaviourSprite.landscape.x += this.offset.x;
            this.behaviourSprite.landscape.y += this.offset.y;
            this.behaviourSprite.landscape.width *= this.offset.width * this.scale;
            this.behaviourSprite.landscape.height *= this.offset.height * this.scale;

            // Adjust for if this is the middle of a stack
            let stackOffset = this.symbol.getStackOffsetLandscapeY();
            if (stackOffset) {
                this.behaviourSprite.landscape.y -= stackOffset;
            }

            this.behaviourSprite.portrait.x += this.offset.x;
            this.behaviourSprite.portrait.y += this.offset.y;
            this.behaviourSprite.portrait.width *= this.offset.width * this.scale;
            this.behaviourSprite.portrait.height *= this.offset.height * this.scale;

            // Adjust for if this is the middle of a stack
            stackOffset = this.symbol.getStackOffsetLandscapeY();
            if (stackOffset) {
                this.behaviourSprite.portrait.y -= stackOffset;
            }
        }
    }

    protected createSprite() {
        this.cleanup();

        this.behaviourSprite = Services.get(GraphicsService).createSprite(this.symbol.symbolId);
        CenterPivot(this.behaviourSprite);
        this.animationLayer.add(this.behaviourSprite);
    }

    protected cleanup() {
        if (this.behaviourSprite) {
            this.animationLayer.remove(this.behaviourSprite);
            this.behaviourSprite.destroy();
            this.behaviourSprite = undefined;
        }
    }
}
