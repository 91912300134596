import { RandomRangeInt } from "appworks/utils/math/random";
import { SlingoReelSpinResultBuilder } from "slingo/integration/builders/results/slingo-reel-spin-result-builder";
import { SlingoResponse } from "slingo/integration/slingo-schema";
import { SlingoRecord } from "slingo/model/records/slingo-record";
import { SlingoReelSpinResult } from "slingo/model/results/slingo-reel-spin-result";
import { slotDefinition } from "slotworks/model/slot-definition";

export class PYLReelSpinResultBuilder extends SlingoReelSpinResultBuilder {
    protected mapSymbol(serverSymbolId: string): string {
        if (serverSymbolId === "D") {
            return `whammy_${RandomRangeInt(1, 3)}`;
        }

        return super.mapSymbol(serverSymbolId);
    }
}
