import { PYLSymbolGrowBehaviour } from "behaviors/pyl-symbol-grow-behaviour";
import { PYLWhammyLandSymbolBehavior } from "behaviors/pyl-whammy-land-symbol-behavior";
import { PYLMatrixComponent } from "components/pyl-matrix-component";
import { SlingoReelMatrixFactory } from "slingo/components/factories/slingo-reel-matrix-factory";
import { AbstractMatrixComponent } from "slotworks/components/matrix/abstract-matrix-component";
import { SpinStageEvents } from "slotworks/components/matrix/reel/transition-behaviours/spin/reel-spinner";
import { AnimateSymbolBehaviour } from "slotworks/components/matrix/symbol/behaviours/animate-symbol-behaviour";
import { SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";
import { slotDefinition } from "slotworks/model/slot-definition";

export class PYLReelMatrixFactory extends SlingoReelMatrixFactory {
    constructor(...args: ConstructorParameters<typeof SlingoReelMatrixFactory>) {
        super(...args);

        this.spinStages.find((stage) => stage.name === "stop").events[0].delay = 125;

        const minStopDistance = this.calculateMinStopDistance();

        this.spinStages = [
            {
                name: "accelerate",
                s: -1,
                u: 0,
                v: -this.spinSpeed,
                events: [
                    {
                        name: SpinStageEvents.START,
                        delay: 50
                    }
                ]
            },
            {
                name: "minRepeat",
                s: -1,
                u: -this.spinSpeed,
                v: -this.spinSpeed
            },
            {
                name: "repeat",
                s: -1,
                u: -this.spinSpeed,
                v: -this.spinSpeed,
                events: [
                    {
                        name: SpinStageEvents.START,
                        delay: 0
                    }
                ]
            },
            {
                name: "stop",
                s: -minStopDistance,
                u: -this.spinSpeed,
                v: -this.spinSpeed,
                events: [
                    {
                        name: SpinStageEvents.STOP,
                        delay: 250
                    }
                ]
            },
            {
                name: "overshoot",
                s: -0.35,
                u: -0.01,
                v: 0
            },
            {
                name: "complete",
                s: 0.35,
                t: 220,
                easing: "Quadratic.Out",
                events: [
                    {
                        name: SpinStageEvents.SKIP,
                        delay: 100
                    },
                    {
                        name: SpinStageEvents.ANTICIPATE,
                        delay: 200
                    }
                ]
            },
            {
                name: "land",
                s: 0,
                t: 0
            },
            {
                name: "pointless_yet_somehow_critical_stage",
                s: 0,
                t: 0
            }
        ];

        this.skipStages = [
            {
                name: "stop",
                s: -this.calculateMinStopDistance(),
                t: 0,
                events: [
                    {
                        name: SpinStageEvents.STOP,
                        delay: 0
                    },
                    {
                        name: SpinStageEvents.SKIP,
                        delay: 0
                    },
                    {
                        name: SpinStageEvents.ANTICIPATE,
                        delay: 100
                    }
                ]
            },
            {
                name: "land",
                s: 0,
                t: 0
            }
        ];
    }

    protected calculateMinStopDistance(): number {
        return super.calculateMinStopDistance() + 2;
    }

    protected createMatrix(): AbstractMatrixComponent {
        return new PYLMatrixComponent(
            slotDefinition.matrixGrid,
            this.defaultStops,
            slotDefinition.reelsets.get(this.defaultReelset)
        );
    }

    protected addSymbolBehaviours(matrix: AbstractMatrixComponent<SymbolSubcomponent>): void {
        matrix.addDefaultSymbolBehaviour((symbol) => new PYLWhammyLandSymbolBehavior(symbol), ["whammy_1", "whammy_2", "whammy_3"]);
        matrix.addDefaultSymbolBehaviour((symbol) => new PYLSymbolGrowBehaviour(symbol), ["SJ", "J"]);
    }
}