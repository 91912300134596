import { AbstractComponent } from "appworks/components/abstract-component";
import { AnimatedSprite } from "appworks/graphics/animation/animated-sprite";
import { ButtonElement, ButtonEvent } from "appworks/graphics/elements/button-element";
import { Container } from "appworks/graphics/pixi/container";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { Group, PIXIElement, Ungroup } from "appworks/graphics/pixi/group";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Text } from "appworks/graphics/pixi/text";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { pulse, scale } from "appworks/utils/animation/scale";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { Easing, Tween } from "appworks/utils/tween";
import { gameLayers } from "game-layers";
import { PYLPrize } from "model/pyl-prize";
import { Signal } from "signals";

export class PYLPickBonusCardComponent extends AbstractComponent {
    public onPick: Signal = new Signal();
    public prize: PYLPrize;

    protected button: ButtonElement;
    protected blankFace: Sprite;
    public valueText: Text;
    protected whammy: AnimatedSprite;

    public group: Container;

    constructor(protected index: number, protected layer = gameLayers.Bonus) {
        super();

        this.button = this.layer.getButton("pick_" + this.index);
        this.blankFace = this.layer.getSprite("blank_" + this.index);
        this.valueText = this.layer.getText("prize_value_" + this.index);
        this.whammy = this.layer.getAnimatedSprite("whammy_" + this.index);

        this.group = Group([this.button, this.blankFace, this.valueText, this.whammy]);

        this.blankFace.landscape.scale.set(0, 1);
        this.blankFace.portrait.scale.set(0, 1);
        this.whammy.landscape.scale.set(0, 1);
        this.whammy.portrait.scale.set(0, 1);
        this.valueText.landscape.scale.set(0, 1);
        this.valueText.portrait.scale.set(0, 1);

        this.button.on(ButtonEvent.CLICK, () => this.onPick.dispatch(this.index));

        this.moveToStartPos(1).execute();
    }

    public setButtonEnabled(enabled: boolean) {
        this.button.setEnabled(enabled);
    }

    public reveal(prize: PYLPrize, dim: boolean = false): Contract {
        Services.get(SoundService).customEvent("card_flip");

        this.whammy.gotoAndStop(0);

        this.valueText.text = Services.get(CurrencyService).format(prize.cashWon, true);

        this.blankFace.tint = dim ? 0xAAAAAA : 0xFFFFFF;
        this.valueText.tint = dim ? 0xAAAAAA : 0xFFFFFF;
        this.whammy.tint = dim ? 0xAAAAAA : 0xFFFFFF;

        return new Sequence([
            () => scale(this.button, { x: 0, y: 1 }, 250, Easing.Circular.In),
            () => new Parallel([
                () => scale(this.blankFace, { x: 1, y: 1 }, 250, Easing.Circular.Out),
                () => scale(prize.whammy ? this.whammy : this.valueText, { x: 1, y: 1 }, 250, Easing.Circular.Out),
                () => Contract.wrap(() => {
                    this.prize = prize;

                    if (prize.whammy && !dim) {
                        this.whammy.play();
                        Services.get(SoundService).customEvent("pyl_whammy_hit");
                    }
                }),
            ])
        ]);
    }

    public flipToBlank(): Contract {
        Services.get(SoundService).customEvent("card_flip");

        return new Sequence([
            () => new Parallel([
                () => scale(this.blankFace, { x: 0, y: 1 }, 250, Easing.Circular.In),
                () => scale(this.valueText, { x: 0, y: 1 }, 250, Easing.Circular.In),
                () => scale(this.whammy, { x: 0, y: 1 }, 250, Easing.Circular.In)
            ]),
            () => scale(this.button, { x: 1, y: 1 }, 250, Easing.Circular.Out),
        ]);
    }

    public transformToWhammy(): Contract {
        return new Sequence([
            () => new Parallel([
                () => scale(this.valueText, { x: 0, y: 0 }, 250, Easing.Circular.In),
                () => scale(this.whammy, { x: 1, y: 1 }, 250, Easing.Circular.In)
            ])
        ]);
    }

    public bringToTop() {
        gameLayers.Bonus.add(this.group);
    }

    public moveToStartPos(time: number = 500): Contract {
        const position = gameLayers.Bonus.getPosition("pos" + this.index);
        return this.moveToPosition(position, time);
    }

    public moveToCenter(time: number = 500): Contract {
        const position = gameLayers.Bonus.getPosition("center");
        return this.moveToPosition(position, time);
    }

    protected moveToPosition(pos: DualPosition, time: number): Contract {
        return new Parallel([
            () => TweenContract.wrapTween(
                new Tween(this.group.landscape)
                    .to({ x: pos.landscape.x, y: pos.landscape.y }, time)
                    .easing(Easing.Cubic.Out)
            ),
            () => TweenContract.wrapTween(
                new Tween(this.group.portrait)
                    .to({ x: pos.portrait.x, y: pos.portrait.y }, time)
                    .easing(Easing.Cubic.Out)
            )
        ]);
    }
}