import { AbstractComponent } from "appworks/components/abstract-component";
import { Components } from "appworks/components/components";
import { AnimationService } from "appworks/graphics/animation/animation-service";
import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { ButtonEvent } from "appworks/graphics/elements/button-element";
import { GraphicsService } from "appworks/graphics/graphics-service";
import { Container } from "appworks/graphics/pixi/container";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { HTMLText } from "appworks/graphics/pixi/html-text";
import { Position } from "appworks/graphics/pixi/position";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { gameState } from "appworks/model/game-state";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { TransactionService } from "appworks/services/transaction/transaction-service";
import { TranslationsService } from "appworks/services/translations/translations-service";
import { brightness } from "appworks/utils/animation/brightness";
import { fadeIn2, fadeOut2 } from "appworks/utils/animation/fade2";
import { dualTransform } from "appworks/utils/animation/transform";
import { tweenNumberText } from "appworks/utils/animation/tween-number-text";
import { lastInArray, normalizeIndex, shuffle, swapElements } from "appworks/utils/collection-utils";
import { CancelGroup } from "appworks/utils/contracts/cancel-group";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { TweenContract } from "appworks/utils/contracts/tween-contract";
import { logger } from "appworks/utils/logger";
import { precisionRound, roundToMultiple } from "appworks/utils/math/number";
import { RandomFromArray, RandomRangeInt } from "appworks/utils/math/random";
import { Timer } from "appworks/utils/timer";
import { Easing } from "appworks/utils/tween";
import { Tween } from "appworks/utils/tween";
import { PYLBoardTile } from "components/pyl-board-tile-subcomponent";
import { PYLBonusWinCounterComponent } from "components/pyl-bonus-win-counter-component";
import { PYLWhammyAnimationComponent } from "components/pyl-whammy-animation-component";
import { gameLayers } from "game-layers";
import { PYLPrize } from "model/pyl-prize";
import { PYLBonusResult } from "model/results/pyl-bonus-result.ts";
import { AdjustmentFilter } from "pixi-filters";
import { DisplayObject } from "pixi.js";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";

/**
 * Tile indexes start from 0 in the top left, going clockwise until the one below top left (17)
 */

export const chanceOfMove2OnBoard = 0.3;
export const chanceOfLandingOnMove2 = 0.3;
export const chanceOfExtraLifeOnBoard = 0.1;

export class PYLBoardComponent extends AbstractComponent {
    public playing = false;
    public activeTile: PYLBoardTile;

    protected tiles: PYLBoardTile[] = [];
    protected loopCancelGroup = new CancelGroup();
    protected generateRandomBoardPrizes: (prize?: PYLPrize) => PYLPrize[];

    public init(): void {
        gameLayers.Bonus.onSceneEnter.add((sceneName: keyof typeof gameLayers.Bonus.scene) => {
            if (sceneName === "board_game") {
                gameLayers.Bonus.scene.board_game.contents.tile.visible = false;
                gameLayers.Bonus.scene.board_game.contents.multiplier_value_template.visible = false;
                gameLayers.Bonus.scene.board_game.contents.move_text_template.visible = false;
                gameLayers.Bonus.scene.board_game.contents.move_value.visible = false;
                gameLayers.Bonus.scene.board_game.contents.move_arrow.visible = false;
                gameLayers.Bonus.scene.board_game.contents.whammy_steal.visible = false;
                gameLayers.Bonus.scene.board_game.contents.steal_title.visible = false;
                gameLayers.Bonus.scene.board_game.contents.steals_value.visible = false;

                gameLayers.Bonus.scene.board_game.contents.win_backing.alpha = 0;
                gameLayers.Bonus.scene.board_game.contents.win_label.alpha = 0;
                gameLayers.Bonus.scene.board_game.contents.win_value.alpha = 0;

                gameLayers.Bonus.add(gameLayers.Bonus.scene.board_game.contents.gold_back, false, true);

                (gameLayers.Bonus.scene.board_game.contents.win_value as unknown as HTMLText).setTagStyles({
                    s: {
                        fontSize: 60
                    }
                });
            } else {
                for (const tile of this.tiles) {
                    if (gameLayers.Bonus.has(tile)) {
                        gameLayers.Bonus.remove(tile);
                    }
                }
            }
        });

        gameLayers.Bonus.afterSceneEnter.add((sceneName: keyof typeof gameLayers.Bonus.scene) => {
            if (sceneName === "board_game") {
                for (let i = 0; i < 18; i++) {
                    let tile = this.tiles[i];

                    if (!tile || tile.destroyed()) {
                        const pos = gameLayers.Bonus.scene.board_game.contents.positions[`tile_${i}`];
                        tile = new PYLBoardTile(pos);
                        this.tiles[i] = tile;
                    }

                    if (!gameLayers.Bonus.has(tile)) {
                        gameLayers.Bonus.add(tile)
                    }
                };
            }
        });
    }

    // https://www.youtube.com/watch?v=e75sF9H5Jco&t=498s
    public play(generateRandomBoardPrizes: () => PYLPrize[], lastTileHighlightedIndex = 0, step = 0): void {
        if (!this.playing) {
            Services.get(SoundService).customEvent("pyl_board_flash_start");
        }

        this.playing = true;
        this.generateRandomBoardPrizes = generateRandomBoardPrizes;

        this.tiles.forEach(tile => brightness(tile, 1).execute());
        this.resetCentralGfx().execute();

        const contracts: Array<() => Contract> = [];

        if (step % 3 === 0 && this.playing) {
            const tiles = this.generateRandomBoardPrizes();
            contracts.push(() => this.loopCancelGroup.add(this.setTiles(tiles)));
        }

        const landIndex = RandomRangeInt(0, 17, [lastTileHighlightedIndex]);
        lastTileHighlightedIndex = landIndex;
        const tile = this.tiles[landIndex];

        contracts.push(() => this.loopCancelGroup.parallel([
            () => this.loopCancelGroup.add(this.hideHighlights()),
            () => this.loopCancelGroup.add(tile.highlight()),
            () => this.loopCancelGroup.timeoutContract(160),
        ]));

        this.loopCancelGroup.sequence(contracts).then(() => {
            if (this.playing) {
                this.play(generateRandomBoardPrizes, lastTileHighlightedIndex, step + 1);
            }
        });
    }

    public stop(prize: PYLPrize): Contract {
        Services.get(SoundService).customEvent("pyl_board_flash_stop");

        this.playing = false;
        this.loopCancelGroup.cancel();

        const tiles = this.generateRandomBoardPrizes(prize);

        let prizeTileIndex = tiles.indexOf(prize);
        let prizeTile = this.tiles[prizeTileIndex];

        const moveTileIndex = tiles.findIndex(tile => tile.moveTwo);
        const moveTile = this.tiles[moveTileIndex];
        const landOnMove = moveTile && Math.random() <= chanceOfLandingOnMove2;

        if (landOnMove) {
            swapElements(tiles, prizeTileIndex, normalizeIndex(moveTileIndex + 2, 18));
            prizeTileIndex = tiles.indexOf(prize);
            prizeTile = this.tiles[prizeTileIndex];
        }

        this.setTiles(tiles).execute();

        if (landOnMove) {
            const middleTileIndex = normalizeIndex(moveTileIndex + 1, 18);
            const middleTile = this.getTile(middleTileIndex);

            return new Sequence([
                () => this.hideHighlights(),
                () => moveTile.flashHighlight(),
                () => this.hideHighlights(),
                () => middleTile.highlight(),
                () => Contract.getTimeoutContract(400),
                () => this.hideHighlights(),
                () => this.awardPrize(prizeTile, prize)
            ]);
        } else {
            return this.awardPrize(prizeTile, prize);
        }
    }

    public setTiles(prizes: PYLPrize[]): Contract {
        if (prizes.length !== 18) {
            throw new Error(`prizes.length must be 18, it is ${prizes.length}`);
        }

        return new Parallel(this.tiles.map((tile, i) => () => tile.setPrize(prizes[i], i)));
    }

    public playWhammyAnimation(type: "win" | "lose" = "lose"): Contract {
        return new Parallel([
            () => Components.get(PYLWhammyAnimationComponent).playAnim(type),
            () => fadeOut2(gameLayers.Bonus.scene.board_game.contents.logo_middle, 200),
        ]);
    }

    protected getTile(tileIndex: number) {
        return this.tiles[tileIndex];
    }

    protected hideHighlights(): Parallel {
        return new Parallel(this.tiles.map(tile => () => tile.unhighlight()));
    }

    protected awardPrize(winningTile: PYLBoardTile, prize: PYLPrize): Contract {
        this.activeTile = winningTile;
        const otherTiles = this.tiles.filter(tile => tile !== winningTile);

        if (prize.whammy) {
            Services.get(SoundService).customEvent("pyl_whammy_hit");
        } else {
            Services.get(SoundService).customEvent("pyl_win_hit");
            Services.get(SoundService).customEvent("pyl_win_cheer_" + RandomRangeInt(1, 5));
        }

        return new Parallel([
            ...otherTiles.map(tile => () => brightness(tile, 0.5)),
            () => winningTile.flashHighlight(),
            () => Contract.wrap(() => {
                if (prize.whammy) {
                    winningTile.whammyAnim();
                }
            }),
        ]);
    }

    public resetCentralGfx(): Contract {
        const elements = [
            gameLayers.Bonus.scene.board_game.contents.win_backing,
            gameLayers.Bonus.scene.board_game.contents.win_label,
            gameLayers.Bonus.scene.board_game.contents.win_value,
            gameLayers.Bonus.scene.board_game.contents.whammy_steal,
            gameLayers.Bonus.scene.board_game.contents.steal_title,
            gameLayers.Bonus.scene.board_game.contents.steals_value,
        ];

        return new Parallel([
            ...elements.map(el => () => {
                if (el.alpha === 1) {
                    return fadeOut2(el, 200);
                } else {
                    return Contract.empty();
                }
            }),
            () => fadeIn2(gameLayers.Bonus.scene.board_game.contents.logo_middle, 200),
        ]);
    }

    public awardCashPrize(amount: number) {
        if (amount <= 0) {
            return Contract.empty();
        }

        gameLayers.Bonus.scene.board_game.contents.win_label.text = Services.get(TranslationsService).get("win");
        gameLayers.Bonus.scene.board_game.contents.win_value.text = "0";

        const contracts: Array<() => Contract> = [];

        const winTween = tweenNumberText({
            text: gameLayers.Bonus.scene.board_game.contents.win_value,
            from: 0,
            to: amount,
            duration: 1000,
            mutator: (value) => Services.get(CurrencyService).format(value, true)
        })

        contracts.push(() => new Parallel([
            () => fadeIn2([
                gameLayers.Bonus.scene.board_game.contents.win_backing,
                gameLayers.Bonus.scene.board_game.contents.win_label,
                gameLayers.Bonus.scene.board_game.contents.win_value,
            ], 200),
            () => Components.get(PYLBonusWinCounterComponent).addWin(amount, 1000, true),
            () => TweenContract.wrapTween(winTween),
        ]));

        return new Sequence(contracts);
    }
}