import { CanvasService } from "appworks/graphics/canvas/canvas-service";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { SoundService } from "appworks/services/sound/sound-service";
import { fadeIn2 } from "appworks/utils/animation/fade2";
import { shakeDualPosition } from "appworks/utils/animation/shake";
import { dualTransform } from "appworks/utils/animation/transform";
import { tweenNumberText } from "appworks/utils/animation/tween-number-text";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Easing, Tween } from "appworks/utils/tween";
import { gameLayers } from "game-layers";
import { SignalBinding } from "signals";
import { BigWinComponent, BigWinTierConfig } from "slotworks/components/bigwin/big-win-component";


export class PYLBigWinComponent extends BigWinComponent {
    protected x2: boolean = false;
    protected countupTween: Tween;
    protected onSceneEnterBinding: SignalBinding;

    public showWin(amount: number, stake: number, autoHide: boolean = true, x2 = false, bonusId?: string, variant?: string) {
        this.onSceneEnterBinding = gameLayers.CelebrationForeground.onSceneEnter.add(() => {
            gameLayers.CelebrationForeground.scene.active.contents.spin_a_win_logo_low.alpha = 0;
            gameLayers.CelebrationForeground.scene.active.contents.lucky_press_logo_low.alpha = 0;
            gameLayers.CelebrationForeground.scene.active.contents.take_your_pick_logo_low.alpha = 0;
            gameLayers.CelebrationForeground.scene.active.contents.press_your_luck_logo_low.alpha = 0;
            gameLayers.CelebrationForeground.scene.active.contents.spin_a_win_logo_high.alpha = 0;
            gameLayers.CelebrationForeground.scene.active.contents.lucky_press_logo_high.alpha = 0;
            gameLayers.CelebrationForeground.scene.active.contents.take_your_pick_logo_high.alpha = 0;
            gameLayers.CelebrationForeground.scene.active.contents.press_your_luck_logo_high.alpha = 0;
    
            const bonusLogoName = `${bonusId}_logo_${variant}`;
            const bonusLogo = gameLayers.CelebrationForeground.scene.active.contents[bonusLogoName];
            if (bonusLogo) {
                gameLayers.CelebrationForeground.scene.active.contents[bonusLogoName].alpha = 1;
            }
        });

        this.x2 = x2;
        return super.showWin(amount, stake, autoHide);
    }

    public hide(): Contract<void> {
        return new Parallel([
            () => super.hide(),
            () => gameLayers.CelebrationValue.defaultScene()
        ]);
    }

    protected getTierCountTime(currentTier: BigWinTierConfig, amount: number, stake: number) {
        return currentTier.countTime;
    }

    protected startCountup(amount: number, stake: number) {
        super.startCountup(amount, stake);

        gameLayers.CelebrationValue.jumpToScene("win");

        gameLayers.CelebrationValue.add(gameLayers.CelebrationValue.scene.active.contents.x2);
        gameLayers.CelebrationValue.scene.active.contents.x2.alpha = 0;

        const valueText = gameLayers.CelebrationValue.scene.active.contents.value;

        const totalCountupTime = this.getLinearCountTime(amount, stake);

        if (this.x2) {
            const tween1 = tweenNumberText({
                text: valueText,
                from: 0,
                to: amount * 0.5,
                duration: totalCountupTime * 0.5,
                easing: Easing.Circular.Out,
                mutator: (value) => Services.get(CurrencyService).format(value, true),
                onComplete: () => this.triggerX2()
            });

            const tween2 = tweenNumberText({
                text: valueText,
                from: amount * 0.5,
                to: amount,
                duration: totalCountupTime * 0.5,
                mutator: (value) => Services.get(CurrencyService).format(value, true)
            });

            this.countupTween = tween1.chain(tween2);
        } else {
            this.countupTween = tweenNumberText({
                text: valueText,
                from: 0,
                to: amount,
                duration: totalCountupTime,
                mutator: (value) => Services.get(CurrencyService).format(value, true)
            });
        }

        this.countupTween.start();
    }

    protected complete(amount: number): void {
        super.complete(amount);

        this.onSceneEnterBinding.detach();
        this.onSceneEnterBinding = undefined;
        this.countupTween.stop();
    }

    protected triggerX2() {
        const x2TargetPos = new DualPosition(
            gameLayers.CelebrationValue.scene.active.contents.x2.landscape.clone(),
            gameLayers.CelebrationValue.scene.active.contents.x2.portrait.clone(),
        );
        gameLayers.CelebrationValue.scene.active.contents.x2.landscape.y += 50;
        gameLayers.CelebrationValue.scene.active.contents.x2.portrait.y += 50;
        dualTransform(gameLayers.CelebrationValue.scene.active.contents.x2, x2TargetPos, 600, Easing.Elastic.Out).execute();
        fadeIn2(gameLayers.CelebrationValue.scene.active.contents.x2, 250, Easing.Sinusoidal.Out).execute();
        shakeDualPosition(Services.get(CanvasService).stage, 500, 7).execute();
        Services.get(SoundService).customEvent("slingo");
    }
}