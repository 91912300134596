import { AnimatedSprite } from "appworks/graphics/animation/animated-sprite";
import { ButtonElement } from "appworks/graphics/elements/button-element";
import { SmartShape } from "appworks/graphics/elements/smart-shape";
import { Layers } from "appworks/graphics/layers/layers";
import { Scene } from "appworks/graphics/layers/scene";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { SpineContainer } from "appworks/graphics/pixi/spine-container";
import { Sprite } from "appworks/graphics/pixi/sprite";
import { Text } from "appworks/graphics/pixi/text";
import { SoundNames } from "sounds-enum";

class GameLayers {
    public Menus: Layers & {
        scene: {
             preloader: Scene & {
                contents: {
                    preloader?: SpineContainer
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    preloader?: SpineContainer
                    cover?: SmartShape
                    sound_effects?: Text
                    background_music?: Text
                    deposit?: ButtonElement
                    ui_help?: ButtonElement
                    ui_play_controls?: ButtonElement
                    lobby?: ButtonElement
                    history?: ButtonElement
                    arrow_forward?: ButtonElement
                    arrow_back?: ButtonElement
                    stake_0?: ButtonElement
                    stake_1?: ButtonElement
                    stake_2?: ButtonElement
                    stake_3?: ButtonElement
                    stake_4?: ButtonElement
                    stake_5?: ButtonElement
                    stake_6?: ButtonElement
                    stake_7?: ButtonElement
                    stake_label_0?: Text
                    stake_label_1?: Text
                    stake_label_2?: Text
                    stake_label_3?: Text
                    stake_label_4?: Text
                    stake_label_5?: Text
                    stake_label_6?: Text
                    stake_label_7?: Text
                    close?: ButtonElement
                    extra_spins_0?: Text
                    extra_spins_1?: Text
                    extra_spins_2?: Text
                    extra_spins_3?: Text
                    extra_spins_4?: Text
                    spin_price_0?: Text
                    spin_price_1?: Text
                    spin_price_2?: Text
                    spin_price_3?: Text
                    spin_price_4?: Text
                    total_stake_0?: Text
                    total_stake_1?: Text
                    total_stake_2?: Text
                    total_stake_3?: Text
                    total_stake_4?: Text
                    total_loss_0?: Text
                    total_loss_1?: Text
                    total_loss_2?: Text
                    total_loss_3?: Text
                    total_loss_4?: Text
                    save?: ButtonElement
                    cancel?: ButtonElement
                    frame?: Sprite
                    title_play_controls_spins?: Text
                    title_play_controls_spin_price?: Text
                    title_play_controls_stake?: Text
                    title_play_controls_loss?: Text
                };
            };
             settings: Scene & {
                contents: {
                    cover?: SmartShape
                    sound_effects?: Text
                    background_music?: Text
                    deposit?: ButtonElement
                    ui_help?: ButtonElement
                    ui_play_controls?: ButtonElement
                    lobby?: ButtonElement
                    history?: ButtonElement
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             stake: Scene & {
                contents: {
                    arrow_forward?: ButtonElement
                    arrow_back?: ButtonElement
                    stake_0?: ButtonElement
                    stake_1?: ButtonElement
                    stake_2?: ButtonElement
                    stake_3?: ButtonElement
                    stake_4?: ButtonElement
                    stake_5?: ButtonElement
                    stake_6?: ButtonElement
                    stake_7?: ButtonElement
                    cover?: SmartShape
                    stake_label_0?: Text
                    stake_label_1?: Text
                    stake_label_2?: Text
                    stake_label_3?: Text
                    stake_label_4?: Text
                    stake_label_5?: Text
                    stake_label_6?: Text
                    stake_label_7?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             rules: Scene & {
                contents: {
                    close?: ButtonElement
                    cover?: SmartShape
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             play_controls: Scene & {
                contents: {
                    cover?: SmartShape
                    extra_spins_0?: Text
                    extra_spins_1?: Text
                    extra_spins_2?: Text
                    extra_spins_3?: Text
                    extra_spins_4?: Text
                    spin_price_0?: Text
                    spin_price_1?: Text
                    spin_price_2?: Text
                    spin_price_3?: Text
                    spin_price_4?: Text
                    total_stake_0?: Text
                    total_stake_1?: Text
                    total_stake_2?: Text
                    total_stake_3?: Text
                    total_stake_4?: Text
                    total_loss_0?: Text
                    total_loss_1?: Text
                    total_loss_2?: Text
                    total_loss_3?: Text
                    total_loss_4?: Text
                    save?: ButtonElement
                    cancel?: ButtonElement
                    frame?: Sprite
                    title_play_controls_spins?: Text
                    title_play_controls_spin_price?: Text
                    title_play_controls_stake?: Text
                    title_play_controls_loss?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public SuperSpinWheel: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    cta_bg?: Sprite
                    help?: ButtonElement
                    spin?: ButtonElement
                    wheel_portrait?: SpineContainer
                    wheel_landscape?: SpineContainer
                    cta?: Text
                    counter?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    cta_bg?: Sprite
                    help?: ButtonElement
                    spin?: ButtonElement
                    wheel_portrait?: SpineContainer
                    wheel_landscape?: SpineContainer
                    cta?: Text
                    counter?: Text
                };
            };
        };
    };
    public Debug: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public BonusUI: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    win_plaque?: Sprite
                    total_win_value?: Text
                    total_win_label?: Text
                };
            };
             bonus: Scene & {
                contents: {
                    win_plaque?: Sprite
                    total_win_value?: Text
                    total_win_label?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public Bonus: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        pos0?: DualPosition
                        pos1?: DualPosition
                        pos2?: DualPosition
                        pos3?: DualPosition
                        center?: DualPosition
                        whammy_portrait$whammy_portrait_5?: DualPosition
                        whammy_portrait$whammy_portrait_4?: DualPosition
                        whammy_portrait$whammy_portrait_3?: DualPosition
                        whammy_portrait$whammy_portrait_2?: DualPosition
                        whammy_portrait$whammy_portrait_1?: DualPosition
                        whammy_portrait$whammy_portrait_0?: DualPosition
                        whammy_1$whammy_5_1?: DualPosition
                        whammy_2$whammy_5_2?: DualPosition
                        whammy_3$whammy_5_3?: DualPosition
                        whammy_heart$whammy_flag_5?: DualPosition
                        whammy_1$whammy_4_1?: DualPosition
                        whammy_2$whammy_4_2?: DualPosition
                        whammy_3$whammy_4_3?: DualPosition
                        whammy_heart$whammy_flag_4?: DualPosition
                        whammy_1$whammy_3_1?: DualPosition
                        whammy_2$whammy_3_2?: DualPosition
                        whammy_3$whammy_3_3?: DualPosition
                        whammy_heart$whammy_flag_3?: DualPosition
                        whammy_1$whammy_2_1?: DualPosition
                        whammy_2$whammy_2_2?: DualPosition
                        whammy_3$whammy_2_3?: DualPosition
                        whammy_heart$whammy_flag_2?: DualPosition
                        whammy_1$whammy_1_1?: DualPosition
                        whammy_2$whammy_1_2?: DualPosition
                        whammy_3$whammy_1_3?: DualPosition
                        whammy_heart$whammy_flag_1?: DualPosition
                        whammy_1$whammy_0_1?: DualPosition
                        whammy_2$whammy_0_2?: DualPosition
                        whammy_3$whammy_0_3?: DualPosition
                        whammy_heart$whammy_flag_0?: DualPosition
                        tile_0?: DualPosition
                        tile_1?: DualPosition
                        tile_2?: DualPosition
                        tile_3?: DualPosition
                        tile_4?: DualPosition
                        tile_5?: DualPosition
                        tile_6?: DualPosition
                        tile_7?: DualPosition
                        tile_8?: DualPosition
                        tile_9?: DualPosition
                        tile_10?: DualPosition
                        tile_11?: DualPosition
                        tile_12?: DualPosition
                        tile_13?: DualPosition
                        tile_14?: DualPosition
                        tile_15?: DualPosition
                        tile_16?: DualPosition
                        tile_17?: DualPosition
                    };
                    generic?: Sprite
                    bonus_01_whammy_fullscreen?: SpineContainer
                    whammy_0?: AnimatedSprite
                    whammy_1?: AnimatedSprite
                    whammy_2?: AnimatedSprite
                    whammy_3?: AnimatedSprite
                    blank_0?: Sprite
                    blank_1?: Sprite
                    blank_2?: Sprite
                    blank_3?: Sprite
                    pick_0?: ButtonElement
                    pick_1?: ButtonElement
                    pick_2?: ButtonElement
                    pick_3?: ButtonElement
                    frame_frame?: Sprite
                    frame_bg?: Sprite
                    goldiethingy?: Sprite
                    title?: Text
                    prize_value_0?: Text
                    prize_value_1?: Text
                    prize_value_2?: Text
                    prize_value_3?: Text
                    multiplier_bg?: Sprite
                    collect?: ButtonElement
                    landscape_bell?: Sprite
                    landscape_bangstick_1?: Sprite
                    landscape_bangstick_2?: Sprite
                    landscape_x2?: Sprite
                    landscape_lights_1?: Sprite
                    landscape_lights_2?: Sprite
                    landscape_collect_frame?: Sprite
                    landscape_glass?: Sprite
                    landscape_gold?: Sprite
                    landscape_red?: Sprite
                    landscape_meter_bg?: Sprite
                    landscape_bell_bg?: Sprite
                    whammy_steal?: Sprite
                    win_backing?: Sprite
                    logo_middle?: Sprite
                    move_arrow?: Sprite
                    move_value?: Sprite
                    tile?: Sprite
                    play?: ButtonElement
                    gold_back?: Sprite
                    portrait_totalwin_bg?: Sprite
                    portrait_bangstick_2?: Sprite
                    portrait_bangstick_1?: Sprite
                    portrait_bell?: Sprite
                    portrait_x2?: Sprite
                    portrait_threshold_bg?: Sprite
                    portrait_glass?: Sprite
                    portrait_gold?: Sprite
                    portrait_red?: Sprite
                    portrait_meter_bg?: Sprite
                    portrait_lights_2?: Sprite
                    portrait_lights_1?: Sprite
                    portrait_bell_bg?: Sprite
                    threshold_multiplier?: Text
                    landscape_total_win_value?: Text
                    landscape_total_win_label?: Text
                    steals_value?: Text
                    steal_title?: Text
                    win_label?: Text
                    win_value?: Text
                    move_text_template?: Text
                    multiplier_value_template?: Text
                    portrait_total_win_label?: Text
                    portrait_total_win_value?: Text
                    lucky_press_logo_low?: Sprite
                    lucky_press_logo_high?: Sprite
                    continue?: ButtonElement
                    body?: Text
                    press_your_luck_logo_low?: Sprite
                    press_your_luck_logo_high?: Sprite
                    spin_a_win_logo_low?: Sprite
                    spin_a_win_logo_high?: Sprite
                    take_your_pick_logo_low?: Sprite
                    take_your_pick_logo_high?: Sprite
                };
            };
             take_your_pick: Scene & {
                contents: {
                    generic?: Sprite
                    bonus_01_whammy_fullscreen?: SpineContainer
                    whammy_0?: AnimatedSprite
                    whammy_1?: AnimatedSprite
                    whammy_2?: AnimatedSprite
                    whammy_3?: AnimatedSprite
                    blank_0?: Sprite
                    blank_1?: Sprite
                    blank_2?: Sprite
                    blank_3?: Sprite
                    pick_0?: ButtonElement
                    pick_1?: ButtonElement
                    pick_2?: ButtonElement
                    pick_3?: ButtonElement
                    frame_frame?: Sprite
                    frame_bg?: Sprite
                    goldiethingy?: Sprite
                    title?: Text
                    prize_value_0?: Text
                    prize_value_1?: Text
                    prize_value_2?: Text
                    prize_value_3?: Text
                    positions: {
                        [key: string]: DualPosition
                        pos0?: DualPosition
                        pos1?: DualPosition
                        pos2?: DualPosition
                        pos3?: DualPosition
                        center?: DualPosition
                    };
                };
            };
             board_game: Scene & {
                contents: {
                    multiplier_bg?: Sprite
                    collect?: ButtonElement
                    landscape_bell?: Sprite
                    landscape_bangstick_1?: Sprite
                    landscape_bangstick_2?: Sprite
                    landscape_x2?: Sprite
                    landscape_lights_1?: Sprite
                    landscape_lights_2?: Sprite
                    landscape_collect_frame?: Sprite
                    landscape_glass?: Sprite
                    landscape_gold?: Sprite
                    landscape_red?: Sprite
                    landscape_meter_bg?: Sprite
                    landscape_bell_bg?: Sprite
                    whammy_steal?: Sprite
                    win_backing?: Sprite
                    logo_middle?: Sprite
                    move_arrow?: Sprite
                    move_value?: Sprite
                    tile?: Sprite
                    play?: ButtonElement
                    frame_frame?: Sprite
                    frame_bg?: Sprite
                    gold_back?: Sprite
                    portrait_totalwin_bg?: Sprite
                    portrait_bangstick_2?: Sprite
                    portrait_bangstick_1?: Sprite
                    portrait_bell?: Sprite
                    portrait_x2?: Sprite
                    portrait_threshold_bg?: Sprite
                    portrait_glass?: Sprite
                    portrait_gold?: Sprite
                    portrait_red?: Sprite
                    portrait_meter_bg?: Sprite
                    portrait_lights_2?: Sprite
                    portrait_lights_1?: Sprite
                    portrait_bell_bg?: Sprite
                    threshold_multiplier?: Text
                    landscape_total_win_value?: Text
                    landscape_total_win_label?: Text
                    steals_value?: Text
                    steal_title?: Text
                    win_label?: Text
                    win_value?: Text
                    move_text_template?: Text
                    multiplier_value_template?: Text
                    portrait_total_win_label?: Text
                    portrait_total_win_value?: Text
                    positions: {
                        [key: string]: DualPosition
                        whammy_portrait$whammy_portrait_5?: DualPosition
                        whammy_portrait$whammy_portrait_4?: DualPosition
                        whammy_portrait$whammy_portrait_3?: DualPosition
                        whammy_portrait$whammy_portrait_2?: DualPosition
                        whammy_portrait$whammy_portrait_1?: DualPosition
                        whammy_portrait$whammy_portrait_0?: DualPosition
                        whammy_1$whammy_5_1?: DualPosition
                        whammy_2$whammy_5_2?: DualPosition
                        whammy_3$whammy_5_3?: DualPosition
                        whammy_heart$whammy_flag_5?: DualPosition
                        whammy_1$whammy_4_1?: DualPosition
                        whammy_2$whammy_4_2?: DualPosition
                        whammy_3$whammy_4_3?: DualPosition
                        whammy_heart$whammy_flag_4?: DualPosition
                        whammy_1$whammy_3_1?: DualPosition
                        whammy_2$whammy_3_2?: DualPosition
                        whammy_3$whammy_3_3?: DualPosition
                        whammy_heart$whammy_flag_3?: DualPosition
                        whammy_1$whammy_2_1?: DualPosition
                        whammy_2$whammy_2_2?: DualPosition
                        whammy_3$whammy_2_3?: DualPosition
                        whammy_heart$whammy_flag_2?: DualPosition
                        whammy_1$whammy_1_1?: DualPosition
                        whammy_2$whammy_1_2?: DualPosition
                        whammy_3$whammy_1_3?: DualPosition
                        whammy_heart$whammy_flag_1?: DualPosition
                        whammy_1$whammy_0_1?: DualPosition
                        whammy_2$whammy_0_2?: DualPosition
                        whammy_3$whammy_0_3?: DualPosition
                        whammy_heart$whammy_flag_0?: DualPosition
                        tile_0?: DualPosition
                        tile_1?: DualPosition
                        tile_2?: DualPosition
                        tile_3?: DualPosition
                        tile_4?: DualPosition
                        tile_5?: DualPosition
                        tile_6?: DualPosition
                        tile_7?: DualPosition
                        tile_8?: DualPosition
                        tile_9?: DualPosition
                        tile_10?: DualPosition
                        tile_11?: DualPosition
                        tile_12?: DualPosition
                        tile_13?: DualPosition
                        tile_14?: DualPosition
                        tile_15?: DualPosition
                        tile_16?: DualPosition
                        tile_17?: DualPosition
                    };
                };
            };
             spin_a_win: Scene & {
                contents: {
                    play?: ButtonElement
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             entry_lucky_press: Scene & {
                contents: {
                    lucky_press_logo_low?: Sprite
                    lucky_press_logo_high?: Sprite
                    continue?: ButtonElement
                    title?: Text
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             entry_press_your_luck: Scene & {
                contents: {
                    press_your_luck_logo_low?: Sprite
                    press_your_luck_logo_high?: Sprite
                    continue?: ButtonElement
                    title?: Text
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             entry_spin_a_win: Scene & {
                contents: {
                    spin_a_win_logo_low?: Sprite
                    spin_a_win_logo_high?: Sprite
                    continue?: ButtonElement
                    title?: Text
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             entry_take_your_pick: Scene & {
                contents: {
                    take_your_pick_logo_low?: Sprite
                    take_your_pick_logo_high?: Sprite
                    continue?: ButtonElement
                    title?: Text
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public BonusBackground: Layers & {
        scene: {
             bonus: Scene & {
                contents: {
                    background_portrait?: Sprite
                    background_landscape?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    background_portrait?: Sprite
                    background_landscape?: Sprite
                };
            };
        };
    };
    public BonusTransitionLogo: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        lucky_press_logo_end?: DualPosition
                        press_your_luck_logo_end?: DualPosition
                        spin_a_win_logo_end?: DualPosition
                        take_your_pick_logo_end?: DualPosition
                        lucky_press_logo_start?: DualPosition
                        press_your_luck_logo_start?: DualPosition
                        spin_a_win_logo_start?: DualPosition
                        take_your_pick_logo_start?: DualPosition
                    };
                    lucky_press_logo_high?: Sprite
                    lucky_press_logo_low?: Sprite
                    press_your_luck_logo_high?: Sprite
                    press_your_luck_logo_low?: Sprite
                    spin_a_win_logo_low?: Sprite
                    spin_a_win_logo_high?: Sprite
                    take_your_pick_logo_low?: Sprite
                    take_your_pick_logo_high?: Sprite
                };
            };
             collect: Scene & {
                contents: {
                    lucky_press_logo_high?: Sprite
                    lucky_press_logo_low?: Sprite
                    press_your_luck_logo_high?: Sprite
                    press_your_luck_logo_low?: Sprite
                    spin_a_win_logo_low?: Sprite
                    spin_a_win_logo_high?: Sprite
                    take_your_pick_logo_low?: Sprite
                    take_your_pick_logo_high?: Sprite
                    positions: {
                        [key: string]: DualPosition
                        lucky_press_logo_end?: DualPosition
                        press_your_luck_logo_end?: DualPosition
                        spin_a_win_logo_end?: DualPosition
                        take_your_pick_logo_end?: DualPosition
                        lucky_press_logo_start?: DualPosition
                        press_your_luck_logo_start?: DualPosition
                        spin_a_win_logo_start?: DualPosition
                        take_your_pick_logo_start?: DualPosition
                    };
                };
            };
        };
    };
    public WhammyAnims: Layers & {
        scene: {
             board_game: Scene & {
                contents: {
                    hitbox?: SmartShape
                    mask?: SmartShape
                    positions: {
                        [key: string]: DualPosition
                        whammy?: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        whammy?: DualPosition
                    };
                    hitbox?: SmartShape
                    mask?: SmartShape
                    bg?: Sprite
                    bonus_intro_portrait?: SpineContainer
                    bonus_intro_landscape?: SpineContainer
                };
            };
             spin_a_win: Scene & {
                contents: {
                    hitbox?: SmartShape
                    mask?: Sprite
                    bg?: Sprite
                    positions: {
                        [key: string]: DualPosition
                        whammy?: DualPosition
                    };
                };
            };
             take_your_pick: Scene & {
                contents: {
                    hitbox?: SmartShape
                    mask?: SmartShape
                    positions: {
                        [key: string]: DualPosition
                        whammy?: DualPosition
                    };
                };
            };
             default: Scene & {
                contents: {
                    bonus_intro_portrait?: SpineContainer
                    bonus_intro_landscape?: SpineContainer
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public MenuButton: Layers & {
        scene: {
             bonus: Scene & {
                contents: {
                    ui_settings_menu?: ButtonElement
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    ui_settings_menu?: ButtonElement
                };
            };
             base: Scene & {
                contents: {
                    ui_settings_menu?: ButtonElement
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public CelebrationValue: Layers & {
        scene: {
             win: Scene & {
                contents: {
                    x2?: Text
                    value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    x2?: Text
                    value?: Text
                };
            };
        };
    };
    public CelebrationForeground: Layers & {
        scene: {
             win: Scene & {
                contents: {
                    press_your_luck_logo_high?: Sprite
                    lucky_press_logo_high?: Sprite
                    take_your_pick_logo_high?: Sprite
                    spin_a_win_logo_high?: Sprite
                    press_your_luck_logo_low?: Sprite
                    lucky_press_logo_low?: Sprite
                    take_your_pick_logo_low?: Sprite
                    spin_a_win_logo_low?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    press_your_luck_logo_high?: Sprite
                    lucky_press_logo_high?: Sprite
                    take_your_pick_logo_high?: Sprite
                    spin_a_win_logo_high?: Sprite
                    press_your_luck_logo_low?: Sprite
                    lucky_press_logo_low?: Sprite
                    take_your_pick_logo_low?: Sprite
                    spin_a_win_logo_low?: Sprite
                };
            };
        };
    };
    public CelebrationContent: Layers & {
        scene: {
             fullhouse: Scene & {
                contents: {
                    full_house?: SpineContainer
                    clickblocker?: SmartShape
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    full_house?: SpineContainer
                    clickblocker?: SmartShape
                    title?: Text
                    value?: Text
                    celebrations?: SpineContainer
                    slingo_spine?: SpineContainer
                };
            };
             win: Scene & {
                contents: {
                    title?: Text
                    value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             bigwin: Scene & {
                contents: {
                    celebrations?: SpineContainer
                    value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             megawin: Scene & {
                contents: {
                    celebrations?: SpineContainer
                    value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             slingo: Scene & {
                contents: {
                    slingo_spine?: SpineContainer
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public CelebrationBackground: Layers & {
        scene: {
             win: Scene & {
                contents: {
                    clickblocker?: SmartShape
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    clickblocker?: SmartShape
                    coin?: Sprite
                    morecoin?: Sprite
                };
            };
             bigwin: Scene & {
                contents: {
                    coin?: Sprite
                    clickblocker?: SmartShape
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             megawin: Scene & {
                contents: {
                    morecoin?: Sprite
                    clickblocker?: SmartShape
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public BetBar: Layers & {
        scene: {
             bonus: Scene & {
                contents: {
                    bg_top?: SmartShape
                    bg_bottom?: SmartShape
                    gameId?: Text
                    time?: Text
                    balance_label?: Text
                    balance_value?: Text
                    slingo_stake?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    bg_top?: SmartShape
                    bg_bottom?: SmartShape
                    gameId?: Text
                    time?: Text
                    balance_label?: Text
                    balance_value?: Text
                    slingo_stake?: Text
                    ui_stake_menu?: ButtonElement
                };
            };
             base: Scene & {
                contents: {
                    ui_stake_menu?: ButtonElement
                    bg_top?: SmartShape
                    bg_bottom?: SmartShape
                    gameId?: Text
                    time?: Text
                    balance_label?: Text
                    balance_value?: Text
                    slingo_stake?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public Collect: Layers & {
        scene: {
             collect: Scene & {
                contents: {
                    purchase?: ButtonElement
                    collect_bonus?: ButtonElement
                    collect?: ButtonElement
                    spin_a_win_logo_high?: Sprite
                    spin_a_win_logo_low?: Sprite
                    press_your_luck_logo_high?: Sprite
                    press_your_luck_logo_low?: Sprite
                    lucky_press_logo_low?: Sprite
                    lucky_press_logo_high?: Sprite
                    take_your_pick_logo_low?: Sprite
                    take_your_pick_logo_high?: Sprite
                    potential_win_frame?: Sprite
                    footer?: Text
                    title?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    purchase?: ButtonElement
                    collect_bonus?: ButtonElement
                    collect?: ButtonElement
                    spin_a_win_logo_high?: Sprite
                    spin_a_win_logo_low?: Sprite
                    press_your_luck_logo_high?: Sprite
                    press_your_luck_logo_low?: Sprite
                    lucky_press_logo_low?: Sprite
                    lucky_press_logo_high?: Sprite
                    take_your_pick_logo_low?: Sprite
                    take_your_pick_logo_high?: Sprite
                    potential_win_frame?: Sprite
                    footer?: Text
                    title?: Text
                };
            };
        };
    };
    public SpinCounter: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    spins_remaining_bg?: Sprite
                    spins_remaining_value?: Text
                    positions: {
                        [key: string]: DualPosition
                        sparkStart_0?: DualPosition
                        sparkStart_1?: DualPosition
                        sparkStart_2?: DualPosition
                        sparkStart_3?: DualPosition
                        sparkStart_4?: DualPosition
                        sparkMidPoint?: DualPosition
                        sparkTarget?: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        sparkStart_0?: DualPosition
                        sparkStart_1?: DualPosition
                        sparkStart_2?: DualPosition
                        sparkStart_3?: DualPosition
                        sparkStart_4?: DualPosition
                        sparkMidPoint?: DualPosition
                        sparkTarget?: DualPosition
                    };
                    spins_remaining_bg?: Sprite
                    spins_remaining_value?: Text
                };
            };
        };
    };
    public Logo: Layers & {
        scene: {
             lucky_press_high: Scene & {
                contents: {
                    logo?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    logo?: Sprite
                    press_your_luck_logo_high?: Sprite
                    spin_a_win_logo_high?: Sprite
                    take_your_pick_logo_high?: Sprite
                    lucky_press_logo_low?: Sprite
                    press_your_luck_logo_low?: Sprite
                    spin_a_win_logo_low?: Sprite
                    take_your_pick_logo_low?: Sprite
                    slingo?: Sprite
                };
            };
             press_your_luck_high: Scene & {
                contents: {
                    press_your_luck_logo_high?: Sprite
                    logo?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             spin_a_win_high: Scene & {
                contents: {
                    spin_a_win_logo_high?: Sprite
                    logo?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             take_your_pick_high: Scene & {
                contents: {
                    take_your_pick_logo_high?: Sprite
                    logo?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             lucky_press_low: Scene & {
                contents: {
                    lucky_press_logo_low?: Sprite
                    logo?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             press_your_luck_low: Scene & {
                contents: {
                    press_your_luck_logo_low?: Sprite
                    logo?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             spin_a_win_low: Scene & {
                contents: {
                    spin_a_win_logo_low?: Sprite
                    logo?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             take_your_pick_low: Scene & {
                contents: {
                    take_your_pick_logo_low?: Sprite
                    logo?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             default: Scene & {
                contents: {
                    logo?: Sprite
                    slingo?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public Footer: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    value?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    value?: Text
                };
            };
        };
    };
    public SpinButton: Layers & {
        scene: {
             base: Scene & {
                contents: {
                    spin?: ButtonElement
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    spin?: ButtonElement
                };
            };
        };
    };
    public TicketMatrix: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        dab?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_0_1?: DualPosition
                        symbol_0_2?: DualPosition
                        symbol_0_3?: DualPosition
                        symbol_0_4?: DualPosition
                        symbol_1_0?: DualPosition
                        symbol_1_1?: DualPosition
                        symbol_1_2?: DualPosition
                        symbol_1_3?: DualPosition
                        symbol_1_4?: DualPosition
                        symbol_2_0?: DualPosition
                        symbol_2_1?: DualPosition
                        symbol_2_2?: DualPosition
                        symbol_2_3?: DualPosition
                        symbol_2_4?: DualPosition
                        symbol_3_0?: DualPosition
                        symbol_3_1?: DualPosition
                        symbol_3_2?: DualPosition
                        symbol_3_3?: DualPosition
                        symbol_3_4?: DualPosition
                        symbol_4_0?: DualPosition
                        symbol_4_1?: DualPosition
                        symbol_4_2?: DualPosition
                        symbol_4_3?: DualPosition
                        symbol_4_4?: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        dab?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_0_1?: DualPosition
                        symbol_0_2?: DualPosition
                        symbol_0_3?: DualPosition
                        symbol_0_4?: DualPosition
                        symbol_1_0?: DualPosition
                        symbol_1_1?: DualPosition
                        symbol_1_2?: DualPosition
                        symbol_1_3?: DualPosition
                        symbol_1_4?: DualPosition
                        symbol_2_0?: DualPosition
                        symbol_2_1?: DualPosition
                        symbol_2_2?: DualPosition
                        symbol_2_3?: DualPosition
                        symbol_2_4?: DualPosition
                        symbol_3_0?: DualPosition
                        symbol_3_1?: DualPosition
                        symbol_3_2?: DualPosition
                        symbol_3_3?: DualPosition
                        symbol_3_4?: DualPosition
                        symbol_4_0?: DualPosition
                        symbol_4_1?: DualPosition
                        symbol_4_2?: DualPosition
                        symbol_4_3?: DualPosition
                        symbol_4_4?: DualPosition
                    };
                };
            };
        };
    };
    public TicketMatrixBacking: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    grid?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    grid?: Sprite
                };
            };
        };
    };
    public SlingoLadder: Layers & {
        scene: {
             paytable: Scene & {
                contents: {
                    glitter_mask?: Sprite
                    level_12_logo?: Sprite
                    level_10_logo?: Sprite
                    level_9_logo?: Sprite
                    level_8_logo?: Sprite
                    level_7_logo?: Sprite
                    level_6_logo?: Sprite
                    level_5_logo?: Sprite
                    level_4_logo?: Sprite
                    ladder_count_4?: Sprite
                    ladder_count_5?: Sprite
                    ladder_count_6?: Sprite
                    ladder_count_7?: Sprite
                    ladder_count_8?: Sprite
                    ladder_count_9?: Sprite
                    ladder_count_10?: Sprite
                    ladder_count_12?: Sprite
                    level_12?: Sprite
                    level_10?: Sprite
                    level_9?: Sprite
                    level_8?: Sprite
                    level_7?: Sprite
                    level_6?: Sprite
                    level_5?: Sprite
                    level_4?: Sprite
                    ladder_glittter?: Sprite
                    ladder_frame?: Sprite
                    ladder_whammy_landscape?: SpineContainer
                    level_3?: Sprite
                    level_2?: Sprite
                    level_1?: Sprite
                    ladder_dividers?: Sprite
                    landscape_colors?: Sprite
                    gradients?: Sprite
                    ladder_bg?: Sprite
                    reflection_OFF?: Sprite
                    reflection_ON?: Sprite
                    pre_ladder_glitter?: Sprite
                    pre_ladder_frame?: Sprite
                    pre_ladder_dividers?: Sprite
                    pre_ladder_bg?: Sprite
                    cover?: SmartShape
                    ladder_text_4?: Text
                    ladder_text_5?: Text
                    ladder_text_6?: Text
                    ladder_text_7?: Text
                    ladder_text_8?: Text
                    ladder_text_9?: Text
                    ladder_text_10?: Text
                    ladder_text_12?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    glitter_mask?: Sprite
                    level_12_logo?: Sprite
                    level_10_logo?: Sprite
                    level_9_logo?: Sprite
                    level_8_logo?: Sprite
                    level_7_logo?: Sprite
                    level_6_logo?: Sprite
                    level_5_logo?: Sprite
                    level_4_logo?: Sprite
                    ladder_count_4?: Sprite
                    ladder_count_5?: Sprite
                    ladder_count_6?: Sprite
                    ladder_count_7?: Sprite
                    ladder_count_8?: Sprite
                    ladder_count_9?: Sprite
                    ladder_count_10?: Sprite
                    ladder_count_12?: Sprite
                    level_12?: Sprite
                    level_10?: Sprite
                    level_9?: Sprite
                    level_8?: Sprite
                    level_7?: Sprite
                    level_6?: Sprite
                    level_5?: Sprite
                    level_4?: Sprite
                    ladder_glittter?: Sprite
                    ladder_frame?: Sprite
                    ladder_whammy_landscape?: SpineContainer
                    level_3?: Sprite
                    level_2?: Sprite
                    level_1?: Sprite
                    ladder_dividers?: Sprite
                    landscape_colors?: Sprite
                    gradients?: Sprite
                    ladder_bg?: Sprite
                    reflection_OFF?: Sprite
                    reflection_ON?: Sprite
                    pre_ladder_glitter?: Sprite
                    pre_ladder_frame?: Sprite
                    pre_ladder_dividers?: Sprite
                    pre_ladder_bg?: Sprite
                    cover?: SmartShape
                    ladder_text_4?: Text
                    ladder_text_5?: Text
                    ladder_text_6?: Text
                    ladder_text_7?: Text
                    ladder_text_8?: Text
                    ladder_text_9?: Text
                    ladder_text_10?: Text
                    ladder_text_12?: Text
                    ui_ladder?: ButtonElement
                    portrait_glitter_mask?: Sprite
                    portrait_ladder_glittter?: Sprite
                    portrait_ladder_frame?: Sprite
                    ladder_whammy_portrait?: SpineContainer
                    portrait_ladder_dividers?: Sprite
                    level_12_portrait?: Sprite
                    level_10_portrait?: Sprite
                    level_9_portrait?: Sprite
                    level_8_portrait?: Sprite
                    level_7_portrait?: Sprite
                    level_6_portrait?: Sprite
                    level_5_portrait?: Sprite
                    level_4_portrait?: Sprite
                    portrait_colors?: Sprite
                    portrait_gradients?: Sprite
                    portrait_ladder_bg?: Sprite
                    portrait_pre_ladder_glitter?: Sprite
                    portrait_pre_ladder_frame?: Sprite
                    portrait_pre_ladder_dividers?: Sprite
                    level_3_portrait?: Sprite
                    level_2_portrait?: Sprite
                    level_1_portrait?: Sprite
                    portrait_pre_ladder_bg?: Sprite
                };
            };
             base: Scene & {
                contents: {
                    ui_ladder?: ButtonElement
                    level_12_logo?: Sprite
                    level_10_logo?: Sprite
                    level_9_logo?: Sprite
                    level_8_logo?: Sprite
                    level_7_logo?: Sprite
                    level_6_logo?: Sprite
                    level_5_logo?: Sprite
                    level_4_logo?: Sprite
                    portrait_glitter_mask?: Sprite
                    ladder_count_4?: Sprite
                    ladder_count_5?: Sprite
                    ladder_count_6?: Sprite
                    ladder_count_7?: Sprite
                    ladder_count_8?: Sprite
                    ladder_count_9?: Sprite
                    ladder_count_10?: Sprite
                    ladder_count_12?: Sprite
                    portrait_ladder_glittter?: Sprite
                    portrait_ladder_frame?: Sprite
                    ladder_whammy_portrait?: SpineContainer
                    portrait_ladder_dividers?: Sprite
                    level_12_portrait?: Sprite
                    level_10_portrait?: Sprite
                    level_9_portrait?: Sprite
                    level_8_portrait?: Sprite
                    level_7_portrait?: Sprite
                    level_6_portrait?: Sprite
                    level_5_portrait?: Sprite
                    level_4_portrait?: Sprite
                    portrait_colors?: Sprite
                    portrait_gradients?: Sprite
                    portrait_ladder_bg?: Sprite
                    portrait_pre_ladder_glitter?: Sprite
                    portrait_pre_ladder_frame?: Sprite
                    portrait_pre_ladder_dividers?: Sprite
                    level_3_portrait?: Sprite
                    level_2_portrait?: Sprite
                    level_1_portrait?: Sprite
                    portrait_pre_ladder_bg?: Sprite
                    glitter_mask?: Sprite
                    level_12?: Sprite
                    level_10?: Sprite
                    level_9?: Sprite
                    level_8?: Sprite
                    level_7?: Sprite
                    level_6?: Sprite
                    level_5?: Sprite
                    level_4?: Sprite
                    ladder_glittter?: Sprite
                    ladder_frame?: Sprite
                    ladder_whammy_landscape?: SpineContainer
                    level_3?: Sprite
                    level_2?: Sprite
                    level_1?: Sprite
                    ladder_dividers?: Sprite
                    landscape_colors?: Sprite
                    gradients?: Sprite
                    ladder_bg?: Sprite
                    reflection_OFF?: Sprite
                    reflection_ON?: Sprite
                    pre_ladder_glitter?: Sprite
                    pre_ladder_frame?: Sprite
                    pre_ladder_dividers?: Sprite
                    pre_ladder_bg?: Sprite
                    ladder_text_4?: Text
                    ladder_text_5?: Text
                    ladder_text_6?: Text
                    ladder_text_7?: Text
                    ladder_text_8?: Text
                    ladder_text_9?: Text
                    ladder_text_10?: Text
                    ladder_text_12?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public MatrixContent: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        whammy_1_win?: DualPosition
                        whammy_2_win?: DualPosition
                        whammy_3_win?: DualPosition
                        land?: DualPosition
                        static?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_1_0?: DualPosition
                        symbol_2_0?: DualPosition
                        symbol_3_0?: DualPosition
                        symbol_4_0?: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        whammy_1_win?: DualPosition
                        whammy_2_win?: DualPosition
                        whammy_3_win?: DualPosition
                        land?: DualPosition
                        static?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_1_0?: DualPosition
                        symbol_2_0?: DualPosition
                        symbol_3_0?: DualPosition
                        symbol_4_0?: DualPosition
                    };
                };
            };
        };
    };
    public SymbolAnimations: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public MatrixBackground: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    reels?: Sprite
                    reflection?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    reels?: Sprite
                    reflection?: Sprite
                };
            };
        };
    };
    public Background: Layers & {
        scene: {
             onboard: Scene & {
                contents: {
                    background_portrait?: Sprite
                    background_landscape?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    background_portrait?: Sprite
                    background_landscape?: Sprite
                };
            };
             default: Scene & {
                contents: {
                    background_portrait?: Sprite
                    background_landscape?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public Onboard: Layers & {
        scene: {
             onboard: Scene & {
                contents: {
                    bar?: SmartShape
                    whammy_onboard?: SpineContainer
                    load_bar_mask?: SmartShape
                    load_bar?: Sprite
                    bar_backing?: Sprite
                    continue?: ButtonElement
                    logo?: Sprite
                    portrait_slingo?: Sprite
                    landscape_slingo?: Sprite
                    board?: Sprite
                    whammy_1?: SpineContainer
                    text_1?: Text
                    text_2?: Text
                    legal?: Text
                    version?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    bar?: SmartShape
                    whammy_onboard?: SpineContainer
                    load_bar_mask?: SmartShape
                    load_bar?: Sprite
                    bar_backing?: Sprite
                    continue?: ButtonElement
                    logo?: Sprite
                    portrait_slingo?: Sprite
                    landscape_slingo?: Sprite
                    board?: Sprite
                    whammy_1?: SpineContainer
                    text_1?: Text
                    text_2?: Text
                    legal?: Text
                    version?: Text
                };
            };
        };
    };
    public Prompts: Layers & {
        scene: {
             bonusmoney: Scene & {
                contents: {
                    tc?: ButtonElement
                    ok?: ButtonElement
                    frame?: Sprite
                    cover?: SmartShape
                    title?: Text
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    tc?: ButtonElement
                    ok?: ButtonElement
                    frame?: Sprite
                    cover?: SmartShape
                    title?: Text
                    body?: Text
                    whammy?: SpineContainer
                    total_win?: Text
                    no?: ButtonElement
                    yes?: ButtonElement
                    continuenooptions?: ButtonElement
                    continue?: ButtonElement
                    history?: ButtonElement
                    logout?: ButtonElement
                };
            };
             gamesummary_nowin: Scene & {
                contents: {
                    whammy?: SpineContainer
                    frame?: Sprite
                    cover?: SmartShape
                    title?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             gamesummary_win: Scene & {
                contents: {
                    whammy?: SpineContainer
                    frame?: Sprite
                    cover?: SmartShape
                    title?: Text
                    total_win?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             yes_no: Scene & {
                contents: {
                    no?: ButtonElement
                    yes?: ButtonElement
                    frame?: Sprite
                    cover?: SmartShape
                    title?: Text
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             realitycheck: Scene & {
                contents: {
                    continuenooptions?: ButtonElement
                    continue?: ButtonElement
                    history?: ButtonElement
                    logout?: ButtonElement
                    frame?: Sprite
                    cover?: SmartShape
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
             info: Scene & {
                contents: {
                    continue?: ButtonElement
                    frame?: Sprite
                    cover?: SmartShape
                    title?: Text
                    body?: Text
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public SpinAWinParticles: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
        };
    };
    public SpinAWinMatrixForeground: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    ticker_0_left?: Sprite
                    ticker_0_right?: Sprite
                    ticker_1_left?: Sprite
                    ticker_1_right?: Sprite
                    selector_bottom?: Sprite
                    bottom_frame?: Sprite
                    right_frame?: Sprite
                    left_frame?: Sprite
                    fade_1?: Sprite
                    fade_2?: Sprite
                    fade_3?: Sprite
                    shadow?: Sprite
                    mask_0?: Sprite
                    mask_1?: Sprite
                    mask_2?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    ticker_0_left?: Sprite
                    ticker_0_right?: Sprite
                    ticker_1_left?: Sprite
                    ticker_1_right?: Sprite
                    selector_bottom?: Sprite
                    bottom_frame?: Sprite
                    right_frame?: Sprite
                    left_frame?: Sprite
                    fade_1?: Sprite
                    fade_2?: Sprite
                    fade_3?: Sprite
                    shadow?: Sprite
                    mask_0?: Sprite
                    mask_1?: Sprite
                    mask_2?: Sprite
                };
            };
        };
    };
    public SpinAWinAnimations: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    mask?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    mask?: Sprite
                };
            };
        };
    };
    public SpinAWinMatrixContent0: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    multiplier_value_template?: Text
                    positions: {
                        [key: string]: DualPosition
                        static?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_0_1?: DualPosition
                        symbol_0_2?: DualPosition
                        symbol_0_3?: DualPosition
                        symbol_0_4?: DualPosition
                        symbol_0_5?: DualPosition
                        symbol_0_6?: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        static?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_0_1?: DualPosition
                        symbol_0_2?: DualPosition
                        symbol_0_3?: DualPosition
                        symbol_0_4?: DualPosition
                        symbol_0_5?: DualPosition
                        symbol_0_6?: DualPosition
                    };
                    multiplier_value_template?: Text
                };
            };
        };
    };
    public SpinAWinMatrixContent1: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        static?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_0_1?: DualPosition
                        symbol_0_2?: DualPosition
                        symbol_0_3?: DualPosition
                        symbol_0_4?: DualPosition
                        symbol_0_5?: DualPosition
                        symbol_0_6?: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        static?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_0_1?: DualPosition
                        symbol_0_2?: DualPosition
                        symbol_0_3?: DualPosition
                        symbol_0_4?: DualPosition
                        symbol_0_5?: DualPosition
                        symbol_0_6?: DualPosition
                    };
                };
            };
        };
    };
    public SpinAWinMatrixContent2: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    multiplier_value_template?: Text
                    positions: {
                        [key: string]: DualPosition
                        whammy_1_win?: DualPosition
                        whammy_2_win?: DualPosition
                        whammy_3_win?: DualPosition
                        static?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_0_1?: DualPosition
                        symbol_0_2?: DualPosition
                        symbol_0_3?: DualPosition
                        symbol_0_4?: DualPosition
                        symbol_0_5?: DualPosition
                        symbol_0_6?: DualPosition
                        symbol_0_7?: DualPosition
                        symbol_0_8?: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                        whammy_1_win?: DualPosition
                        whammy_2_win?: DualPosition
                        whammy_3_win?: DualPosition
                        static?: DualPosition
                        symbol_0_0?: DualPosition
                        symbol_0_1?: DualPosition
                        symbol_0_2?: DualPosition
                        symbol_0_3?: DualPosition
                        symbol_0_4?: DualPosition
                        symbol_0_5?: DualPosition
                        symbol_0_6?: DualPosition
                        symbol_0_7?: DualPosition
                        symbol_0_8?: DualPosition
                    };
                    multiplier_value_template?: Text
                };
            };
        };
    };
    public SpinAWinMatrixBackground: Layers & {
        scene: {
             default: Scene & {
                contents: {
                    bottom_bg?: Sprite
                    right_bg?: Sprite
                    left_bg?: Sprite
                    connector?: Sprite
                    gold?: Sprite
                    positions: {
                        [key: string]: DualPosition
                    };
                };
            };
            active: {
                contents: {
                    positions: {
                        [key: string]: DualPosition
                    };
                    bottom_bg?: Sprite
                    right_bg?: Sprite
                    left_bg?: Sprite
                    connector?: Sprite
                    gold?: Sprite
                };
            };
        };
    };
}

export enum LayerNames {
    Menus = "menus",
    SuperSpinWheel = "superspinwheel",
    Debug = "debug",
    BonusUI = "bonusui",
    Bonus = "bonus",
    BonusBackground = "bonusbackground",
    BonusTransitionLogo = "bonustransitionlogo",
    WhammyAnims = "whammyanims",
    MenuButton = "menubutton",
    CelebrationValue = "celebrationvalue",
    CelebrationForeground = "celebrationforeground",
    CelebrationContent = "celebrationcontent",
    CelebrationBackground = "celebrationbackground",
    BetBar = "betbar",
    Collect = "collect",
    SpinCounter = "spincounter",
    Logo = "logo",
    Footer = "footer",
    SpinButton = "spinbutton",
    TicketMatrix = "ticketmatrix",
    TicketMatrixBacking = "ticketmatrixbacking",
    SlingoLadder = "slingoladder",
    MatrixContent = "matrixcontent",
    SymbolAnimations = "symbolanimations",
    MatrixBackground = "matrixbackground",
    Background = "background",
    Onboard = "onboard",
    Prompts = "prompts",
    SpinAWinParticles = "spinawinparticles",
    SpinAWinMatrixForeground = "spinawinmatrixforeground",
    SpinAWinAnimations = "spinawinanimations",
    SpinAWinMatrixContent0 = "spinawinmatrixcontent0",
    SpinAWinMatrixContent1 = "spinawinmatrixcontent1",
    SpinAWinMatrixContent2 = "spinawinmatrixcontent2",
    SpinAWinMatrixBackground = "spinawinmatrixbackground"
}

export const gameLayers = new GameLayers();
export const assetworksData = {gameLayers, LayerNames, SoundNames};
