import { Layers } from "appworks/graphics/layers/layers";
import { DualPosition } from "appworks/graphics/pixi/dual-position";
import { HTMLText } from "appworks/graphics/pixi/html-text";
import { Text } from "appworks/graphics/pixi/text";
import { gameState } from "appworks/model/game-state";
import { CurrencyService } from "appworks/services/currency/currency-service";
import { Services } from "appworks/services/services";
import { pulse } from "appworks/utils/animation/scale";
import { lastInArray } from "appworks/utils/collection-utils";
import { Point } from "appworks/utils/geom/point";
import { fakeSpinAWinPrizes } from "components/pyl-spin-a-win-bonus-component";
import { gameLayers } from "game-layers";
import { PYLBonusResult } from "model/results/pyl-bonus-result.ts";
import { SymbolBehaviourMethod } from "slotworks/components/matrix/symbol/behaviours/symbol-behaviour-driver";
import { SymbolSubcomponent } from "slotworks/components/matrix/symbol/symbol-subcomponent";
import { slotDefinition } from "slotworks/model/slot-definition";
import { SlotBetService } from "slotworks/services/bet/slot-bet-service";
import { getMultiplierTint } from "utils/pyl-tint";

export class PYLSpinAWinSymbolSubcomponent extends SymbolSubcomponent {
    public text: Text;
    protected value: number;
    protected wheelIndex: 0 | 1 | 2;
    protected staticId: string;

    constructor(gridPosition: Point, matrixLayer: Layers, animationLayer: Layers) {
        super(gridPosition, matrixLayer, animationLayer);

        this.wheelIndex = matrixLayer === gameLayers.SpinAWinMatrixContent0 ? 0 : matrixLayer === gameLayers.SpinAWinMatrixContent1 ? 1 : 2;

        this.text = this.createText();
        this.staticSprite.addChild(this.text);
        
        this.text.landscape.x = this.text.landscape.width * -0.5;
        this.text.landscape.y = this.text.landscape.height * -0.5;
        this.text.portrait.x = this.text.portrait.width * -0.5;
        this.text.portrait.y = this.text.portrait.height * -0.5;
    }

    public setSymbol(id: string): void {
        const prefix = this.wheelIndex === 2 && !id.includes("whammy") ? "bottom" : "";
        if (this.wheelIndex === 2 && !id.startsWith(prefix)) {
            id = prefix + id;
        }

        if (id.includes("whammy")) {
            this.value = undefined;
        } else if (id.includes("_")) {
            this.value = parseFloat(id.split("_")[1]);
        }

        super.setSymbol(id);
    }

    protected updateStatic(transform: DualPosition): void {
        super.updateStatic(transform);

        if (this.value) {
            const record = gameState.getCurrentGame().getCurrentRecord();
            const result = record.getFirstResultOfType(PYLBonusResult);

            let maxMult = 20;
            if (record && result) {
                const fakeMultipliers = fakeSpinAWinPrizes[`wheel${this.wheelIndex + 1}`][result.variant];
                maxMult = lastInArray(fakeMultipliers);
            }

            if (this.wheelIndex === 2) {
                this.text.text = `x${this.value}`;
                this.staticSprite.tint = getMultiplierTint(this.value, maxMult);
            } else {
                const cashValue = Services.get(CurrencyService).format(this.value, false);
                this.text.text = cashValue;
                this.staticSprite.tint = getMultiplierTint(this.value / Services.get(SlotBetService).getTotalStake(), maxMult);
            }
            this.staticSprite.addChild(this.text)
        } else {
            this.text.text = "";
            this.staticSprite.tint = 0xFFFFFF;
        }
    }

    protected createText(): Text {
        const textTemplate = this.wheelIndex === 2 ? gameLayers.SpinAWinMatrixContent2.scene.default.contents.multiplier_value_template : gameLayers.SpinAWinMatrixContent0.scene.default.contents.multiplier_value_template;

        const text = textTemplate.clone();

        text.autoShrink = false;

        return text;
    }
}