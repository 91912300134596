import { builderManager } from "appworks/server/builders/builder-manager";
import { GMRCollectRequestBuilder } from "gaming-realms/integration/builders/requests/gmr-collect-request-builder";
import { GMRForceRequestBuilder } from "gaming-realms/integration/builders/requests/gmr-force-request-builder";
import { GMRInitRequestBuilder } from "gaming-realms/integration/builders/requests/gmr-init-request-builder";
import { PYLBonusResultBuilder } from "integration/builders/results/pyl-bonus-result-builder";
import { SlingoCollectRecordBuilder } from "slingo/integration/builders/records/slingo-collect-record-builder";
import { SlingoInitRecordBuilder } from "slingo/integration/builders/records/slingo-init-record-builder";
import { SlingoPickRecordBuilder } from "slingo/integration/builders/records/slingo-pick-record-builder";
import { SlingoPurchaseEntryRecordBuilder } from "slingo/integration/builders/records/slingo-purchase-entry-record-builder";
import { SlingoSpinRecordBuilder } from "slingo/integration/builders/records/slingo-spin-record-builder";
import { SlingoPickRequestBuilder } from "slingo/integration/builders/requests/slingo-pick-request-builder";
import { SlingoPurchaseEntryRequestBuilder } from "slingo/integration/builders/requests/slingo-purchase-entry-request-builder";
import { SlingoSpinRequestBuilder } from "slingo/integration/builders/requests/slingo-spin-request-builder";
import { SlingoGameProgressResultBuilder } from "slingo/integration/builders/results/slingo-game-progress-result-builder";
import { SlingoJokerResultBuilder } from "slingo/integration/builders/results/slingo-joker-result-builder";
import { SlingoLadderResultBuilder } from "slingo/integration/builders/results/slingo-ladder-result-builder";
import { PYLBonusRecordBuilder } from "integration/builders/records/pyl-bonus-record-builder";
import { GMRFeaturePickRequestBuilder } from "gaming-realms/integration/builders/requests/gmr-feature-pick-request-builder";
import { SlingoReelSpinResult } from "slingo/model/results/slingo-reel-spin-result";
import { SlingoReelSpinResultBuilder } from "slingo/integration/builders/results/slingo-reel-spin-result-builder";
import { PYLReelSpinResultBuilder } from "integration/builders/results/pyl-reel-spin-result-builder";
import { PYLInitRecordBuilder } from "integration/builders/records/pyl-init-record-builder";
import { SlingoStakeToSpinRequestBuilder } from "slingo/integration/builders/requests/slingo-stake-to-spin-request-builder";
import { SlingoStakeToSpinRecordBuilder } from "slingo/integration/builders/records/slingo-stake-to-spin-record-builder";
import { SlingoStakeToSpinResultBuilder } from "slingo/integration/builders/results/slingo-stake-to-spin-result-builder";

export function PYLCommsSetup() {
    builderManager.addRequestBuilder(new GMRForceRequestBuilder());
    builderManager.addRequestBuilder(new GMRInitRequestBuilder());
    builderManager.addRequestBuilder(new SlingoPurchaseEntryRequestBuilder());
    builderManager.addRequestBuilder(new SlingoSpinRequestBuilder());
    builderManager.addRequestBuilder(new SlingoPickRequestBuilder());
    builderManager.addRequestBuilder(new GMRCollectRequestBuilder());
    builderManager.addRequestBuilder(new GMRFeaturePickRequestBuilder());
    builderManager.addRequestBuilder(new SlingoStakeToSpinRequestBuilder());

    builderManager.addRecordBuilder(new PYLInitRecordBuilder());
    builderManager.addRecordBuilder(new SlingoPurchaseEntryRecordBuilder());
    builderManager.addRecordBuilder(new SlingoSpinRecordBuilder());
    builderManager.addRecordBuilder(new SlingoPickRecordBuilder());
    builderManager.addRecordBuilder(new SlingoCollectRecordBuilder());
    builderManager.addRecordBuilder(new PYLBonusRecordBuilder());
    builderManager.addRecordBuilder(new SlingoStakeToSpinRecordBuilder());

    builderManager.addResultBuilder(new PYLReelSpinResultBuilder());
    builderManager.addResultBuilder(new SlingoJokerResultBuilder());
    builderManager.addResultBuilder(new SlingoLadderResultBuilder());
    builderManager.addResultBuilder(new SlingoGameProgressResultBuilder());
    builderManager.addResultBuilder(new PYLBonusResultBuilder());
    builderManager.addResultBuilder(new SlingoStakeToSpinResultBuilder());
}