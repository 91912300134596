import { Components } from "appworks/components/components";
import { PIXIElement } from "appworks/graphics/pixi/group";
import { Contract } from "appworks/utils/contracts/contract";
import { Parallel } from "appworks/utils/contracts/parallel";
import { Sequence } from "appworks/utils/contracts/sequence";
import { Easing, Tween } from "appworks/utils/tween";
import { SlingoCelebrationComponent } from "slingo/components/slingo-celebration-component";
import { SlingoHighlightType, SlingoTicketMatrixComponent } from "slingo/components/slingo-ticket-matrix-component";

export class PYLTicketMatrixComponent extends SlingoTicketMatrixComponent {
    protected highlightTweens: Tween[] = [];

    public checkWinlines(showCelebration: boolean = true): Contract {
        const newMatches: string[] = [];
        this.winlines.forEach((matched, pattern) => {
            if (!matched && this.checkWinline(pattern)) {
                newMatches.push(pattern);
                this.winlines.set(pattern, true);
            }
        });

        if (showCelebration && newMatches.length) {
            const contractType = this.config.parallelCelebration ? Parallel : Sequence;

            return new Sequence([
                () => new contractType([
                    ...newMatches.map(pattern => () => this.animateLine(pattern))
                ]),
                () => Components.get(SlingoCelebrationComponent).showCelebration(newMatches.length),
            ]);
        } else {
            return Contract.empty();
        }
    }

    protected getHighlightAnimationContract(highlight: PIXIElement, highlightType: SlingoHighlightType): Contract<void> {
        if (highlightType === SlingoHighlightType.MATCHED) {
            return super.getHighlightAnimationContract(highlight, highlightType);
        }

        highlight.alpha = 0.25;
        this.highlightTweens.push(new Tween(highlight)
            .to({ alpha: 1 }, 500)
            .easing(Easing.Sinusoidal.InOut)
            .yoyo(true)
            .repeat(Number.MAX_SAFE_INTEGER)
            .start()
        );

        return Contract.getTimeoutContract(500);
    }

    public clearHighlights(): Contract<void> {
        this.highlightTweens.forEach((tween) => tween.stop());
        this.highlightTweens = [];
        return super.clearHighlights();
    }

    protected getFullHouseAnimationPatterns(): number[][][] {
        return [
            [
                [0, 1, 2, 3, 4],
                [1, 2, 3, 4, 5],
                [2, 3, 4, 5, 6],
                [3, 4, 5, 6, 7],
                [4, 5, 6, 7, 8],
            ],
            [
                [8, 7, 6, 5, 4],
                [7, 6, 5, 4, 3],
                [6, 5, 4, 3, 2],
                [5, 4, 3, 2, 1],
                [4, 3, 2, 1, 0],
            ],
            [
                [0, 0, 0, 0, 0],
                [0, 1, 1, 1, 0],
                [0, 1, 2, 1, 0],
                [0, 1, 1, 1, 0],
                [0, 0, 0, 0, 0],
            ],
            [
                [2, 2, 2, 2, 2],
                [2, 1, 1, 1, 2],
                [2, 1, 0, 1, 2],
                [2, 1, 1, 1, 2],
                [2, 2, 2, 2, 2],
            ]
        ];
    }
}