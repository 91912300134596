import { ParticleConfig } from "appworks/config/asset-config-schema";
import { deepClone } from "appworks/utils/collection-utils";
import { Easing } from "appworks/utils/tween";
import { padStart } from "lodash";
import { EmitterConfig } from "pixi-particles";

export function PYLSetupParticles(): { [id: string]: ParticleConfig; } {
    const spinAWin: ParticleConfig = {
        textures: ["star_particle"],
        config: {
            frequency: 0.5,
            lifetime: { min: 1, max: 1 },
            pos: { x: 0, y: 0 },
            minimumSpeedMultiplier: 0.5,
            minimumScaleMultiplier: 0.5,
            alpha: {
                list: [
                    { value: 0, time: 0},
                    { value: 1, time: 0.5},
                    { value: 0, time: 1}
                ]
            },
            startRotation: { min: 0, max: 360 },
            rotationSpeed: { min: 100, max: 200 },
            scale: {
                list: [
                    { value: 0, time: 0},
                    { value: 1, time: 0.5},
                    { value: 0, time: 1}
                ]
            },
            ease: Easing.Sinusoidal.In,
            blendMode: "add",
            spawnType: "polygonalChain",
            spawnPolygon: [{x: 525, y: 771}, {x:916,y:783}, {x:1332, y:771}, {x:1342,y:777}, {x:922,y:796}, {x:514,y:780}],
            maxParticles: 5000
        } as EmitterConfig
    }

    const spinAWin2 = deepClone(spinAWin);
    spinAWin2.config.spawnPolygon = [{x:511,y:945}, {x:915,y:971}, {x:1338,y:953}, {x:1333,y:960}, {x:915,y:988}, {x:521,y:956}];

    const coin: ParticleConfig = {
        textures: [
            {
                textures: new Array(24).fill(0).map((_, i) => `coin/${padStart(i.toString(), 3, "0")}`),
                loop: true,
                framerate: 24
            }
        ],
        config: {
            scale: {
                start: 0.75,
                end: 1,
                minimumScaleMultiplier: 0.6   
            },
            color: {
                start: "#ffffff",
                end: "#ffffff"
            },
            speed: {
                start: 900,
                end: 0,
                minimumSpeedMultiplier: 2
            },
            acceleration: {
                x: 0,
                y: 900
            },
            maxSpeed: 0,
            startRotation: {
                min: 200,
                max: 340
            },
            noRotation: false,
            rotationSpeed: {
                min: 0,
                max: 0
            },
            lifetime: {
                min: 5,
                max: 5
            },
            blendMode: "normal",
            frequency: 0.1,
            emitterLifetime: -1,
            maxParticles: 125,
            pos: {
                x: 0,
                y: 0
            },
            addAtBack: true,
            spawnType: "point"
        }
    };

    const morecoin = deepClone(coin);
    morecoin.config.frequency = 0.02;

    return {
        generic: {
            textures: ["particle"],
            config: {
                frequency: 0.004,
                lifetime: { min: 1, max: 1 },
                pos: { x: 0, y: 0 },
                speed: { start: 200, end: 600, ease: Easing.Sinusoidal.In },
                minimumSpeedMultiplier: 0.5,
                alpha: { start: 0.6, end: 0 },
                startRotation: { min: 0, max: 360 },
                scale: { start: 0.12, end: 0.2 },
                ease: Easing.Sinusoidal.In,
                blendMode: "add",
                spawnType: "rect",
                spawnRect: { x: 0, y: 0, w: 1, h: 1 },
                maxParticles: 5000
            }
        },
        spinAWin,
        spinAWin2,
        coin,
        morecoin
    }
}