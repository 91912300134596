import { Contract } from "appworks/utils/contracts/contract";
import { SymbolSubcomponent } from "../symbol-subcomponent";
import { LandPulseSymbolBehaviour } from "./land-pulse-symbol-behaviour";

export class AnticipateLandPulseSymbolBehaviour extends LandPulseSymbolBehaviour {
    public behaviourName: string = "AnticipateLandPulse";

    public anticipateLand(): Contract<void> {
        return super.land();
    }

    public land() {
        return Contract.empty();
    }
}
