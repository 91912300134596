export const pylTint = {
    blue: 0x0066b4,
    green: 0x537019,
    red: 0xbb374e,
    yellow: 0xecc012,
    purple: 0x7921ff,
    lightGreen: 0xa0ba36
};

export function getMultiplierTint(multiplier: number, maxMult: number) {  
    const percent = multiplier / maxMult;
    if (percent <= 0.25) {
        return pylTint.yellow;
    } else if (percent > 0.25 && percent <= 0.4) {
        return pylTint.green;
    } else if (percent > 0.4 && percent <= 0.6) {
        return pylTint.blue;
    } else if (percent > 0.6 && percent <= 0.8) {
        return pylTint.purple;
    } else {
        return pylTint.red;
    }
}