import { stateMachine } from 'appworks/state-machine/state-machine';
import { PYLMatrixComponent } from 'components/pyl-matrix-component';
import { SlingoFreeSpinsRemainingDecision } from 'slingo/states/decisions/slingo-free-spins-remaining-decision';
import { SlingoHasJokerDecision } from 'slingo/states/decisions/slingo-has-joker-decision';
import { SlingoIsMaximumStakeLimitDecision } from 'slingo/states/decisions/slingo-is-maximum-stake-limit-decision';
import { SlingoRecoveryIsBeforeFirstSpinDecision } from 'slingo/states/decisions/slingo-recovery-is-before-first-spin-decision';
import { SlingoSpinPurchasedDecision } from 'slingo/states/decisions/slingo-spin-purchased-decision';
import { SlingoStakeToSpinWonDecision } from 'slingo/states/decisions/slingo-stake-to-spin-won-decision';
import { SlingoStandardSpinsRemainingDecision } from 'slingo/states/decisions/slingo-standard-spins-remaining-decision';
import { SlingoTicketCompletedDecision } from 'slingo/states/decisions/slingo-ticket-completed-decision';
import { SlingoIdleState } from 'slingo/states/slingo-idle-state';
import { SlingoPickJokerState } from 'slingo/states/slingo-pick-joker-state';
import { IsBonusTriggeredDecision } from 'slotworks/state-machine/standard/decisions/is-bonus-triggered-decision';
import { IsRecoveryDecision } from 'slotworks/state-machine/standard/decisions/is-recovery-decision';
import { PYLSlingoFullHouseState } from 'states/pyl-slingo-full-house-state';
import { SocialGameOverState } from 'states/social-game-over-state';
import { SocialPurchaseEntryState } from 'states/social-purchase-entry-state';
import { SocialPurchaseSpinOrCollectState } from 'states/social-purchase-spin-or-collect-state';
import { SocialInitState } from 'states/social-init-state';
import { SocialRecoveryState } from 'states/social-recovery-state';
import { SocialRequestSpinState } from 'states/social-request-spin-state';
import { SocialSpinState } from 'states/social-spin-state';
import { SocialTakeYourPickBonusState } from 'states/bonus/social-take-your-pick-bonus-state';
import { SocialPressYourLuckBonusState } from 'states/bonus/social-press-your-luck-bonus-state';
import { SocialLuckyPressBonusState } from 'states/bonus/social-lucky-press-bonus-state';
import { SocialSpinAWinBonusState } from 'states/bonus/social-spin-a-win-bonus-state';

/**
 * Replacement for PylStateSetup. Replacements commented out
 */
export function SocialStateSetup() {
  // States
  // const initState = new PYLInitState();
  const initState = new SocialInitState();
  const idleState = new SlingoIdleState({ matrixComponentType: PYLMatrixComponent });
  // const recoveryState = new SlingoRecoveryState();
  const recoveryState = new SocialRecoveryState();
  // const purchaseEntryState = new SlingoPurchaseEntryState();
  const purchaseEntryState = new SocialPurchaseEntryState();
  // const requestSpinState = new PYLSlingoRequestSpinState({ slingoMatrixComponentType: PYLMatrixComponent });
  const requestSpinState = new SocialRequestSpinState({
    slingoMatrixComponentType: PYLMatrixComponent,
  });
  // const stakeToSpinRequestSpinState = new PYLSlingoRequestSpinState({ slingoMatrixComponentType: PYLMatrixComponent, skipRequest: true });
  const stakeToSpinRequestSpinState = new SocialRequestSpinState({
    slingoMatrixComponentType: PYLMatrixComponent,
    skipRequest: true,
  });
  // const spinState = new PYLSlingoSpinState({ slingoMatrixComponentType: PYLMatrixComponent });
  const spinState = new SocialSpinState({ slingoMatrixComponentType: PYLMatrixComponent });
  const pickJokerState = new SlingoPickJokerState();
  // const purchaseOrCollectState = new PYLPurchaseSpinOrCollectState();
  const purchaseOrCollectState = new SocialPurchaseSpinOrCollectState();
  // const gameOverState = new PYLGameOverState();
  const gameOverState = new SocialGameOverState();
  const fullHouseState = new PYLSlingoFullHouseState();
  // const takeYourPickBonusState = new PYLTakeYourPickBonusState();
  const takeYourPickBonusState = new SocialTakeYourPickBonusState();
  // const pressYourLuckBonusState = new PYLPressYourLuckBonusState();
  const pressYourLuckBonusState = new SocialPressYourLuckBonusState();
  // const luckyPressBonusState = new PYLLuckyPressBonusState();
  const luckyPressBonusState = new SocialLuckyPressBonusState();
  // const spinAWinBonusState = new PYLSpinAWinBonusState();
  const spinAWinBonusState = new SocialSpinAWinBonusState();

  // Decisions
  const isRecoveryDecision = new IsRecoveryDecision();
  const hasJokerDecision = new SlingoHasJokerDecision();
  const standardSpinsRemainingDecision = new SlingoStandardSpinsRemainingDecision();
  const freeSpinsRemainingDecision = new SlingoFreeSpinsRemainingDecision();
  const ticketCompletedDecision = new SlingoTicketCompletedDecision();
  const spinPurchasedDecision = new SlingoSpinPurchasedDecision();
  const recoveryBeforeFirstSpinDecision = new SlingoRecoveryIsBeforeFirstSpinDecision();
  const isTakeYourPickBonus = new IsBonusTriggeredDecision('take_your_pick');
  const isPressYourLuckBonus = new IsBonusTriggeredDecision('press_your_luck');
  const isLuckyPressBonus = new IsBonusTriggeredDecision('lucky_press');
  const isSpinAWinBonus = new IsBonusTriggeredDecision('spin_a_win');
  const isStakeToSpinWonDecision = new SlingoStakeToSpinWonDecision();
  const isMaximumStakeLimitDecision = new SlingoIsMaximumStakeLimitDecision();

  stateMachine.addStates([
    { state: initState, links: { complete: isRecoveryDecision }, entryPoint: true },
    { state: recoveryState, links: { complete: recoveryBeforeFirstSpinDecision } },
    { state: idleState, links: { complete: purchaseEntryState } },
    { state: purchaseEntryState, links: { complete: requestSpinState, error: idleState } },
    { state: requestSpinState, links: { complete: spinState, error: purchaseOrCollectState } },
    { state: stakeToSpinRequestSpinState, links: { complete: spinState } },
    { state: spinState, links: { complete: ticketCompletedDecision } },
    { state: pickJokerState, links: { complete: ticketCompletedDecision } },
    { state: purchaseOrCollectState, links: { complete: isStakeToSpinWonDecision } },
    { state: gameOverState, links: { complete: idleState } },
    { state: takeYourPickBonusState, links: { complete: gameOverState } },
    { state: pressYourLuckBonusState, links: { complete: gameOverState } },
    { state: luckyPressBonusState, links: { complete: gameOverState } },
    { state: spinAWinBonusState, links: { complete: gameOverState } },
    { state: fullHouseState, links: { complete: pressYourLuckBonusState } },
  ]);

  stateMachine.addDecisions([
    { decision: isRecoveryDecision, links: { true: recoveryState, false: idleState } },
    {
      decision: recoveryBeforeFirstSpinDecision,
      links: { true: requestSpinState, false: spinState },
    },
    {
      decision: hasJokerDecision,
      links: { true: pickJokerState, false: standardSpinsRemainingDecision },
    },
    {
      decision: standardSpinsRemainingDecision,
      links: { true: requestSpinState, false: freeSpinsRemainingDecision },
    },
    {
      decision: freeSpinsRemainingDecision,
      links: { true: requestSpinState, false: purchaseOrCollectState },
    },
    {
      decision: spinPurchasedDecision,
      links: { true: requestSpinState, false: isTakeYourPickBonus },
    },
    {
      decision: ticketCompletedDecision,
      links: { true: isMaximumStakeLimitDecision, false: hasJokerDecision },
    },
    {
      decision: isTakeYourPickBonus,
      links: { true: takeYourPickBonusState, false: isPressYourLuckBonus },
    },
    {
      decision: isPressYourLuckBonus,
      links: { true: pressYourLuckBonusState, false: isLuckyPressBonus },
    },
    { decision: isLuckyPressBonus, links: { true: luckyPressBonusState, false: isSpinAWinBonus } },
    { decision: isSpinAWinBonus, links: { true: spinAWinBonusState, false: gameOverState } },
    {
      decision: isStakeToSpinWonDecision,
      links: { true: stakeToSpinRequestSpinState, false: spinPurchasedDecision },
    },
    {
      decision: isMaximumStakeLimitDecision,
      links: { true: gameOverState, false: fullHouseState },
    },
  ]);
}
