import { Services } from "appworks/services/services";
import { SoundEvent } from "appworks/services/sound/sound-events";
import { SoundService } from "appworks/services/sound/sound-service";
import { WhammyAnimations } from "components/pyl-whammy-animation-component";
import { SlingoSoundEvent } from "slingo/sound/slingo-sound-events";
import { SoundNames } from "sounds-enum";

export function PYLSoundSetup(): void {
    const sound = Services.get(SoundService);

    // Buttons
    sound.registerPlay(SoundEvent.default_button_press, { id: SoundNames.snipup_2 });

    // Music
    sound.registerPlay(SoundEvent.start_game, { id: SoundNames.PYL_MUS_InGame_Main_Loop, music: true, loop: true });
    sound.registerPlay("bonus_start", { id: SoundNames.PYL_MUS_InGame_Bonus_Loop, music: true, loop: true });
    sound.registerStop("bonus_end", { id: SoundNames.PYL_MUS_InGame_Bonus_Loop });
    sound.registerVolume("pyl_board_flash_start", { id: SoundNames.PYL_MUS_InGame_Bonus_Loop, volume: 0.4 });
    sound.registerVolume("pyl_board_flash_stop", { id: SoundNames.PYL_MUS_InGame_Bonus_Loop, volume: 0.4 });

    // Popups
    sound.registerPlay(SlingoSoundEvent.end_game_win, { id: SoundNames.PYL_SFX_BG_EndGame_Win });
    sound.registerPlay(SlingoSoundEvent.end_game_no_win, { id: SoundNames.PYL_SFX_BG_EndGame_NoWin });

    // Win tickups
    sound.registerPlay(SoundEvent.winnings_countup, { id: SoundNames.PYL_SFX_BG_Countup_Loop, loop: true, singleton: true });
    sound.registerStop(SoundEvent.winnings_countup_end, { id: SoundNames.PYL_SFX_BG_Countup_Loop });
    sound.registerTerminator(SoundNames.PYL_SFX_BG_Countup_End, SoundNames.PYL_SFX_BG_Countup_Loop);

    // big win
    sound.registerPlayArgs(SoundEvent.big_win_tier_id, ["bigwin"], [{ id: SoundNames.PYL_SFX_BW_Big_Start }, { id: SoundNames.PYL_SFX_BW_Big_Loop, music: true, loop: true }, { id: SoundNames.PYL_SFX_BG_Countup_Loop, loop: true, singleton: true }]);
    sound.registerPlayArgs(SoundEvent.big_win_tier_id, ["megawin"], [{ id: SoundNames.PYL_SFX_BW_Epic_Start }, { id: SoundNames.PYL_SFX_BW_Epic_Loop, music: true, loop: true }, { id: SoundNames.PYL_SFX_BG_Countup_Loop, loop: true, singleton: true }]);
    sound.registerStop(SoundEvent.big_win_countup_end, [{ id: SoundNames.PYL_SFX_BW_Big_Loop }, { id: SoundNames.PYL_SFX_BW_Epic_Loop }]);

    // Reels
    sound.registerPlay(SoundEvent.reel_transition_start, { id: SoundNames.PYL_MECH_SPIN_START_SFX });
    sound.registerPlay(SoundEvent.reel_any_land, { id: SoundNames.PYL_MECH_REEL_STOP_1 });

    // Base Whammy (Blocker) Lands
    sound.registerPlay("whammy_land", { id: SoundNames.PYL_SFX_BG_Land_WhammyBlocker });
    sound.registerPlay("whammy_1_land", { id: SoundNames.KM_Giggle_01 });
    sound.registerPlay("whammy_2_land", { id: SoundNames.KM_Giggle_04 });
    sound.registerPlay("whammy_3_land", { id: SoundNames.KM_Giggle_05 });

    // Wild lands
    sound.registerPlayArgs(SoundEvent.symbol_ID_land, ["J"], { id: SoundNames.PYL_SFX_BG_Land_Wild });
    sound.registerPlayArgs(SoundEvent.symbol_ID_land, ["SJ"], { id: SoundNames.PYL_SFX_BG_Land_WildSuper });

    // Dabs
    sound.registerPlayArgs(SlingoSoundEvent.dab_N, ["1"], { id: SoundNames.MultiplierLand1 });
    sound.registerPlayArgs(SlingoSoundEvent.dab_N, ["2"], { id: SoundNames.MultiplierLand2 });
    sound.registerPlayArgs(SlingoSoundEvent.dab_N, ["3"], { id: SoundNames.MultiplierLand3 });
    sound.registerPlayArgs(SlingoSoundEvent.dab_N, ["4"], { id: SoundNames.MultiplierLand3 });
    sound.registerPlayArgs(SlingoSoundEvent.dab_N, ["5"], { id: SoundNames.MultiplierLand3 });

    // Slingo celebration
    sound.registerPlay(SlingoSoundEvent.slingo, { id: SoundNames.BigBucksSymbol });

    // Ladder
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["1"], { id: SoundNames.MultiplierLand1, rate: 1, singleton: true });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["2"], { id: SoundNames.MultiplierLand1, rate: 1.1, singleton: true });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["3"], { id: SoundNames.MultiplierLand1, rate: 1.2, singleton: true });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["4"], { id: SoundNames.MultiplierLand1, rate: 1.3, singleton: true });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["5"], { id: SoundNames.MultiplierLand1, rate: 1.4, singleton: true });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["6"], { id: SoundNames.MultiplierLand1, rate: 1.5, singleton: true });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["7"], { id: SoundNames.MultiplierLand1, rate: 1.6, singleton: true });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["8"], { id: SoundNames.MultiplierLand1, rate: 1.7, singleton: true });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["9"], { id: SoundNames.MultiplierLand1, rate: 1.8, singleton: true });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["10"], { id: SoundNames.MultiplierLand1, rate: 1.9, singleton: true });
    sound.registerPlayArgs(SlingoSoundEvent.ladder_level_up_N, ["12"], { id: SoundNames.MultiplierLand1, rate: 2, singleton: true });

    // Board game
    sound.registerPlay("pyl_board_flash_start", { id: SoundNames.PYL_BONUS_BOARD_V1, loop: true });
    sound.registerStop("pyl_board_flash_stop", { id: SoundNames.PYL_BONUS_BOARD_V1 });

    sound.registerPlay("pyl_whammy_hit", { id: SoundNames.PYL_WAMMY_HIT });
    sound.registerPlay("pyl_win_hit", { id: SoundNames.PYL_GetCredits_Medium_01 });

    sound.registerPlay("pyl_win_cheer_1", { id: SoundNames.BBCrowd_CreditCheer_01 });
    sound.registerPlay("pyl_win_cheer_2", { id: SoundNames.BBCrowd_CreditCheer_02 });
    sound.registerPlay("pyl_win_cheer_3", { id: SoundNames.BBCrowd_CreditCheer_03 });
    sound.registerPlay("pyl_win_cheer_4", { id: SoundNames.BBCrowd_CreditCheer_04 });
    sound.registerPlay("pyl_win_cheer_5", { id: SoundNames.BBCrowd_CreditCheer_05 });

    sound.registerPlay("pyl_bell_loop", { id: SoundNames.PYL_SFX_BellRinging_Loop, loop: true });
    sound.registerStop("pyl_bell_stop", { id: SoundNames.PYL_SFX_BellRinging_Loop });
    sound.registerTerminator(SoundNames.PYL_SFX_BellRinging_Terminator, SoundNames.PYL_SFX_BellRinging_Loop);
    
    sound.registerPlay("heart_break", { id: SoundNames.break, singleton: true });

    // Spin a win
    sound.registerPlay("reeltick_1", { id: SoundNames.PYL_SFX_SAWB_WheelSpinCog_01a });
    sound.registerPlay("reeltick_2", { id: SoundNames.PYL_SFX_SAWB_WheelSpinCog_01b });
    sound.registerPlay("reeltick_3", { id: SoundNames.PYL_SFX_SAWB_WheelSpinCog_01c });
    sound.registerPlay("reeltick_4", { id: SoundNames.PYL_SFX_SAWB_WheelSpinCog_01d });

    // Pick bonus
    sound.registerPlay("card_flip", { id: SoundNames.cardflip, singleton: true });
    sound.registerPlayArgs(SoundEvent.button_press_NAME, ["pick_0"], { id: SoundNames.SILENCE_1_SEC });
    sound.registerPlayArgs(SoundEvent.button_press_NAME, ["pick_1"], { id: SoundNames.SILENCE_1_SEC });
    sound.registerPlayArgs(SoundEvent.button_press_NAME, ["pick_2"], { id: SoundNames.SILENCE_1_SEC });
    sound.registerPlayArgs(SoundEvent.button_press_NAME, ["pick_3"], { id: SoundNames.SILENCE_1_SEC });
    sound.registerPlay("whammy_add_1", { id: SoundNames.KM_Giggle_01 });
    sound.registerPlay("whammy_add_2", { id: SoundNames.KM_Giggle_04 });
    sound.registerPlay("whammy_add_3", { id: SoundNames.KM_Giggle_05 });

    // Whammy Animations
    sound.registerPlay(WhammyAnimations.BARTENDER, { id: SoundNames.Bartender });
    sound.registerPlay(WhammyAnimations.BULLRIDE, { id: SoundNames.Bullride });
    sound.registerPlay(WhammyAnimations.CRUISESHIP, { id: SoundNames.CruiseShip });
    sound.registerPlay(WhammyAnimations.HULA, { id: SoundNames.Hula });
    sound.registerPlay(WhammyAnimations.JULY4, { id: SoundNames.July4 });
    sound.registerPlay(WhammyAnimations.OPERA, { id: SoundNames.Opera });
    sound.registerPlay(WhammyAnimations.PIZZA, { id: SoundNames.Pizza });
    sound.registerPlay(WhammyAnimations.ROLLERSKATE, { id: SoundNames.Rollerskate });
    sound.registerPlay(WhammyAnimations.TARZAN, { id: SoundNames.Tarzan });
    sound.registerPlay(WhammyAnimations.UFO, { id: SoundNames.UFO });
    sound.registerPlay(WhammyAnimations.UNICORN, { id: SoundNames.Unicorn });
    sound.registerPlay(WhammyAnimations.WATERSKI, { id: SoundNames.WaterSki });

    sound.registerStop("whammy_stop", [
        { id: SoundNames.Bartender },
        { id: SoundNames.Bullride },
        { id: SoundNames.CruiseShip },
        { id: SoundNames.Hula },
        { id: SoundNames.July4 },
        { id: SoundNames.Opera },
        { id: SoundNames.Pizza },
        { id: SoundNames.Rollerskate },
        { id: SoundNames.Tarzan },
        { id: SoundNames.UFO },
        { id: SoundNames.Unicorn },
        { id: SoundNames.WaterSki }
    ]);
}
